import React, { useEffect, useState } from 'react';
import { Container, CircularProgress, Snackbar, Alert, Typography, Box } from '@mui/material';
import api from '../../../../services/api';
import ActivationRequestForm from './ActivationRequestForm';

const SMSActivationStatusHome = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await api.get('/api/smsko/sms-activation-status/');
                setStatus(response.data.status);
            } catch (err) {
                setError("Erreur lors de la vérification du statut de l'activation du service SMS.");
            } finally {
                setLoading(false);
            }
        };

        fetchStatus();
    }, []);

    return (
        <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Snackbar open autoHideDuration={6000}>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            ) : (
                <div>
                    {status !== 1 && (
                        <Box sx={{ mt: 2 }}>
                            <Alert severity="warning" variant="filled">
                                <Typography variant="h5" component="div">
                                    Votre service SMS by BEFIANA n'est pas encore activé, ce qui limite l'accès à certaines fonctionnalités sur votre compte.
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                                    Veuillez compléter votre demande d'activation pour utiliser ce service.
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                                    Si votre demande a déjà été soumise, veuillez patienter jusqu'à ce qu'elle soit approuvée par notre équipe sous un délai maximum de 24 heures.
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                                    Contactez le service support au 032 63 166 59 / 034 09 721 93 pour plus d'informations.
                                </Typography>
                                <br />
                                <ActivationRequestForm />
                                
                            </Alert>
                        </Box>
                    )}
                </div>
            )}
        </Container>
    );
};

export default SMSActivationStatusHome;
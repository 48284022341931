import {useEffect, useState} from 'react'
import api from '../../services/api';
import { Typography, Box } from '@mui/material'
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; 
dayjs.locale('fr'); 

function LastPasswordChange() {

    const [lastDateChange, setLastDateChange] = useState(null);

    useEffect(() => {
        api.get('api/user-info/')
          .then(response => {
            setLastDateChange(response.data.last_password_update)
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des informations utilisateur:', error);
          });
      }, []);

  return (
    <Box>
        <Typography variant="body2" color="primary">Dernière modification : {dayjs(lastDateChange).format('D MMMM YYYY, HH:mm')} </Typography>
    </Box>
  )
}

export default LastPasswordChange
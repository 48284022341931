import React from 'react';
import { AppBar, Toolbar, Typography, Box, Paper, Button, Link, IconButton, Container, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function TermsOfService() {
    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <img src="/befiana.png" alt="Befiana Logo" style={{ height: '50px', marginRight: '20px' }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        
                    </Typography>
                    <IconButton color="inherit" component={RouterLink} to="/account">
                        <MenuIcon />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Mon Compte
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>

            <Paper elevation={3} sx={{ p: 2, maxWidth: 899, mx: 'auto' }}>
                <Typography variant="h4" gutterBottom>
                    Conditions Générales d'Utilisation de Befiana Connect
                </Typography>
                <Helmet>
                    <title>Conditions Générales d'Utilisation - BEFIANA Connect</title>
                    <meta name="description" content="Lisez attentivement les Conditions Générales d'Utilisation de Befiana Connect pour comprendre les règles et les normes régissant votre accès à nos services cloud centralisés. Assurez une utilisation sûre et conforme de BefianaCloud Services." />
                </Helmet>
                <Typography paragraph>
                    Nous vous remercions de choisir Befiana Connect et BefianaCloud Services pour vos besoins en solutions cloud centralisées. Avant d'utiliser notre plateforme, nous vous invitons à lire attentivement les conditions d'utilisation suivantes qui régissent votre accès et l'utilisation de nos services. Ces conditions sont conçues pour assurer une expérience sûre, respectueuse et conforme pour tous nos utilisateurs.
                </Typography>
                <Typography variant="subtitle2" color="initial">Date de dernière révision : 18 avril 2024</Typography>
                
                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    1. Identification de l'Entreprise
                </Typography>
                <Typography paragraph>
                    <strong>Befiana Platform Corps Ltd</strong><br />
                    Company Number: 14718738<br />
                    Type de Société: Private limited Company<br />
                    Adresse du Siège Social: 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ<br />
                    Représentant Légal: Rabenandrasana Antenaina Jaonina
                </Typography>
                <Typography paragraph>
                    <strong>Befiana Platform Corps SARLU</strong><br />
                    STAT: 74909 11 2023 0 10359<br />
                    RCS: Antananarivo 2023 B 00357<br />
                    NIF: 6017757496<br />
                    CF: 0026871/DGI-K<br />
                    Adresse du Siège Social: SITRAM Ankorondrano Zone Industrielle Route des Hydrocarbures B.P 171 – Antananarivo 101<br />
                    Représentant Légal: Rabenandrasana Antenaina Jaonina
                </Typography>
                <Typography paragraph>
                    Contact : Pour toute question relative à ces conditions d'utilisation, veuillez nous contacter par email à l'adresse <Link href="mailto:policy@befiana.com">policy@befiana.com</Link>.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    2. Description du Service
                </Typography>
                <Typography paragraph>
                    Nature du Service : Befiana Connect et BefianaCloud Services offrent une interface web centralisée intégrant divers outils conçus pour optimiser et faciliter le quotidien professionnel et personnel de nos utilisateurs.
                </Typography>
                <Typography paragraph>
                    Offre de Services : Nos services incluent une gamme étendue de solutions cloud, accessible via une connexion unique pour une expérience utilisateur optimisée et simplifiée.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    3. Conditions d'Accès
                </Typography>
                <Typography paragraph>
                    Critères d’Éligibilité :<br />
                    Âge : L'accès est restreint aux individus âgés d'au moins 13 ans, avec des services spécifiques réservés aux utilisateurs de 18 ans et plus.<br />
                    Localisation Géographique : Disponible principalement pour les utilisateurs situés dans l'Union Européenne, au Royaume-Uni, et à Madagascar.<br />
                    Adresse Email Valide : Nécessaire pour l'enregistrement et l'utilisation continue des services.<br />
                    Consentement Parental : Requis pour les utilisateurs mineurs conformément à la législation en vigueur.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    4. Droits de Propriété Intellectuelle
                </Typography>
                <Typography paragraph>
                    Contenus sous Licence et Propriétaires : Certaines images et contenus sont obtenus sous licence via Pexels et autres fournisseurs, et sont soumis aux conditions de ces licences tierces.
                </Typography>
                <Typography paragraph>
                    Utilisation du Contenu : Les utilisateurs s'engagent à respecter les droits de propriété intellectuelle et à ne pas reproduire, distribuer, modifier ou utiliser le contenu de la plateforme sans autorisation préalable.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    5. Utilisation Acceptable et Violations
                </Typography>
                <Typography paragraph>
                    Interdictions : Toute forme de harcèlement, piratage, ou diffusion de contenu malveillant via nos services est strictement interdite. Les utilisateurs ne doivent en aucun cas compromettre la sécurité ou le fonctionnement normal de la plateforme.
                </Typography>
                <Typography paragraph>
                    Conséquences : Tout manquement à ces règles entraînera des mesures correctives immédiates, y compris, mais sans s'y limiter, la suspension ou la résiliation de l'accès aux services.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    6. Limitations de Responsabilité
                </Typography>
                <Typography paragraph>
                    Nous déclinons toute responsabilité pour les interruptions de service, pertes de données ou dommages indirects résultant de l'utilisation de nos services, sauf dans les cas où notre responsabilité ne peut être exclue par la loi.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    7. Modalités de Résiliation et de Suspension
                </Typography>
                <Typography paragraph>
                    Suspension et Résiliation de Compte : Un compte peut être suspendu ou résilié pour non-respect des présentes conditions ou pour activités suspectes. Tout utilisateur peut également fermer son compte de manière volontaire.
                </Typography>
                <Typography paragraph>
                    Processus de Fermeture de Compte : La demande de suppression de compte entraîne une désactivation immédiate. Un délai de 30 jours est accordé pour permettre l'effacement définitif des données personnelles, période durant laquelle un audit peut être conduit si nécessaire.
                </Typography>

                <Divider sx={{ my: 1 }} />

                <Typography variant="h6" gutterBottom>
                    8. Modifications des Conditions
                </Typography>
                <Typography paragraph>
                    Nous nous réservons le droit de modifier ces conditions à tout moment. Les utilisateurs seront informés de toute modification significative par notification électronique, avec un délai raisonnable pour prendre connaissance des changements avant leur entrée en vigueur.
                </Typography>
                <Button
                    component={RouterLink}
                    to="/"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    Retour à l'accueil
                </Button>
            </Paper>

            </Container>

            <Box component="footer" sx={{ bgcolor: 'background.paper', p: 2, mt: 4 }}>
            <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%' }}>
                    <Link href="/terms" underline="hover" sx={{ mx: 2, my: 1 }}>
                        CGU
                    </Link>
                    <Link href="/policy" underline="hover" sx={{ mx: 2, my: 1 }}>
                        Politique de confidentialité
                    </Link>
                    <Link href="/cookies" underline="hover" sx={{ mx: 2, my: 1 }}>
                        Cookies 
                    </Link>
                    <Link href="https://help.befiana.cloud" underline="hover" sx={{ mx: 2, my: 1 }}>
                        Besoins d'aide
                    </Link>
                    <Link href="https://www.befiana.com/contact/" underline="hover" sx={{ mx: 2, my: 1 }}>
                        Contact
                    </Link>
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }} align="center">
                    © {new Date().getFullYear()} Befiana Platform Corps
                </Typography>
            </Container>
        </Box>


        </Box>
    );
}

export default TermsOfService;
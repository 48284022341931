import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useBoxAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const storeReference = localStorage.getItem('store_reference');
    if (storeReference) {
      navigate('/App/iBondacha/cash');
    }
  }, [navigate]);
};

export default useBoxAuth;
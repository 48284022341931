import React from 'react';
import Select from 'react-select';
import countries from './countries'; 
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

const CountrySelect = React.forwardRef(({ onChange, value, error, helperText }, ref) => {
  const theme = useTheme();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      borderColor: error ? theme.palette.error.main : provided.borderColor,
      '&:hover': {
        borderColor: error ? theme.palette.error.main : provided.borderColor,
      },
      color: theme.palette.text.primary,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      zIndex: 99, 
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? theme.palette.primary.main : theme.palette.text.primary,
      backgroundColor: state.isSelected ? theme.palette.action.selected : theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    }),
  };

  return (
    <div>
      <Select
        ref={ref}
        options={countries}
        styles={customStyles}
        onChange={(val) => onChange(val)}
        value={countries.find((c) => c.value === value)}
        placeholder="Sélectionnez un pays..."
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
      {error && <Typography color="error" variant="caption">{helperText}</Typography>}
    </div>
  );
});

export default CountrySelect;
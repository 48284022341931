import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Grid, Paper, TextField, Button, Typography, Link, Alert } from '@mui/material';
import { InputAdornment, IconButton, CircularProgress  } from '@mui/material';
import TheBefiana from '../components/TheBefiana';
import { Link as RouterLink } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Helmet } from 'react-helmet-async';

import { useDispatch } from 'react-redux';
import { loginSuccess } from '../features/auth/authSlice';
import useAuthStatus  from '../hooks/useAuthStatus';

function Login() {
    const dispatch = useDispatch();
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const isAuthenticated = useAuthStatus();
    React.useEffect(() => {
        if (isAuthenticated) {
            navigate('/'); 
        }
    }, [isAuthenticated, navigate]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials(prevCredentials => ({
            ...prevCredentials,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError(''); 

        axios.post(`${process.env.REACT_APP_API_URL}/api/token/`, credentials)
            .then(response => {
                Cookies.set('befiana_tc', response.data.access, { expires: 1/288, sameSite: 'Lax' }); 
                Cookies.set('befiana_rc', response.data.refresh, { expires: 29, sameSite: 'Lax' });
                Cookies.set('befiana_id', response.data.id, { expires: 29, sameSite: 'Lax' });

                dispatch(loginSuccess({
                    user: response.data.user,
                    token: response.data.access,
                    refreshToken: response.data.refresh,
                }));
                navigate('/');
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    switch (error.response.status) {
                        case 403:
                            setError("Ce compte a été désactivé.");
                            break;
                        case 401:
                            setError("Identifiant ou mot de passe invalide.");
                            break;
                        default:
                            setError("Une erreur inattendue est survenue. Veuillez réessayer plus tard.");
                    }
                } else {
                    setError("Problème de connexion au serveur. Veuillez vérifier votre connexion Internet.");
                }
                setIsLoading(false);
            });
    };

    return (
        <Grid container sx={{ bgcolor: '#f0f2f5', height: '100vh' }}>
            <Grid item xs={12} md={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, paddingRight: { sm: '11px' } }}>
                <TheBefiana />
            </Grid>
            <Grid item xs={12} md={5} sx={{ bgcolor: '#f0f2f5', paddingRight: { sm: '11px' }, marginTop: { xs: '10vh', md: '0px', lg: '0px', xl: '0px' }, marginLeft: { xs: '9px', md: '0px', lg: '0px', xl: '0px' }, marginRight: { xs: '9px', md: '0px', lg: '0px', xl: '0px' } }} display="flex" alignItems="center" justifyContent="center">
                <Paper
                    elevation={8}
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        margin: 'auto',
                        padding: 4,
                        backgroundColor: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.05) 0px 6px 10px 0px, rgba(0, 0, 0, 0.05) 0px 1px 18px 0px'
                    }}
                >
                    <Grid item>
                        <img src="/befiana.png" alt="Description" style={{ maxHeight: '71px', display: 'block', margin: '0 auto' }} />
                    </Grid>
                    <Typography variant="subtitle2" mt={1} textAlign={'center'} component="h2" gutterBottom>
                        Connectez votre compte BEFIANA Connect
                    </Typography>
                    <Helmet>
                        <title>Connexion - BEFIANA Connect</title>
                        <meta name="description" content="Connectez-vous à Befiana Connect pour accéder à vos solutions cloud centralisées. Entrez votre adresse email ou nom d'utilisateur et votre mot de passe pour profiter de nos services sécurisés et optimisés pour votre productivité." />
                    </Helmet>
                    <Typography variant="subtitle2" textAlign={'center'} sx={{ fontFamily: 'befianaLora', fontStyle: 'italic' }} component="h2" gutterBottom>
                        "Cap sur l'avenir : Osez l'infini !"
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Nom d'utilisateur ou Adresse Email"
                            name="username"
                            value={credentials.username}
                            autoComplete="username"
                            onChange={handleChange}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            label="Mot de passe"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toogle la visibilité du mot de passe"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} 
                        />

                            {error && <Alert variant="filled" severity="error"> {error} </Alert>}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, p: 2 }}
                            disabled={isLoading}
                            startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}
                        >
                            {isLoading ? 'Connexion en cours...' : 'Se connecter'}
                        </Button>

                    </form>

                    <Typography variant="body1" color="primary" textAlign={"center"}><Link component={RouterLink} to="/password-reset">Mot de passe oublié ?</Link></Typography>

                    <Button
                        component={RouterLink}
                        to="/tongasoa"
                        type="button"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2, p: 1 }}
                    >
                        Créer noveau compte
                    </Button>

                </Paper>
            </Grid>
        </Grid>
    )
}

export default Login
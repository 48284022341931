import React, { useState } from 'react';
import { Box, Toolbar, Typography, Divider, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SendIcon from '@mui/icons-material/Send';
import IBAppBarComponent from './components/AppBar';
import IBDrawerBarMD from './drawer/Drawer';
import IBDrawerTabsXS from './drawer/DrawerXS';
import VoucherExportCompta from './components/VoucherExportCompta';
import SendSMS from './components/SendSMS';
import { Helmet } from 'react-helmet-async';
import VoucherLogExport from './components/VoucherLogExport';
import CreateVoucherCompta from './components/CreateVoucherCompta';

function IBCompta() {
    const [openSendSMSDialog, setOpenSendSMSDialog] = useState(false);

    const handleOpenSendSMSDialog = () => {
        setOpenSendSMSDialog(true);
    };

    const handleCloseSendSMSDialog = () => {
        setOpenSendSMSDialog(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Espace Compta - iBondacha</title>
            </Helmet>
            <IBAppBarComponent />
            <IBDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>Espace Compta</Typography>
                <Divider>iBondacha App</Divider>
                <br />

                <VoucherExportCompta />

                <Box sx={{ maxWidth: 600, mx: 'auto' }}>
                    <CreateVoucherCompta />
                </Box>

                <VoucherLogExport />

            </Box>

            <SpeedDial
                ariaLabel="SpeedDial example"
                sx={{ position: 'fixed', bottom: 16, right: 16, '& .MuiFab-primary': { backgroundColor: '#e84eb2', opacity: '0.7', height: 40, width: 40 }}}
                icon={<SpeedDialIcon />}
            >
                <SpeedDialAction
                    icon={<SendIcon />}
                    tooltipTitle="Envoyer SMS"
                    onClick={handleOpenSendSMSDialog}
                />
            </SpeedDial>

            <Dialog open={openSendSMSDialog} onClose={handleCloseSendSMSDialog}>
                <DialogTitle>
                    <Button variant="text" color="ib1" startIcon={<SendIcon />} >
                        Envoyer un SMS
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <SendSMS />
                </DialogContent>
            </Dialog>

            <IBDrawerTabsXS />
        </Box>
    );
}

export default IBCompta;
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Paper, Button, Typography } from '@mui/material';
import TheBefiana from '../components/TheBefiana';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import HappyBefianaConnect from '../components/lotties/HappyBefianaConnect';
import SadBefianaConnect from '../components/lotties/SadBefianaConnect';
import axios from 'axios';


function EmailVerification() {
    const { token } = useParams();
    const [verificationStatus, setVerificationStatus] = useState('');
    const [sad, setSad] = useState(false);
    const [happy, setHappy] = useState(false);

    const verifyingRef = useRef(false);

    
    useEffect(() => {
        if (verifyingRef.current) {
            return;
        }
        verifyingRef.current = true;

        const verifyEmail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-email/${token}/`);
                if (response.status === 200) {
                    setVerificationStatus('Votre compte a été vérifié avec succès.');
                    setHappy(true);
                } else {
                    setVerificationStatus('La vérification a échoué. Veuillez réessayer ou contacter le support.');
                    setSad(true);
                }
            } catch (error) {
                setVerificationStatus('Une erreur est survenue. Veuillez réessayer plus tard.');
                setSad(true);
            } finally {
                verifyingRef.current = false; 
            }
        };

        verifyEmail();
    }, [token]);

    return (
        <Grid container sx={{ bgcolor: '#f0f2f5', height: '100vh' }}>
            <Grid item xs={12} md={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, paddingRight: { sm: '11px' } }}>
                <TheBefiana />
            </Grid>
            <Grid item xs={12} md={5} sx={{ bgcolor: '#f0f2f5', paddingRight: { sm: '11px' }, marginTop: { xs: '10vh', md: '0px', lg: '0px', xl: '0px' }, marginLeft: { xs: '9px', md: '0px', lg: '0px', xl: '0px' }, marginRight: { xs: '9px', md: '0px', lg: '0px', xl: '0px' } }} display="flex" alignItems="center" justifyContent="center">
                <Paper
                    elevation={8}
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        margin: 'auto',
                        padding: 4,
                        backgroundColor: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.05) 0px 6px 10px 0px, rgba(0, 0, 0, 0.05) 0px 1px 18px 0px'
                    }}
                >
                    <Grid item>
                        <img src="/befiana.png" alt="Description" style={{ maxHeight: '71px', display: 'block', margin: '0 auto' }} />
                    </Grid>
                    
                    <Typography variant="subtitle2" textAlign={'center'} sx={{ fontFamily: 'befianaLora', fontStyle: 'italic' }} component="h2" gutterBottom>
                        "Cap sur l'avenir : Osez l'infini !"
                    </Typography>
                    
                    <Typography variant="h5" color="primary" mt={3} mb={2} textAlign={"center"}>Vérification de l'E-mail</Typography>
                    <Divider>Compte BEFIANA Connect</Divider>
                    
                    {sad && <SadBefianaConnect />}
                    {happy && <HappyBefianaConnect />}

                    <Typography sx={{ mb: 2 }} textAlign={"center"}>
                        {verificationStatus}
                    </Typography>

                    {happy && (
                            <Button
                                component={RouterLink}
                                to="/login"
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2, p: 1 }}
                            >
                                Connectez-vous maintenant
                            </Button>
                        )}

                    {sad && (
                        <Button
                            component={RouterLink}
                            to="/login"
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2, mb: 2, p: 1 }}
                            color="error"
                        >
                            Demander une nouvelle vérification
                        </Button>
                    )}
                    

                </Paper>
            </Grid>
        </Grid>
    )
}

export default EmailVerification
import React, { useState, useEffect } from 'react';
import {
  Box, TextField, FormControl, InputLabel, Select, MenuItem, Button,
  Snackbar, Alert, Typography, Paper, IconButton, Grid
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import api from '../../../../services/api';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const isGSMCharacter = (char) => {
    const GSM_03_38_REGEX = /^[A-Za-z0-9 \r\n@£$¥èéêëùûüîïâäàôöçÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€°œŒ«»‘’“”‹›±×÷√∞∑∏π∂∆≈≠≤≥∫∬∮°µ‰∞]*$/;
    const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
    return GSM_03_38_REGEX.test(char) || emojiRegex.test(char);
  };
  
const getMessageLength = (message) => {
const adjustedMessage = message.replace(/\*\|FNAME\|\*/g, ' '.repeat(33));
return adjustedMessage.split('').reduce((count, char) => {
    const doubleCountChars = ['ê', 'ë', 'î', 'ï', 'â', 'ä', 'ô', 'ö', 'û', 'ü', 'œ', 'Œ'];
    const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
    if (emojiRegex.test(char)) {
    return count + 3;
    }
    return count + (doubleCountChars.includes(char) ? 2 : 1);
}, 0);
};

const getSmsCount = (messageLength) => {
if (messageLength <= 160) {
    return 1;
} else if (messageLength <= 320) {
    return 2;
}
return Math.ceil(messageLength / 160);
};

function CreateCountdownReminderForm({ onSuccess }) {
  const [contactGroupId, setContactGroupId] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [eventDate, setEventDate] = useState(dayjs());
  const [intervals, setIntervals] = useState([{ value: '', unit: 'minutes', message: '' }]);
  const [contactGroups, setContactGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const processPhoneNumbers = (input) => {
    let value = input.replace(/\s+/g, '');
    value = value.replace(/[^0-9,]/g, '');
    value = value.replace(/,+/g, ',');
    let newValue = '';
    let digitCount = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char >= '0' && char <= '9') {
            if (digitCount === 0 && char !== '3') {
                continue;
            }
            newValue += char;
            digitCount++;
            if (digitCount === 9 && i !== value.length - 1) {
                newValue += ',';
                digitCount = 0;
            }
        }
    }
    if (newValue.endsWith(',')) {
        newValue = newValue.slice(0, -1);
    }
    let numbers = newValue.split(',');
    numbers = numbers.map(number => {
        if (number.length > 1) {
            return number.charAt(0) + number.slice(1).replace(/^0+/, '');
        } else {
            return number;
        }
    });
    return numbers.join(',');
  };

  useEffect(() => {
    api.get('/api/smsko/contacts/lists/')
      .then(res => setContactGroups(res.data))
      .catch(err => console.error('Erreur lors de la récupération des groupes de contacts', err));
  }, []);

  const handleAddInterval = () => {
    setIntervals([...intervals, { value: '', unit: 'minutes', message: '' }]);
  };

  const handleRemoveInterval = (index) => {
    const newIntervals = intervals.filter((_, i) => i !== index);
    setIntervals(newIntervals);
  };

  const handleMessageChange = (index, event) => {
    let newMessage = event.target.value;

    if (newMessage.endsWith('*|') && !newMessage.endsWith('*|FNAME|*')) {
      newMessage = newMessage.slice(0, -2) + '*|FNAME|*';
    }

    if (newMessage.endsWith('%f') && !newMessage.endsWith('*|FNAME|*')) {
      newMessage = newMessage.slice(0, -2) + '*|FNAME|*';
    }

    if (newMessage.split('').every(isGSMCharacter) && getMessageLength(newMessage) <= 320) {
      const newIntervals = [...intervals];
      newIntervals[index].message = newMessage;
      setIntervals(newIntervals);
    }
  };

  const handleIntervalChange = (index, field, value) => {
    const newIntervals = [...intervals];
    newIntervals[index][field] = value;
    setIntervals(newIntervals);
  };

  const convertIntervalToMinutes = (value, unit) => {
    const intValue = parseInt(value, 10);
    if (isNaN(intValue) || intValue < 0) return 0;
    switch (unit) {
      case 'months':
        return intValue * 30 * 24 * 60;
      case 'days':
        return intValue * 24 * 60;
      case 'hours':
        return intValue * 60;
      case 'minutes':
        return intValue;
      default:
        return intValue;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const processedPhoneNumbers = processPhoneNumbers(phoneNumbers);

      const intervalsPayload = intervals.map(intervalObj => ({
        interval: convertIntervalToMinutes(intervalObj.value, intervalObj.unit),
        message: intervalObj.message,
      }));

      const payload = {
        contact_group_id: contactGroupId || null,
        phone_numbers: processedPhoneNumbers || null,
        event_date: eventDate.utc().format('YYYY-MM-DD HH:mm:ss'),
        intervals: intervalsPayload,
      };

      await api.post('/api/smsko/reminders/countdown/create/', payload);
      setSnackbar({ open: true, message: 'Rappel avant événement créé avec succès!', severity: 'success' });
      setContactGroupId('');
      setPhoneNumbers('');
      setEventDate(dayjs());
      setIntervals([{ value: '', unit: 'minutes', message: '' }]);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error('Erreur lors de la création du rappel avant événement', error);
      setSnackbar({ open: true, message: 'Erreur lors de la création du rappel.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Créer un Rappel Avant Événement
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="contact-group-select-label">Groupe de Contacts</InputLabel>
            <Select
              labelId="contact-group-select-label"
              id="contact-group-select"
              value={contactGroupId}
              label="Groupe de Contacts"
              onChange={(e) => setContactGroupId(e.target.value)}
            >
              {contactGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
              fullWidth
              label="Numéros de téléphone (séparés par des virgules sans zéros)"
              value={phoneNumbers}
              onChange={(e) => setPhoneNumbers(processPhoneNumbers(e.target.value))}
              sx={{ mb: 2 }}
              helperText="Entrez des numéros commençant par '3'; une virgule sera automatiquement ajoutée après chaque numéro de 9 chiffres."
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
          <DateTimePicker
            label="Date et heure de l'événement"
            value={eventDate}
            onChange={(newValue) => setEventDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
          />
          <Typography variant="h6" gutterBottom>
            Intervalles de Rappel
          </Typography>
          {intervals.map((intervalObj, index) => (
            <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    label="Valeur"
                    type="number"
                    value={intervalObj.value}
                    onChange={(e) => handleIntervalChange(index, 'value', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id={`unit-select-label-${index}`}>Unité</InputLabel>
                    <Select
                      labelId={`unit-select-label-${index}`}
                      id={`unit-select-${index}`}
                      value={intervalObj.unit}
                      label="Unité"
                      onChange={(e) => handleIntervalChange(index, 'unit', e.target.value)}
                    >
                      <MenuItem value="months">Mois</MenuItem>
                      <MenuItem value="days">Jours</MenuItem>
                      <MenuItem value="hours">Heures</MenuItem>
                      <MenuItem value="minutes">Minutes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    fullWidth
                    label="Message"
                    value={intervalObj.message}
                    onChange={(e) => handleMessageChange(index, e)}
                    multiline
                    rows={2}
                    helperText={`${getMessageLength(intervalObj.message)}/320 caractères (${getSmsCount(getMessageLength(intervalObj.message))} SMS) | Utilisez *|FNAME|* pour insérer le prénom dynamique.`}
                    inputProps={{ maxLength: 320 }}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton onClick={() => handleRemoveInterval(index)} color="error">
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddInterval} sx={{ mb: 2 }}>
            Ajouter un intervalle
          </Button> <br />
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            {loading ? 'En cours...' : 'Créer le Rappel'}
          </Button>
        </Box>
      </LocalizationProvider>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CreateCountdownReminderForm;
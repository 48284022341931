import React from 'react';
import { Box, Toolbar, Typography, Paper, Skeleton, Stack } from '@mui/material';
import AppBarComponent from '../components/AppBar';
import DrawerBarandTabsXS from '../components/drawer/DrawerBarXS';
import DrawerBarMD from '../components/drawer/DrawerBarMD';

import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import { Helmet } from 'react-helmet-async';

function Subscription() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Abonnement et Crédit - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>Abonnement et Crédit</Typography>
                <Divider>Befiana Connect</Divider>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899 }}>
                    <Typography variant="body1" color="initial">Page en construction...</Typography>
                    <Box sx={{ width: '100%', mb: 2 }}>
                        <LinearProgress />
                    </Box>
                    <Stack spacing={1}>
                        <Skeleton animation="pulse" variant="rectangular" height={260} />
                        <Skeleton animation="pulse" variant="rectangular" height={60} />
                    </Stack>

                </Paper>


                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default Subscription;
import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import colorCode from '../../services/randomColor';

function AvaibleProgress() {
  const drivetotal = 1.5;
  const driveused = 0.03;
  const progress = (driveused / drivetotal) * 100; 

  return (
    <Box sx={{ width: '100%', mr: 1, mt:1 }}>
      <Typography variant="body2" color="text.secondary">Votre espace de stockage - {drivetotal} Go</Typography>
      <LinearProgress variant="determinate" value={progress} sx={{
        '& .MuiLinearProgress-bar': {
          backgroundColor: colorCode,
        },
      }}/>
      <Typography variant="body2" color="text.secondary"> {driveused} Go d’espace de Befiana Drive utilisé</Typography>
    </Box>
  );
}

export default AvaibleProgress;
import React from 'react';
import { Box, Toolbar, Paper } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import ContactsTable from './components/ContactsTable';
import { Helmet } from 'react-helmet-async';

function SMSContacts() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Mes Contacts SMS - BEFIANA Connect</title>
                <meta name="description" content="Créez et gérez des campagnes SMS en masse avec BEFIANA Connect. Solution simple et efficace pour atteindre vos clients par SMS." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, height: 510 }}>
                    <ContactsTable />

                </Paper>


                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSContacts;
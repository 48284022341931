import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box, Grid } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import api from '../../../../services/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { teal, orange, red } from '@mui/material/colors';

const MobileOperatorStats = () => {
    const [data, setData] = useState({ Telma: 0, Orange: 0, Airtel: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/operator-stats/') 
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    {/* Statistiques pour Telma */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center">
                            <PhoneAndroidIcon fontSize="large" sx={{ color: teal[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">Telma</Typography>
                                <Typography variant="h4" color="primary">
                                    {Number(data.Telma).toLocaleString('fr-FR')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Statistiques pour Orange */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center">
                            <PhoneAndroidIcon fontSize="large" sx={{ color: orange[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">Orange</Typography>
                                <Typography variant="h4" color="primary">
                                    {Number(data.Orange).toLocaleString('fr-FR')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* Statistiques pour Airtel */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="center">
                            <PhoneAndroidIcon fontSize="large" sx={{ color: red[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">Airtel</Typography>
                                <Typography variant="h4" color="primary">
                                    {Number(data.Airtel).toLocaleString('fr-FR')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MobileOperatorStats;
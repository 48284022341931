import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Button, Typography } from '@mui/material';
import TheBefiana from '../components/TheBefiana';
import { Link as RouterLink } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordResetForm from '../components/accounts/PasswordResetForm';

function PasswordResetValidate() {
    const { uidb64, token } = useParams();
    const [isValidToken, setIsValidToken] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/password-reset-validate/${uidb64}/${token}/`)
            .then((response) => {
                setIsValidToken(true);
            })
            .catch((error) => {
                setIsTokenError(true);
            });
    }, [uidb64, token, navigate]);

    return (
        <Grid container sx={{ bgcolor: '#f0f2f5', height: '100vh' }}>
            <Grid item xs={12} md={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, paddingRight: { sm: '11px' } }}>
                <TheBefiana />
            </Grid>
            <Grid item xs={12} md={5} sx={{ bgcolor: '#f0f2f5', paddingRight: { sm: '11px' }, marginTop: { xs: '10vh', md: '0px', lg: '0px', xl: '0px' }, marginLeft: { xs: '9px', md: '0px', lg: '0px', xl: '0px' }, marginRight: { xs: '9px', md: '0px', lg: '0px', xl: '0px' } }} display="flex" alignItems="center" justifyContent="center">
                <Paper
                    elevation={8}
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        margin: 'auto',
                        padding: 4,
                        backgroundColor: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.05) 0px 6px 10px 0px, rgba(0, 0, 0, 0.05) 0px 1px 18px 0px'
                    }}
                >
                    <Grid item>
                        <img src="/befiana.png" alt="Description" style={{ maxHeight: '71px', display: 'block', margin: '0 auto' }} />
                    </Grid>
                    <Typography variant="subtitle1" textAlign={'center'} component="h2" mt={2} gutterBottom>
                        Réinitialiser le mot de passe
                    </Typography>
                    <Helmet>
                        <title>Réinitialiser le mot de passe - BEFIANA Connect</title>
                        <meta name="description" content="Réinitialisez votre mot de passe pour accéder à Befiana Connect. Suivez les étapes simples pour rétablir l'accès à votre compte et continuer à utiliser nos services cloud centralisés de manière sécurisée." />
                    </Helmet>

                    {isTokenError && (
                        <Box>
                        <Alert severity='error' sx={{ mt: 2 }}> Le lien de réinitialisation n'est pas valide ou a expiré </Alert>
                        <Button
                            component={RouterLink}
                            to="/login"
                            type="button"
                            fullWidth
                            variant="outlined"
                            sx={{ mt: 2, mb: 2, p: 1 }}
                        >
                            Annuler
                        </Button> 
                        </Box>
                    )}

                    {isValidToken ? (
                        <PasswordResetForm uidb64={uidb64} token={token} />
                    ) : (
                        <Box>
                            <CircularProgress sx={{display: 'block', margin: '0 auto'}} />
                            <Typography variant="body1" color="initial" mt={2} align='center'>Vérification du jeton d'accès Befiana Connect en cours...</Typography>
                        </Box>
                    )}

                </Paper>
            </Grid>
        </Grid>
    )
}

export default PasswordResetValidate
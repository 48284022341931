import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Toolbar, Typography, Divider, Button, TextField, Card, CardContent, Paper, Stack, IconButton } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';

import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api';
import { Helmet } from 'react-helmet-async';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object({
    email: yup.string().email('Adresse e-mail invalide').required('L\'adresse e-mail est requise'),
}).required();

function MyAccountEmail() {

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [back, setBack] = useState("Annuler");

    const onSubmit = async data => {
        setIsLoading(true); 
        try {
            await api.post('/api/update-email/', data);
            setSnackbarMessage('Adresse e-mail mise à jour avec succès. Un e-mail de vérification a été envoyé.');
            setSnackbarOpen(true);
            setBack("Retour");
            reset(); 
        } catch (error) {
            setSnackbarMessage("Erreur lors de la mise à jour de l'adresse e-mail");
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false); 
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const action = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );


    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Courrier électronique - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Divider>Befiana Connect</Divider>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                    Informations sur vous et vos préférences dans les services Befiana
                </Typography>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899, borderRadius: '17px' }}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Mettre à jour votre adresse e-mail
                        </Typography>

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nouvelle adresse e-mail"
                                            type="email"
                                            fullWidth
                                            error={!!errors.email}
                                            helperText={errors.email ? errors.email.message : null}
                                            margin="normal"
                                        />
                                    )}
                                />

                                <Stack direction="row" spacing={2} justifyContent="space-between">

                                    <Button
                                        variant="outlined"
                                        sx={{ mt: 2, mb: 2, p: 1 }}
                                        startIcon={<ArrowBackIcon />}
                                        size="small"
                                        component={Link}
                                        to="/account"
                                    >
                                        {back}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{ mt: 2, mb: 2, p: 1 }}
                                        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}
                                        size="small"
                                    >
                                        Mettre à jour
                                    </Button>
                                </Stack>

                            </form>

                            <div>
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleCloseSnackbar}
                                    message={snackbarMessage}
                                    action={action}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            backgroundColor: '#006837',
                                            marginBottom: '75px'
                                        },
                                    }}
                                />
                            </div>


                        </CardContent>
                    </Card>

                </Paper>

                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default MyAccountEmail;
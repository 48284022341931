import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, TextField, Box, Backdrop, CircularProgress, Badge, Divider } from '@mui/material';
import { Send as SendIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import api from '../../../../services/api';
import ContactUpdateForm from './ContactUpdateForm';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import MessageForm from './MessageForm';
dayjs.locale('fr');

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

function ContactsTable() {
  const [contacts, setContacts] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/contacts/');
      setContacts(response.data);
      setOpen(false)
      setOpenMsg(false)
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    fetchContacts();  // Re-fetch contacts after an update
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  // Convert values to string for proper filtering
  const filteredContacts = contacts.filter((contact) => {
    const phoneStr = String(contact.phone_number);
    const firstNameStr = contact.first_name ? String(contact.first_name) : '';
    const positionStr = contact.position ? String(contact.position) : '';
    const companyStr = contact.company ? String(contact.company) : '';
    return phoneStr.includes(searchText) ||
      firstNameStr.toLowerCase().includes(searchText.toLowerCase()) ||
      positionStr.toLowerCase().includes(searchText.toLowerCase()) ||
      companyStr.toLowerCase().includes(searchText.toLowerCase());
  });

  const columns = [
    {
      field: 'actions', headerName: 'Envoyer SMS', width: 100, renderCell: (params) => (
        // <IconButton onClick={() => window.open(`/send-sms/${params.id}`, '_blank')}>
        <IconButton onClick={() => { setSelectedContact(params.row); setOpenMsg(true); }}>
          <SendIcon color="primary" />
        </IconButton>
      ), sortable: false, filterable: false
    },
    { field: 'phone_number', headerName: 'Numéro téléphone', flex: 1, minWidth: 150, filterable: true },
    { field: 'first_name', headerName: 'Prénom', flex: 1, minWidth: 150, filterable: true },
    { field: 'position', headerName: 'Poste', flex: 1, minWidth: 150, filterable: true },
    { field: 'company', headerName: 'Entreprise', flex: 1, minWidth: 150, filterable: true },
    {
      field: 'details', headerName: 'Détails', width: 100, renderCell: (params) => (
        <IconButton onClick={() => { setSelectedContact(params.row); setOpen(true); }}>
          <VisibilityIcon color='primary' />
        </IconButton>
      ), sortable: false, filterable: false
    },
  ];

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <TextField
        variant="outlined"
        value={searchText}
        onChange={handleSearch}
        placeholder="Rechercher des contacts..."
        fullWidth
        sx={{ mb: 2 }}
      />
      <DataGrid
        rows={filteredContacts}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25, 50, 100]}
        disableSelectionOnClick
        components={{ Toolbar: CustomToolbar }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />

      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="contact-details-dialog">
        <DialogTitle id="contact-details-dialog">Détails du contact <Badge badgeContent={selectedContact.id} color="success" max={90000000}><BadgeIcon color="action" /></Badge>
        </DialogTitle>
        <Divider ></Divider>
        <DialogContent>

          <ContactUpdateForm contact={selectedContact} onUpdate={handleUpdate} />
          <div><b>Date :</b> {dayjs(selectedContact.added_date).format('D MMMM YYYY à H:mm')}</div>
          <div><b>Mise à jour:</b> {dayjs(selectedContact.updated_date).format('D MMMM YYYY à H:mm')}</div>
          <div><b>État:</b> {selectedContact.status === 1 ? 'Actif' : 'Désactivé'}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openMsg} onClose={() => setOpenMsg(false)} aria-labelledby="contact-details-dialog">
        <DialogTitle id="contact-details-dialog">Envoyer un SMS </DialogTitle>
        <Divider ></Divider>
        <DialogContent>

          <MessageForm sendTo={"0"+selectedContact.phone_number}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMsg(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>



      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
}

export default ContactsTable;
import React, { useEffect, useState } from 'react';
import {Typography, Box, Avatar} from '@mui/material';

import api from '../../services/api';
import colorCode from '../../services/randomColor';
import EmailVerificationAlert from '../messages/ResendEmail';

function AboutIntro() {
  // const profilPicture = false
  const [profilPicture, setProfilPicture] = useState(false)
  const [userProfile, setUserProfile] = useState({
    firstName: ' ',
    lastName: ' ',
    email_verified: true, 
    imageUrl: null,
  });


  useEffect(() => {
    api.get('api/user-info/')
      .then(response => {
        setUserProfile({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email_verified: response.data.email_verified
        });
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {console.error('Erreur lors de la récupération des informations utilisateur:', error);}
        
      });


      // Récupération de l'image de profil
    api.get('/api/profile-image/')
    .then(response => {
      if (response.data && response.data.image) {
        setProfilPicture(true)
        setUserProfile(prevState => ({ ...prevState, imageUrl: response.data.image }));
      }
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {console.error("Erreur lors de la récupération de l'image de profil:", error);}
      
    });

  }, []);


  function getInitials(fullname) {
    const words = fullname.split(' ');
    let initials = words[0][0]; 
    if (words.length > 1) {
      initials += words[1][0]; 
    }
    return initials;
  }
  const initials = getInitials(userProfile.firstName + " " + userProfile.lastName);

  return (
    <Box>

        {!userProfile.email_verified && (
                <EmailVerificationAlert />
        )}

        <Box>
          {profilPicture ? (
            <Avatar alt={`${userProfile.firstName} ${userProfile.lastName}`} src={userProfile.imageUrl} sx={{ width: 119, height: 119, margin: '0 auto' }} />
          ) : (
            <Avatar sx={{ bgcolor: colorCode, width: 119, height: 119, fontSize: 65, margin: '0 auto' }} alt={`${userProfile.firstName} ${userProfile.lastName}`} >{initials}</Avatar>
          )}
        </Box>
        <Typography variant="h4" mt={1} color="initial" align='center'>Bienvenue, {userProfile.firstName}</Typography>
    </Box>
  )
}

export default AboutIntro
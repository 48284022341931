import React from 'react';
import { Card, CardContent, Typography, Button, Link, Box, CardMedia } from '@mui/material';

function GuideCard() {
  return (
    <Box sx={{ maxWidth: 899, margin: 'auto', mt: 4 }}>
      <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, padding: 2, boxShadow: 3, borderRadius: 2 }}>
        <CardMedia
          component="img"
          sx={{ width: { xs: '100%', sm: 160 }, borderRadius: 1 }}
          image="https://help.befiana.cloud/wp-content/uploads/2024/06/DOWNLOAD-YOUR-FREE-GUIDE1.jpg"  
          alt="BEFIANA Docs"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, pl: { sm: 2 } }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
            Besoin de conseils pour démarrer ?
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
            Visitez BEFIANA Docs et apprenez à utiliser efficacement les SMS by BEFIANA sur notre site.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              href="https://help.befiana.cloud/docs-category/sms-by-befiana/"
              target="_blank"
              rel="noopener"
              sx={{ mt: 2, textTransform: 'none' }}
            >
              Accéder aux Docs
            </Button>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}

export default GuideCard;
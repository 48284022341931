import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Box, Typography } from '@mui/material';
import api from '../../../../services/api';

const StoreConsumptionTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/store-consumption')  
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    const columns = [
        { field: 'store', headerName: 'Code du Magasin', width: 100 },
        { field: 'store_name', headerName: 'Nom du Magasin', width: 250 },
        { field: 'transaction_count', headerName: 'iBondacha', width: 120 },
        { field: 'average_cart', headerName: 'Panier Moyen  (Ar)', width: 200 },
        { field: 'total_consumption', headerName: 'Consommation Totale (Ar)', width: 200 }
    ];

    return (
        <Box height={400} width="100%">
            <DataGrid
                rows={data.map((row, index) => ({ id: index, ...row }))}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
            />
        </Box>
    );
};

export default StoreConsumptionTable;
import React from 'react';
import { Box, Toolbar, Paper, Typography, Divider, Button, Stack } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import DeleteAllContacts from './components/DeleteAllContacts';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SysSystem from './components/SysSystem';
import ActivationRequestForm from './components/ActivationRequestForm';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SMSActivationStatus from './components/SMSActivationStatus';
import CakeIcon from '@mui/icons-material/Cake';
import BirthdaySMSToggle from './components/BirthdaySMSToggle';
import { Helmet } from 'react-helmet-async';

function SMSSetting() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Configuration SMS - BEFIANA Connect</title>
                <meta name="description" content="Créez et gérez des campagnes SMS en masse avec BEFIANA Connect. Solution simple et efficace pour atteindre vos clients par SMS." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <SysSystem />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, p: 2, mb: 3, maxWidth: 899 }}>

                    <Button variant="text" color="primary" startIcon={<AssignmentIcon />}>
                        Vérification d'identité et d'entreprise
                    </Button>
                    <Divider ></Divider>
                    <br />
                    <SMSActivationStatus />
                    <ActivationRequestForm />

                </Paper>

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, p: 2, mb: 3, maxWidth: 899 }}>

                    <Button variant="text" color="primary" startIcon={<CakeIcon />}>
                        Automatisez les SMS d'anniversaire
                    </Button>
                    <Divider ></Divider>
                    <br />
                    <BirthdaySMSToggle />

                </Paper>


                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899 }}>

                    <Button variant="text" color="primary" startIcon={<AppSettingsAltIcon />}>
                    Mes Contacts
                    </Button>
                    <Divider ></Divider>

                    <Stack spacing={2} direction="row">
                        <Box>
                            <PrivacyTipIcon color='primary' sx={{ fontSize: 150 }} />
                        </Box>
                        <Box>
                            <Typography variant="body2" color="initial" mt={1} mb={1}>Nous vous assurons que toutes vos données de contacts ne seront en aucun cas partagées avec des services tiers. Chaque entreprise ou établissement possède son propre numéro. Nous garantissons également qu'aucune de vos données ne sera utilisée à des fins de diffusion publicitaire commerciale. Seul le propriétaire a le droit de diffuser une campagne ou d'envoyer un message.</Typography>
                            <Typography variant="body2" color="secondary" mb={1}>Vous pouvez supprimer tous vos contacts ici. Veuillez noter que cette action est irréversible et supprimera également les numéros de vos listes. Vous pouvez également les supprimer un par un dans la section Mes Contacts</Typography>

                        </Box>
                        </Stack>

                    <DeleteAllContacts />

                </Paper>

                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSSetting;
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, List, ListItem, ListItemText, Divider, Paper, Snackbar, IconButton, Backdrop, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder'; 
import BusinessIcon from '@mui/icons-material/Business';
import api from '../../../../services/api';

function AddListBusiness() {
    const [groups, setGroups] = useState([]);
    const [newBusinessName, setNewBusinessName] = useState('');
    const [loader, setLoader] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedListId, setSelectedListId] = useState(null);

    useEffect(() => {
        fetchBusiness();
    }, []);

    const fetchBusiness = async () => {
        const response = await api.get('/api/ibondacha/beneficiaries/');
        setGroups(response.data);
    };

    const handleAddBusiness = async () => {
        setLoader(true);
        if (newBusinessName) {
            const response = await api.post('/api/ibondacha/beneficiaries/', { name: newBusinessName });
            if (response.status === 201) {
                setGroups([...groups, response.data]);
                setNewBusinessName('');
                setSnackbarMessage('Entreprise ajouté avec succès');
                setSnackbarOpen(true);
                fetchBusiness();
                setLoader(false);
            }
        }
    };

    const handleDeleteBusiness = async (id, event) => {
        event.stopPropagation();  
        setLoader(true);
        const response = await api.delete(`/api/ibondacha/beneficiaries/${id}/`);
        if (response.status === 204) {
            setGroups(groups.filter(group => group.id !== id));
            setSnackbarMessage('Entreprise supprimé avec succès');
            setSnackbarOpen(true);
            setLoader(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleListItemClick = (listId) => {
        setSelectedListId(listId);
    };

    const handleCloseDialog = () => {
        setSelectedListId(null);
    };

    const handleCloseLoader = () => {
        setLoader(false);
      };
  return (
    <Box sx={{ p: 2 }}>
           
        <Paper elevation={1} sx={{p:3}}>
        <TextField
                label="Nouveau nom d'entreprise"
                value={newBusinessName}
                onChange={(e) => setNewBusinessName(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="ib1" onClick={handleAddBusiness} disabled={!newBusinessName}>
                Créer un entreprise
            </Button>
            <List sx={{ mt: 2, bgcolor: 'background.paper' }}>
                {groups.map(group => (
                    <React.Fragment key={group.id}>
                        <ListItem
                            button
                            onClick={() => handleListItemClick(group.id)}
                            secondaryAction={
                                <IconButton edge="end" onClick={(event) => handleDeleteBusiness(group.id, event)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <BusinessIcon color="ib1" sx={{ mr: 1 }} /> 
                            <ListItemText primary={group.name} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Paper>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />

            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
            onClick={handleCloseLoader}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            
    </Box>
  )
}

export default AddListBusiness
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, Snackbar } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import LastPasswordChange from '../../components/accounts/LastPasswordChange';

const schema = yup.object({
    old_password: yup.string().required('L\'ancien mot de passe est obligatoire'),
    new_password: yup.string().required('Le nouveau mot de passe est obligatoire')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Le mot de passe doit contenir au moins 8 caractères, dont au moins une majuscule, une minuscule, un chiffre et un symbole spécial (@$!%*?&)'),
    confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Les mots de passe doivent correspondre').required('La confirmation du mot de passe est obligatoire'),
}).required();

export default function ChangePassword() {
    const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const [showPassword, setShowPassword] = useState({ old: false, new: false, confirm: false });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const toggleShowPassword = (field) => {
        setShowPassword({ ...showPassword, [field]: !showPassword[field] });
    };

    const onSubmit = async data => {
        try {
            await api.put('/api/update-password/', {
                old_password: data.old_password,
                new_password: data.new_password,
            });
            setSnackbarMessage('Mot de passe mis à jour avec succès');
            setSnackbarOpen(true);
            reset(); 
            setTimeout(() => navigate('/account'), 6000); 
        } catch (error) {
            setSnackbarMessage(error.response.data.detail || 'Erreur lors de la mise à jour du mot de passe');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Typography variant="h6" gutterBottom>Modifiez votre mot de passe</Typography>
            <LastPasswordChange />

            <Controller
                name="old_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Ancien mot de passe"
                        type={showPassword.old ? 'text' : 'password'}
                        error={!!errors.old_password}
                        helperText={errors.old_password?.message}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => toggleShowPassword('old')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.old ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

            <Controller
                name="new_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Nouveau mot de passe"
                        type={showPassword.new ? 'text' : 'password'}
                        error={!!errors.new_password}
                        helperText={errors.new_password?.message}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => toggleShowPassword('new')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.new ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

            <Controller
                name="confirm_password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        label="Confirmez le nouveau mot de passe"
                        type={showPassword.confirm ? 'text' : 'password'}
                        error={!!errors.confirm_password}
                        helperText={errors.confirm_password?.message}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => toggleShowPassword('confirm')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Mettre à jour</Button>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackbarMessage} sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#006837',
                    marginBottom: '75px'
                },
            }} />
        </Box>
    );
}
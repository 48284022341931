import React from 'react';
import { Box, Toolbar, Typography, Divider } from '@mui/material';
import AppBarComponent from '../components/AppBar';
import DrawerBarandTabsXS from '../components/drawer/DrawerBarXS';
import DrawerBarMD from '../components/drawer/DrawerBarMD';

import AboutUser from '../components/accounts/AboutUser';
import AboutIntro from '../components/accounts/AboutIntro';
import AboutAdress from '../components/accounts/AboutAdress';
import ListeAdresses from '../components/accounts/AboutListeAdresses';
import { Helmet } from 'react-helmet-async';

function Account() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Helmet>
          <title>Mon Compte - Osez l'infini !</title>
          <meta name="description" content="Osez l'infini avec BEFIANA Connect !" />
      </Helmet>
      <AppBarComponent />
      <DrawerBarMD />

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />

        <AboutIntro />

        <Divider>Befiana Connect</Divider>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
          Informations sur vous et vos préférences dans les services Befiana
        </Typography>
        <br />

        <AboutUser />
        <AboutAdress />
        
        <ListeAdresses />
        {/* <AboutCoord />   */}

        <DrawerBarandTabsXS />
      </Box>
    </Box>
  );
}

export default Account;
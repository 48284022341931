import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import useStoreAuth from '../../../hooks/useStoreAuth ';
import CashRegisterMobile from './components/CashRegisterMobile';
import VoucherTableBoxMobile from './components/VoucherTableBoxMobile';
import { Helmet } from 'react-helmet-async';

function IBCashBoxMobile() {
    useStoreAuth();

    const [store, setStore] = useState('Magasin');

    useEffect(() => {
        const storeName = localStorage.getItem('store_name');
        setStore(storeName);
    }, [])

    return (
        <Box>
            <Box sx={{ margin: '0px', padding: '0px' }}>
                <Box component="main" sx={{ bgcolor: 'background.default', margin: '0px' }}>
                    <Box sx={{ display: 'block', margin: '0 auto', maxWidth: 375, padding: '10px' }}>
                        <Helmet>
                            <title>Caisse - iBondacha</title>
                            <meta name="robots" content="noindex" />
                        </Helmet>
                        <Link to="/App/iBondacha/cash-m">
                            <img src="/ibondacha.png" width="150px" alt="Logo iBondacha" style={{ display: 'block', margin: '0 auto', paddingBottom: '0px' }} />
                        </Link>
                        <Box>
                            <Button variant="contained" color="ib1" sx={{ display: 'block', margin: '0 auto', opacity: 0.5 }} disableElevation>
                                {store}
                            </Button>
                        </Box>
                    </Box>
                    <CashRegisterMobile />
                    <VoucherTableBoxMobile />
                    <br /><br /><br />
                </Box>
            </Box>
        </Box>
    );
}

export default IBCashBoxMobile;
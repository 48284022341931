import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider, List, ListItem, Tooltip, ListItemButton, ListItemIcon, ListItemText, AccordionSummary, Accordion, AccordionDetails, Button } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SettingsIcon from '@mui/icons-material/Settings';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import api from '../../../../services/api';

dayjs.extend(utc);
dayjs.extend(timezone);

const getMadagascarTime = () => {
    return dayjs().tz('Indian/Antananarivo').format('D MMMM YYYY à H:mm');
};

function SysSystem() {
    const [senderName, setSenderName] = useState('BEFIANA');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSenderName = async () => {
            try {
                const response = await api.get('/api/smsko/get-sender-name/');
                setSenderName(response.data.sender_name);
            } catch (error) {
                // console.error('Erreur lors de la récupération du nom de l\'expéditeur:', error);
                setSenderName('SERVICE NON ACTIVE');
            } finally {
                setLoading(false);
            }
        };

        fetchSenderName();
    }, []);

    const madagascarTime = getMadagascarTime();

    return (
        <Paper elevation={1} sx={{ margin: 'auto', mt: 1, p: 2, mb: 3, maxWidth: 899 }}>
            <Button variant="text" color="primary" startIcon={<SettingsIcon />}>
                Configuration du système
            </Button>
            <Divider></Divider>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccessAlarmIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={`Fuseau horaire: Heure de Madagascar (GMT+3) - ${madagascarTime}`} />
                    </ListItemButton>
                </ListItem>
                <Tooltip title="Si vous avez besoin d'aide, appelez ce numéro : 032 63 166 59 / 034 09 721 93" arrow placement='bottom-start'>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <BadgeIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary={`Nom de l'expéditeur : ${loading ? 'Chargement...' : senderName}`} />
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            </List>

            <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography><b>Vous pouvez personnaliser le nom de l'expéditeur avec celui de votre entreprise.</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    
                    <Typography variant="subtitle2" color="initial">Étapes et conditions :</Typography>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Les opérateurs à Madagascar </b> appliquent des règles strictes concernant les noms d'expéditeurs. Votre marque doit être inscrite sur la liste blanche des trois opérateurs locaux. Nous nous chargeons de transmettre votre demande accompagnée de vos informations légales : <b>nom du représentant, siège social, NIF, STAT, RCS, ainsi que les détails sur vos activités et l’utilisation prévue des SMS. </b> <br /> <i>(Si vous avez déjà fourni ces informations lors de votre demande d’activation, cette étape peut être ignorée, car nous les avons déjà enregistrées.)</i>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Coût de l’opération :</b> 90 000 Ar, paiement unique, avec un bonus de 1 000 SMS offerts après approbation du nom d’expéditeur par les opérateurs.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Délai : </b> La personnalisation prend généralement entre 24 et 48 heures après la soumission de la demande.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Longueur du nom :</b> Le nom d’expéditeur peut comporter jusqu’à 11 caractères maximum, espaces inclus, et ne doit pas contenir de caractères spéciaux.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Typography variant="subtitle2" color="primary">Appelez ce numéro 032 63 166 59 / 034 09 721 93 ou envoyez un email à service.client@befiana.com pour demander la personnalisation du nom d'expéditeur de votre SMS au nom de votre entreprise.</Typography> 
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}

export default SysSystem;
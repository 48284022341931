import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider, LinearProgress, Box } from '@mui/material';
import GoSMSSendLater from '../../../../components/lotties/SmsSendLater';

function PendingSms({ count }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <LinearProgress sx={{ width: '100%' }} />
      </Box>
    );
  }

  return (
    <Paper elevation={1} sx={{ margin: 'auto', mt: 3, p: 2, mb: 3, maxWidth: 899, borderRadius: 5 }}>
      <Divider />
      <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
        <GoSMSSendLater />
        <Typography variant="body1">
          Vous avez {count} SMS en attente.
        </Typography>
        <Box width="100%" mt={2}>
          <LinearProgress variant="indeterminate" />
          <Typography variant="caption" display="block" textAlign="center" mt={1}>
            Envoi de SMS en cours ... ou en attente de programmation
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

export default PendingSms;

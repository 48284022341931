import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import InfoIcon from '@mui/icons-material/Info';
import AirIcon from '@mui/icons-material/Air';

import api from '../../services/api';



function Weather() {
    const [weather, setWeather] = useState(null);
    const city = 'Antananarivo';

    useEffect(() => {
        const lang = 'fr';
        const lat = '-18.9137';
        const lon = '47.5361';
        const weatherCache = localStorage.getItem('Befiana_weatherData');

        if (weatherCache) {
            const cachedData = JSON.parse(weatherCache);
            const now = new Date();
            if (now.getTime() - cachedData.timestamp < 10 * 60 * 1000) { 
                setWeather(cachedData.data);
                return;
            }
        }

        const url = `api/weather/?lat=${lat}&lon=${lon}&lang=${lang}`;

        api.get(url)
            .then(response => {
                setWeather(response.data);
                localStorage.setItem('Befiana_weatherData', JSON.stringify({ timestamp: new Date().getTime(), data: response.data }));
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des données météo:", error);
            });
    }, []);

    if (!weather) return <div>Chargement des données météo...</div>;

    const iconCode = weather.weather[0].icon;
    const iconUrl = `https://openweathermap.org/img/wn/${iconCode}@2x.png`;

    const temperature = weather.main.temp_min + " à " + Math.round(weather.main.temp_max) + " °С ";
    const condition = "Conditions: " + weather.weather[0].description;
    const vent = "Vent " + weather.wind.speed + "m/s - Humidité : " + weather.main.humidity + "%";

    return (
        <Box>
            <Typography variant="h5" color="primary" align='center'>Météo à {city}</Typography>
            <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }} spacing={2} p={1}>
                <Box>
                    <img src={iconUrl} alt="Weather icon" height={'150px'} />
                    <Button variant="contained" color="primary">
                        {Math.round(weather.main.temp)}°C
                    </Button>
                </Box>
                <Box>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader" >
                        <ListItemButton><ListItemIcon><DeviceThermostatIcon /></ListItemIcon><ListItemText primary={temperature} /></ListItemButton>
                        <ListItemButton><ListItemIcon><InfoIcon /></ListItemIcon><ListItemText primary={condition} /></ListItemButton>
                        <ListItemButton><ListItemIcon><AirIcon /></ListItemIcon><ListItemText primary={vent} /></ListItemButton>
                    </List>
                    <Typography variant="body2" color="initial" align='center' mt={1}>Bientôt par localisation</Typography>
                </Box>
            </Stack>
        </Box>
    );
}

export default Weather;
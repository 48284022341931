import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Card, CardContent, CircularProgress, Box, Typography } from '@mui/material';
import api from '../../../../services/api';

const TotalAmountPerMonth = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/total-amount-per-month')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Card elevation={1}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Montant total des iBondacha d'achat par mois
                </Typography>
                <Box height={400}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* Rendre XAxis uniquement si data n'est pas vide */}
                            {data.length > 0 && <XAxis dataKey="month" />}
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total_amount" fill="#82ca9d" name="Montant Total" />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </CardContent>  
        </Card>
    );
};

export default TotalAmountPerMonth;
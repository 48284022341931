import React, { useState } from 'react';
import { Box, Grid, Typography, Button, Paper, TextField, Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CountrySelect from '../../../src/pages/MyAccount/CountrySelect';
import { useNavigate } from 'react-router-dom';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';

// Validation schema
const schema = yup.object({
    company_name: yup.string(),
    address_line_1: yup.string().required('L\'adresse est obligatoire'),
    address_line_2: yup.string(),
    city: yup.string().required('La ville est obligatoire'),
    region: yup.string().required('La région est obligatoire'),
    postal_code: yup.string().required('Le code postal est obligatoire'),
    country: yup.string().required('Le pays est obligatoire'),
    phone: yup.string().required('Le téléphone est obligatoire').matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, 'Numéro de téléphone invalide'),
    address_type: yup.string().required('Le type d\'adresse est obligatoire'),
}).required();


function MyAccountCoordAdd() {
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            await api.post('/api/addresses/', data);
            setIsSubmitting(true);
            setMessage('Adresse ajoutée avec succès.');
            setTimeout(() => {
                navigate('/account');
            }, 3000);
            setIsLoading(false);
        } catch (error) {
            setMessage('Erreur lors de l\'ajout de l\'adresse.');
            setIsLoading(false);
            setIsSubmitting(false);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 10 }}>

                <Box display="flex" alignItems="center" gap={1} mb={2}>
                    <AddHomeWorkIcon color="primary" />
                    <Typography variant="h6" color="primary">
                        Ajouter une adresse
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="company_name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Nom d'entreprise"
                                        fullWidth
                                        error={!!errors.company_name}
                                        helperText={errors.company_name?.message || ""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="address_line_1"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Adresse Ligne 1"
                                        fullWidth
                                        error={!!errors.address_line_1}
                                        helperText={errors.address_line_1?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="address_line_2"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Adresse Ligne 2"
                                        fullWidth
                                        error={!!errors.address_line_2}
                                        helperText={errors.address_line_2?.message || ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="city"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Ville"
                                        fullWidth
                                        error={!!errors.city}
                                        helperText={errors.city?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="region"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Région/État/Province"
                                        fullWidth
                                        error={!!errors.region}
                                        helperText={errors.region?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="postal_code"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Code Postal / ZIP"
                                        fullWidth
                                        error={!!errors.postal_code}
                                        helperText={errors.postal_code?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <CountrySelect
                                        {...field}
                                        onChange={(option) => field.onChange(option.value)} // Ajustez ici
                                        value={field.value}
                                        error={!!errors.country}
                                        helperText={errors.country?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="phone"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Téléphone"
                                        fullWidth
                                        error={!!errors.phone}
                                        helperText={errors.phone?.message || "Commencez par l'indicatif du pays comme +26132xxxxxxx"}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address_type"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Type d'adresse"
                                        fullWidth
                                        error={!!errors.address_type}
                                        helperText={errors.address_type?.message || 'Domicile, Travail, etc.'}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Button type="submit" variant="contained" disabled={isSubmitting} fullWidth>
                                Ajouter
                            </Button>
                            {isLoading && (
                                <CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Paper>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#006837',
                        marginBottom: '75px'
                    },
                }} />
        </Box>
    );
}

export default MyAccountCoordAdd;  
import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Typography, ListItemIcon, Badge, Grid } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppsIcon from '@mui/icons-material/Apps';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import apps from './drawer/appsRendu';


function AppBarComponent() {
    // const [nbNotification, setNbNotification] = useState(0);
    const [appsAnchorEl, setAppsAnchorEl] = useState(null);

    const handleMenu = (event, anchorSetter) => {
        anchorSetter(event.currentTarget);
    };

    const handleClose = () => {
        setAppsAnchorEl(null);
    };

    const theme = useTheme(); // Utilisez useTheme pour accéder au thème
    const renderAppsMenu = (
        <Menu
            anchorEl={appsAnchorEl}
            open={Boolean(appsAnchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{
                marginTop: '55px',
                '& .MuiPaper-root': { // Cible le root du composant papier utilisé par Menu
                    maxWidth: '100%',
                    width: 'auto',
                    [theme.breakpoints.up('sm')]: {
                        maxWidth: '350px',
                    },
                },
            }}
        >
            <Grid container spacing={0}>
                {apps.map((app, index) => (
                    <Grid item xs={6} key={index} component={Link} 
                    to={app.to} sx={{textDecoration: 'none'}}> 
                        <MenuItem onClick={handleClose} disabled={app.release} >
                            <ListItemIcon sx={{ color: app.color }}>
                                {app.icon}
                            </ListItemIcon>
                            <Typography variant="inherit">{app.name}</Typography>
                        </MenuItem>
                    </Grid>
                ))}
            </Grid>
        </Menu>
    );

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>

                {<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 5 }}>
                    <Link to="/">
                        <img src="/befiana.png" alt="Logo Befiana Platform Corps" style={{ maxHeight: '51px', marginTop: '7px' }} />
                    </Link>
                </Typography>}
                <IconButton 
                    color="inherit"
                    component={Link}
                    to="/notification"
                >
                    <Badge badgeContent={0} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <IconButton color="inherit" onClick={(event) => handleMenu(event, setAppsAnchorEl)}>
                    <AppsIcon />
                </IconButton>
                <IconButton
                    edge="end"
                    aria-label="mon compte"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    component={Link}
                    to="/account"
                >
                    <AccountCircle />
                </IconButton>
            </Toolbar>
            {renderAppsMenu}
        </AppBar>
    )
}

export default AppBarComponent
import React from 'react';
import { Box, Paper, Grid, Toolbar, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import IBAppBarComponent from './components/AppBar';
import IBDrawerBarMD from './drawer/Drawer';
import IBDrawerTabsXS from './drawer/DrawerXS';
import { Helmet } from 'react-helmet-async';

import ActiveVouchersSummary from './components/ActiveVouchersSummary';
import MobileOperatorStats from './components/MobileOperatorStats';
import BeneficiaryStats from './components/BeneficiaryStats';
import GlobalStats from './components/GlobalStats';
import AverageUsageDelay from './components/AverageUsageDelay';
import TotalAmountPerMonth from './components/TotalAmountPerMonth';
import NotificationCountPerMonthTable from './components/NotificationCountPerMonth';
import StoreConsumptionTable from './components/StoreConsumptionTable';


function IBStatistic() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>iBondacha</title>
            </Helmet>
            <IBAppBarComponent />
            <IBDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>iBondacha</Typography>
                <Divider>DASHBOARD</Divider>
                <br />

                <Grid container spacing={3}>
                    <Grid item xs={6} md={6} sx={{mb: 3}} key="active-a-usagedelay">
                        <AverageUsageDelay />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} key="active-summary">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6">Résumé des iBondacha actifs</Typography>
                            <ActiveVouchersSummary />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} key="active-operators">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6">Statistiques des opérateurs mobiles</Typography>
                            <MobileOperatorStats />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} key="active-business">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6">Statistiques des entreprises </Typography>
                            <BeneficiaryStats />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} key="active-globale">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <Typography variant="h6">Statistiques globales </Typography>
                            <GlobalStats />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} key="active-a-permonth">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <TotalAmountPerMonth />
                        </Paper>
                    </Grid> 

                    
                    <Grid item xs={12} md={8} key="conso-magasin">
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <StoreConsumptionTable />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} key="active-month-notifaction">
                        <Paper elevation={0} style={{ padding: '20px' }}>
                            <NotificationCountPerMonthTable />
                        </Paper>
                    </Grid>

                </Grid>


            </Box>

            <IBDrawerTabsXS />
        </Box>
    );
}

export default IBStatistic;
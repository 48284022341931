import React, { useState, useEffect } from 'react';
import { Typography, Paper, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import api from '../../../../services/api';

function UnpaidInvoices({refresh}) {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);

    dayjs.locale('fr');  

    useEffect(() => {
        fetchInvoices();
    }, [refresh]);

    const fetchInvoices = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/sms/purchases/unpaid/');
            setInvoices(response.data);
        } catch (error) {
            console.error('Failed to fetch unpaid invoices:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePayment = (invoiceId) => {
        console.log('Initiate payment for invoice:', invoiceId);
        // Ajoutez ici la logique pour initier le paiement
    };

    const columns = [
        { field: 'purchase_date', headerName: 'Date d\'Achat', width: 200, valueGetter: ({ value }) => dayjs(value).format('DD MMMM YYYY à HH:mm') },
        { field: 'transaction_ref', headerName: 'Réf. Transaction', width: 180 },
        { field: 'quantity', headerName: 'Quantité', type: 'number', width: 120 },
        { field: 'total_price', headerName: 'Prix Total', type: 'number', width: 130 },
        { field: 'payment_status', headerName: 'Statut', width: 150, renderCell: (params) => params.value === 0 ? 'Non payé' : <Typography variant='body2' color="secondary" mt={2}>Vérification</Typography> },
        {
            field: 'actions',
            headerName: 'Actions (Soon)',
            sortable: false,
            width: 190,
            renderCell: (params) => {
                return params.row.payment_status === 0 ? (
                    <Button disabled color="primary" onClick={() => handlePayment(params.id)}>Payer (Tel: 032 63 166 59)</Button>
                ) : (
                    <Typography variant='body2' mt={2}>En attente de vérification</Typography>
                );
            },
        },
    ];

    return (
        <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
            <Typography variant="h6" color="primary" m={1}>Historique des Achats Impayés</Typography>
            <Box sx={{ height: 250, width: '100%' }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <DataGrid
                        rows={invoices}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                )}
            </Box>
        </Paper>
    );
}

export default UnpaidInvoices;
import React from 'react';
import { Box, Toolbar, Typography, Grid, Divider, Card, CardActionArea, CardContent, CardMedia, Paper, Button } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import RepeatIcon from '@mui/icons-material/Repeat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CakeIcon from '@mui/icons-material/Cake';
import BirthdaySMSToggle from './components/BirthdaySMSToggle'; // Assurez-vous d'importer correctement ce composant

function SMSReminders() {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: 'background.default' }}>
            <Helmet>
                <title>Rappels Intelligents avec SMS by BEFIANA</title>
                <meta name="description" content="Gérez vos rappels SMS périodiques et avant événement avec BEFIANA Connect." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, p: 4 }}>
                <Toolbar />
                <Typography variant="h3" align="center" sx={{ fontWeight: 700, mb: 2, color: 'primary.main' }}>
                    Rappels SMS Intelligents
                </Typography>
                <Divider variant="middle" sx={{ mb: 4 }}>Gestion des Rappels - SMS by BEFIANA</Divider>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card
                            sx={{
                                maxWidth: 400,
                                mx: 'auto',
                                boxShadow: 4,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': { transform: 'scale(1.05)', boxShadow: 6 },
                            }}
                        >
                            <CardActionArea onClick={() => navigate('/App/SmsKo/periodic-reminders')}>
                                <CardMedia
                                    sx={{
                                        height: 200,
                                        bgcolor: 'primary.main',
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RepeatIcon sx={{ fontSize: 80, color: '#fff' }} />
                                </CardMedia>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
                                        Périodiques & Répétitifs
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Créez et gérez des rappels SMS qui se répètent à des intervalles réguliers pour divers besoins spécifiques.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card
                            sx={{
                                maxWidth: 400,
                                mx: 'auto',
                                boxShadow: 4,
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': { transform: 'scale(1.05)', boxShadow: 6 },
                            }}
                        >
                            <CardActionArea onClick={() => navigate('/App/SmsKo/countdown-reminders')}>
                                <CardMedia
                                    sx={{
                                        height: 200,
                                        bgcolor: 'success.main',
                                        position: 'relative',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <EventAvailableIcon sx={{ fontSize: 80, color: '#fff' }} />
                                </CardMedia>
                                <CardContent>
                                    <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
                                        Événements & RDV
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                        Planifiez des séries de rappels SMS, plusieurs et différents, à envoyer avant un événement spécifique.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>

                {/* Nouvelle section pour le widget SMS d'anniversaire */}
                <Paper elevation={1} sx={{ margin: 'auto', mt: 4, p: 3, mb: 3, maxWidth: 899, textAlign: 'center' }}>
                    <Button variant="text" color="primary" startIcon={<CakeIcon />} sx={{ mb: 2, fontWeight: 'bold' }}>
                        Automatisez les SMS d'anniversaire
                    </Button>
                    <Divider sx={{ mb: 2 }} />
                    <BirthdaySMSToggle />
                </Paper>

                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSReminders;

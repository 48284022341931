import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PolicyIcon from '@mui/icons-material/Policy';
import HelpIcon from '@mui/icons-material/Help';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';

const NavHomeBC = { "color": "#006837", "name": "Home", "icon": <HomeIcon />, "to": "/" };
const NavSetting = { "color": "#006837", "name": "Paramètre", "icon": <SettingsIcon />, "to": "/setting" };
const NavAccount = { "color": "#006837", "name": "Mon Compte", "icon": <AccountCircle />, "to": "/account" };
const NavSubscribe = { "color": "#006837", "name": "Abonnement & Crédit", "icon": <CreditCardIcon />, "to": "/subscription" };
const NavPolicy = { "color": "#006837", "name": "Confidentialité", "icon": <PolicyIcon />, "to": "/policy" };
const NavHelp = { "color": "#006837", "name": "Aide et Assistance", "icon": <HelpIcon />, "to": "https://help.befiana.cloud" };
const NavHistory = { "color": "#006837", "name": "Historique et Connexion", "icon": <HistoryIcon />, "to": "/history" };
const NavLogOut = { "color": "#000", "name": "Déconnexion", "icon": <LogoutIcon />, "to": "/logout" };

const navDrawer = [NavHomeBC, NavAccount, NavSetting, NavSubscribe, NavPolicy, NavHelp, NavHistory, NavLogOut];

export default navDrawer;
import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField, Box, Button, Checkbox, FormControlLabel, Typography, Link } from '@mui/material';
import { InputAdornment, IconButton } from '@mui/material';
import TheBefiana from '../components/TheBefiana';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet-async';

function generateUsername(firstName, lastName) {
  let baseUsername = `${firstName}${lastName}`;
  baseUsername = baseUsername.replace(/\s+/g, '').toLowerCase().replace(/[^a-z0-9]/gi, '');
  const randomNumber = Math.floor(Math.random() * 1000);
  return `${baseUsername}${randomNumber}`.substring(0, 15);
}

function passwordValidation(password) {
  const hasNumber = /\d/.test(password);
  const hasLetter = /[a-zA-Z]/.test(password);
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  return password.length >= 8 && hasNumber && hasLetter && hasSymbol;
}

function Tongasoa() {
  const apiUrl = `${process.env.REACT_APP_API_URL}/api/signup/`;
  const [step, setStep] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [apiError, setApiError] = useState('');

  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      // birthDate: dayjs(), // Initialise avec dayjs
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
  });

  useEffect(() => {
    if (formData.firstName || formData.lastName) {
        const generatedUsername = generateUsername(formData.firstName, formData.lastName);
        setFormData((prev) => ({ ...prev, username: generatedUsername }));
    }
  }, [formData.firstName, formData.lastName]);

  const [passwordError, setPasswordError] = useState('');

  // Limite d'âge
  const minAge = 13;
  const maxAge = 99;

  const handleChangeBirthDate = (newValue) => {
    setSelectedDate(newValue);
    setFormData((prev) => ({ ...prev, birthDate: newValue }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setFormData((prev) => ({ ...prev, [name]: value }));
    
    if (name === 'password') {
        if (!passwordValidation(value)) {
            setPasswordError('Le mot de passe doit comporter au moins 8 caractères, dont un chiffre, une lettre et un symbole.');
        } else {
            setPasswordError('');
        }
    } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
    }

    // Logique pour la génération automatique du nom d'utilisateur basée sur le prénom et le nom
    if (name === 'firstName' || name === 'lastName') {
        const generatedUsername = generateUsername(formData.firstName, formData.lastName);
        setFormData((prev) => ({ ...prev, username: generatedUsername }));
    }
  };

  const handleCheckboxChange = (event) => {
      setFormData((prev) => ({ ...prev, acceptTerms: event.target.checked }));
  };

  // Vérifiez si la date de naissance est dans la plage d'âge valide
  const isBirthDateValid = () => {
    if (!selectedDate) return false;

    const today = dayjs();
    const age = today.diff(selectedDate, 'year');
    return age >= minAge && age <= maxAge;
  };

  const canProceedToNextStep = formData.firstName && isBirthDateValid();
  const canSubmit = formData.email && formData.username && formData.username.length >= 5 && passwordValidation(formData.password) && formData.password === formData.confirmPassword && formData.acceptTerms;

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true); 

    const dataToSend = {
      email: formData.email,
      username: formData.username,
      first_name: formData.firstName,
      last_name: formData.lastName,
      date_of_birth: dayjs(formData.birthDate).format('YYYY-MM-DD'), 
      password: formData.password
    };

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
    .then(response => {
      setIsLoading(false); // Arrête le chargement une fois la requête terminée
      // Vérifiez si la réponse est ok (status 200-299)
      if (!response.ok) {
        // Convertit et renvoie la réponse en JSON s'il y a une erreur
        return response.json().then(errorData => {
          // Construit un message d'erreur basé sur la réponse
          const errorMessage = errorData.email ? errorData.email[0] : 'Une erreur est survenue lors de la création du compte.';
          throw new Error(errorMessage);
        });
      }
      // Traite la réponse en JSON si tout va bien
      return response.json();
    })
    .then(data => {
      // Rediriger l'utilisateur ou afficher un message de succès ici
      setRegistrationComplete(true); // Indique que l'inscription est complète
      setApiError(''); // Réinitialise le message d'erreur si la création est réussie
    })
    .catch((error) => {
      console.error('Error:', error);
      // Met à jour l'état apiError avec le message d'erreur pour l'afficher à l'utilisateur
      setApiError(error.message);
      setIsLoading(false);
    });
  };



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container sx={{ bgcolor: '#f0f2f5', height: '100vh' }}>
      <Grid item xs={12} md={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, paddingRight: { sm: '11px' } }}>
        <TheBefiana />
      </Grid>
      <Grid item xs={12} md={5} sx={{ bgcolor: '#f0f2f5', paddingRight: { sm: '11px' }, marginTop: { xs: '10vh', md: '0px', lg: '0px', xl: '0px' }, marginLeft: { xs: '9px', md: '0px', lg: '0px', xl: '0px' }, marginRight: { xs: '9px', md: '0px', lg: '0px', xl: '0px' } }} display="flex" alignItems="center" justifyContent="center">
        <Paper
          elevation={8}
          sx={{
            width: '100%',
            maxWidth: 500,
            margin: 'auto',
            padding: 4,
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.05) 0px 6px 10px 0px, rgba(0, 0, 0, 0.05) 0px 1px 18px 0px'
          }}
        >
          <Grid item>
            <img src="/befiana.png" alt="Description" style={{ maxHeight: '71px', display: 'block', margin: '0 auto' }} />
          </Grid>
          <Typography variant="subtitle2" textAlign={'center'} component="h2" gutterBottom mt={1}>
            Créez votre compte BEFIANA Connect
          </Typography>
          <Helmet>
              <title>Inscription - BEFIANA Connect</title>
              <meta name="description" content="Inscrivez-vous à Befiana Connect pour accéder à nos solutions cloud centralisées. Créez un compte en quelques étapes simples et commencez à optimiser votre productivité avec nos services sécurisés et efficaces." />
          </Helmet>
          <Typography variant="subtitle2" textAlign={'center'} sx={{ fontFamily: 'befianaLora', fontStyle: 'italic' }} component="h2" gutterBottom>
            "Cap sur l'avenir : Osez l'infini !"
          </Typography>

          {registrationComplete ? (
            <Typography sx={{ mt: 4, mb: 2 }}>
              Votre inscription a été réussie. Un email de vérification a été envoyé à votre adresse email. Veuillez vérifier votre email pour activer votre compte.
            </Typography>
          ) : (

            <form onSubmit={handleSubmit}>
              {step === 1 ? (
                    <>
                        <TextField fullWidth label="Prénom" name="firstName" value={formData.firstName} onChange={handleChange} required sx={{ my: 2 }} />
                        <TextField fullWidth label="Nom" name="lastName" value={formData.lastName} onChange={handleChange} sx={{ my: 2 }} />
                        <DatePicker
                            label="Date de naissance"
                            value={selectedDate}
                            onChange={handleChangeBirthDate}
                            format="DD/MM/YYYY"
                            renderInput={(params) => <TextField {...params} fullWidth helperText="Nous avons besoin de cette information pour vérifier l'accès à nos services."/>}
                            minDate={dayjs().subtract(maxAge, 'year')}
                            maxDate={dayjs().subtract(minAge, 'year')}
                            sx={{mt:2}}
                        />
                        <Typography sx={{fontSize:"11px", m:1, color:"#757575"}} component="p" gutterBottom>
                          Nous avons besoin de cette information pour vérifier l'accès à nos services.
                        </Typography>
                        <Button 
                          fullWidth 
                          sx={{ my: 2 }} 
                          variant="contained" 
                          onClick={() => setStep(2)} 
                          endIcon={<ArrowForwardIosIcon />} 
                          disabled={!canProceedToNextStep}>
                            Suivant
                        </Button>
                    </>
                ) : (
                    <>
                        <TextField fullWidth label="Adresse e-mail" name="email" type="email" value={formData.email} onChange={handleChange} required sx={{ my: 2 }} />
                        <TextField fullWidth label="Nom d'utilisateur" name="username" value={formData.username} onChange={handleChange} required sx={{ my: 2 }} inputProps={{ maxLength: 31, minLength: 5 }} helperText="Nom d'utilisateur généré automatiquement.  Vous pouvez le modifier." />

                        <TextField 
                          fullWidth label="Mot de passe" 
                          name="password" 
                          type={showPassword ? 'text' : 'password'}
                          value={formData.password} 
                          onChange={handleChange} 
                          required 
                          sx={{ my: 2 }} 
                          error={!!passwordError} 
                          helperText={passwordError || "Minimum 8 caractères, avec des chiffres, des lettres et des symboles."}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toogle la visibilité du mot de passe"
                                      onClick={() => setShowPassword(!showPassword)}
                                      onMouseDown={(e) => e.preventDefault()}
                                      edge="end"
                                  >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                              </InputAdornment>
                            ),
                          }} 
                        />
                        <TextField 
                          fullWidth 
                          label="Confirmez le mot de passe" 
                          name="confirmPassword" 
                          type={showConfirmPassword ? 'text' : 'password'}
                          value={formData.confirmPassword} 
                          onChange={handleChange} 
                          required 
                          sx={{ my: 2 }} 
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toogle la visibilité du mot de passe"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                          }}
                        />

                        {apiError && (
                          <Typography color="error" sx={{ mt: 2 }}>
                            {apiError}
                          </Typography>
                        )}

                        <FormControlLabel control={<Checkbox checked={formData.acceptTerms} onChange={handleCheckboxChange} />} label={<Typography>En cochant cette case, je confirme mon accord avec les <Link href="https://www.befiana.com/conditions-generales-dutilisations-et-de-ventes/" underline="hover" target="_blank" rel="noopener noreferrer">Conditions Générales d’Utilisations et de Ventes</Link>, la <Link href="/policy" underline="hover" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</Link> et l'utilisation de <Link href="/cookies" underline="hover" target="_blank" rel="noopener noreferrer">Cookies</Link> conformément aux pratiques décrites, régissant les services proposés par Befiana Platform Corps.</Typography>} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button variant="text" onClick={() => setStep(1)}>Retour</Button>
                            <Button 
                              variant="contained" 
                              type="submit" 
                              disabled={!canSubmit}
                              
                              sx={{ mt: 2, mb: 2, p:2 }}
                              startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null} // Affiche un indicateur de chargement si isLoading est true
                            >
                              S'inscrire
                            </Button>
                        </Box>
                    </>
                )}

            </form>

          )}

          <Button
            component={RouterLink}
            to="/login"
            type="button"
            fullWidth
            variant="outlined"
            sx={{ mt: 2, mb: 2, p: 1 }}
          >
            Je possède déjà un compte
          </Button>

        </Paper>
      </Grid>
    </Grid>
    </LocalizationProvider>
  )
}

export default Tongasoa
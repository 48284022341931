import React, { useState, useEffect } from 'react';
import {
    AppBar, Toolbar, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    TextField, IconButton, Backdrop, CircularProgress, Paper
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import api from '../../../../services/api';


const StoresManager = () => {
    const [stores, setStores] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [storeDetails, setStoreDetails] = useState(null);
    const [storeForm, setStoreForm] = useState({ name: '', user_store: '', pass_store: '' });

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/ibondacha/stores/');
            setStores(response.data);
        } catch (error) {
            console.error('Error fetching stores:', error);
        }
        setLoading(false);
    };

    const handleOpenDialog = () => {
        setStoreForm({ name: '', user_store: '', pass_store: '' });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setStoreDetails(null);
    };

    const handleChange = (e) => {
        setStoreForm({ ...storeForm, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (storeDetails) {
                await api.put(`/api/ibondacha/stores/${storeDetails.id}/`, storeForm);
            } else {
                await api.post('/api/ibondacha/stores/', storeForm);
            }
            fetchStores();
            handleCloseDialog();
        } catch (error) {
            console.error('Error submitting store:', error);
        }
        setLoading(false);
    };

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            await api.delete(`/api/ibondacha/stores/${id}/`);
            fetchStores();
        } catch (error) {
            console.error('Error deleting store:', error);
        }
        setLoading(false);
    };

    const handleViewDetails = async (id) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/ibondacha/stores/${id}/`);
            setStoreDetails(response.data);
            setStoreForm({ name: response.data.name, user_store: response.data.user_store, pass_store: response.data.pass_store });
            setOpenDialog(true);
        } catch (error) {
            console.error('Error fetching store details:', error);
        }
        setLoading(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'name', headerName: 'Nom', width: 150 },
        { field: 'user_store', headerName: 'Utilisateur', width: 150 },
        { field: 'pass_store', headerName: 'Mot de Passe', width: 150 },
        {
            field: 'details',
            headerName: 'Détails',
            width: 100,
            renderCell: (params) => (
                <IconButton color="primary" onClick={() => handleViewDetails(params.id)}>
                    <InfoIcon />
                </IconButton>
            ),
        },
        {
            field: 'delete',
            headerName: 'Supprimer',
            width: 100,
            renderCell: (params) => (
                <IconButton color="secondary" onClick={() => handleDelete(params.id)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 16, width: '850px', margin: '0 auto' }}>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar position="static" color='ib1' style={{ marginBottom: 16, opacity: 0.8 }}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Gestion des Magasins
                    </Typography>
                    <Button color="ib2" variant='contained' startIcon={<AddIcon />} onClick={handleOpenDialog}>
                        Ajouter un Magasin
                    </Button>
                </Toolbar>
            </AppBar>
            <Paper elevation={1} sx={{p:3}}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid rows={stores} columns={columns} localeText={frFR.components.MuiDataGrid.defaultProps.localeText}/>
                </div>
            </Paper>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                <Button color="ib2" variant='text' startIcon={<AddIcon />} onClick={handleOpenDialog} sx={{ fontSize: '21px'}}>
                    {storeDetails ? 'Modifier le Magasin' : 'Ajouter un Magasin'}
                </Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {storeDetails ? 'Modifier les détails du magasin.' : 'Entrez les détails du nouveau magasin.'}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Nom du Magasin"
                        fullWidth
                        value={storeForm.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="user_store"
                        label="Utilisateur du Magasin"
                        fullWidth
                        value={storeForm.user_store}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="pass_store"
                        label="Mot de Passe du Magasin"
                        fullWidth
                        value={storeForm.pass_store}
                        onChange={handleChange}
                        type="password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="ib1" disableElevation>
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} color="ib1" variant='contained' disableElevation>
                        {storeDetails ? 'Mettre à jour' : 'Ajouter'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StoresManager;
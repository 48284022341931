import React from 'react';
import { Box, Toolbar, Typography, Paper } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import ContactForm from './components/ContactAddForm';
import { Helmet } from 'react-helmet-async';

function SMSaddContact() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Ajouter un contact - BEFIANA Connect</title>
                <meta name="description" content="Créez et gérez des campagnes SMS en masse avec BEFIANA Connect. Solution simple et efficace pour atteindre vos clients par SMS." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h4" color="initial">Ajouter un contact</Typography>
                <Typography variant="subtitle2" color="initial">Ajoutez un numéro de téléphone à votre base de données de répertoire.</Typography>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899 }}>
                    <ContactForm />

                </Paper>


                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSaddContact;
import React from 'react';
import { Box, Toolbar} from '@mui/material';
import IBAppBarComponent from './components/AppBar';
import VoucherTable from './components/VoucherTable';
import { Helmet } from 'react-helmet-async';

function IBVoucherAll() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>iBondacha Administration - iBondacha</title>
            </Helmet>
            <IBAppBarComponent />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <VoucherTable />


            </Box>

        </Box>
    );
}

export default IBVoucherAll;
import React, { useState, useEffect } from 'react'
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import navDrawer from './navDrawerItems'
import WidgetsIcon from '@mui/icons-material/Widgets';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
 
function DrawerBarandTabsXS() {
    const location = useLocation();
    const NavHomeBC = { "color": "#006837", "name": "Home", "icon": <HomeIcon />, "to": "/" }
    const NavAccount = { "color": "#006837", "name": "Mon Compte", "icon": <AccountCircle />, "to": "/account" };

    // Drawer for Mobile Version
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [value, setValue] = useState(0);
    // Mise à jour de l'onglet actif en fonction du chemin d'accès actuel
    useEffect(() => {
        if (location.pathname === "/") {
            setValue(0);
        } else if (location.pathname === "/account") {
            setValue(1);
        } else {
            setValue(false); // Aucun onglet actif ou désactiver l'indicateur d'onglet actif
        }
    }, [location]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const drawerContent = (
        <List>
            {navDrawer.map((item, index) => (
                <ListItem 
                    button 
                    key={item.name} 
                    component={Link} 
                    to={item.to} 
                    selected={location.pathname === item.to} 
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Exemple de style pour l'élément sélectionné
                            '& .MuiListItemIcon-root': {
                                color: 'primary.main', // Change la couleur de l'icône pour l'élément sélectionné
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)', // Garde le même fond au survol
                            }
                        },
                        '& .MuiListItemIcon-root': { color: item.color } // Couleur normale des icônes
                    }}
                >
                    <ListItemIcon sx={{ color: item.color }}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
            ))}
        </List>
    );

    return (
        <Box
            sx={{
                display: { xs: 'block', sm: 'none' },
                justifyContent: 'space-around',
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                borderTop: 1,
                borderColor: 'divider',
                zIndex: 99
            }}
        >

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon tabs example"
                variant="fullWidth"
                sx={{
                    backgroundColor: "#fff",
                    zIndex: 999
                }}
            >
                <Tab icon={NavHomeBC.icon} label={NavHomeBC.name} component={Link} to="/" />
                <Tab icon={NavAccount.icon} label={NavAccount.name} component={Link} to={NavAccount.to} />
                <Tab icon={<WidgetsIcon />} label="PLUS" onClick={toggleDrawer(true)} />
            </Tabs>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' }, // Affiche le Drawer uniquement sur les écrans xs
                    '& .MuiDrawer-paper': { paddingTop: '65px' }, // Ajoute un padding-top de 65px
                }}
            >
                {drawerContent}

                <Box sx={{position: 'absolute', bottom: 0, left: 0, margin: '0 auto', width: '100%', paddingTop:'15px', paddingBottom: '5px'}}>
                    <Typography variant="subtitle2" color="#787878" align='center'>Befiana Platform Corps © 2024 </Typography>
                </Box>
            </Drawer>

        </Box>
    )
}

export default DrawerBarandTabsXS
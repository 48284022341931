import React from 'react';
import { Box, Toolbar, Typography, Paper} from '@mui/material';
import Divider from '@mui/material/Divider';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import CampaignList from './components/CampaignList';
import CreateCampaignForm from './components/CreateCampaignForm';

function SMSCampaign() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>BEFIANA Connect - SMS Solution</Typography>
                <Divider>Campagne SMS/Masse</Divider>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 2}}>
                    <CreateCampaignForm />
                </Paper>
                <CampaignList />


                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSCampaign;
import React, { useEffect, useState } from 'react';
import { Typography, Paper, Card, CardContent, Grid, Avatar, Divider, List, ListItem, Badge, ListItemButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import api from '../../services/api';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Helmet } from 'react-helmet-async';

import dayjs from 'dayjs';
import 'dayjs/locale/fr'; 
import colorCode from '../../services/randomColor';
dayjs.locale('fr'); 

function AboutUser() {
  const profilPicture = false
  const [userProfile, setUserProfile] = useState({
    username: ' ',
    firstName: ' ',
    lastName: ' ',
    date_of_birth: ' ',
    gender: ' ',
    email: ' ',
    email_verified: false,
    is_active: false,
    id: ' ',
  });

  const formattedDateOfBirth = dayjs(userProfile.date_of_birth).format('DD MMMM YYYY');

  useEffect(() => {
    api.get('api/user-info/')
      .then(response => {
        setUserProfile({
          username: response.data.username,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          date_of_birth: response.data.date_of_birth,
          gender: response.data.gender,
          email: response.data.email,
          email_verified: response.data.email_verified,
          is_active: response.data.is_active,
          id: response.data.id,
        });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des informations utilisateur:', error);
      });
  }, []);

  function getInitials(fullname) {
    const words = fullname.split(' ');
    let initials = words[0][0]; 
    if (words.length > 1) {
      initials += words[1][0]; 
    }
    return initials;
  }
  const initials = getInitials(userProfile.firstName + " " + userProfile.lastName);

  function getGenderDisplay(gender) {
    if (gender === "F") {
      return "Femme";
    } else if (gender === "H") {
      return "Homme";
    } else if (gender === "N") {
      return "Non précisé";
    } 
  }
  const genderDisplay = getGenderDisplay(userProfile.gender);

  return (
    <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 5, p: 2, maxWidth: 899, borderRadius: '17px' }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="div" color={'primary'}>
                Informations de base
              </Typography>
            </Grid>
          </Grid>

          <List component="nav" aria-label="user information">
            <Divider />
            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink} 
                to="/myAccount/name" 
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Prénom et Nom</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">{userProfile.firstName} {userProfile.lastName}</Typography>
                        <Helmet>
                          <title>{`${userProfile.firstName} ${userProfile.lastName} - BEFIANA Connect`}</title>
                        </Helmet>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink} 
                to="/myAccount/username" 
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Nom Utilisateur</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">@{userProfile.username}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink} 
                to="/myAccount/birthday" 
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Date de naissance</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">{formattedDateOfBirth}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink} 
                to="/myAccount/gender" 
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Genre</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">{genderDisplay}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

            <ListItem 
              sx={{ marginBottom: '8px', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)', }, cursor: 'pointer', textDecoration: 'none', color:'#000' }}
              component={RouterLink} 
              to="/myAccount/profil-picture"
            >
              <Grid container alignItems="center">
                <Grid item xs={12} md={4}>
                  <Typography variant="body2">Photo de profil</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container>

                    <Grid item xs={6} md={4}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <CameraAltIcon
                            sx={{
                              backgroundColor: 'white',
                              color: '#006837',
                              borderRadius: '50%',
                              padding: '0.25em',
                              fontSize: '3rem',
                              border: '2px solid white',
                              zIndex: 0
                            }}
                          />
                        }
                      >
                        {profilPicture ? (
                          <Avatar alt="Nom et Prénom" src="/static/images/avatar/1.jpg" sx={{ width: 75, height: 75, margin: '0 auto' }} />
                        ) : (
                          <Avatar sx={{ bgcolor: colorCode, width: 75, height: 75, fontSize: 25, margin: '0 auto' }} alt="Nom et Prénom" >{initials}</Avatar>
                        )}
                      </Badge>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography variant="caption" color="initial">Une photo aide les gens à vous reconnaître.</Typography>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </List>
        </CardContent>
      </Card>

    </Paper>
  )
}

export default AboutUser
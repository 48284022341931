import React, { useEffect, useState } from 'react';
import { Box, Toolbar, Typography, Paper, Grid, Divider } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import WidgetSMS from './components/WidgetSMS';
import api from '../../../services/api';
import ContactsDownloadTemplate from './components/FileExemple';
import PendingSms from './components/PendingSms';
import GuideCard from './components/GuideCard';
import { Helmet } from 'react-helmet-async';
import SMSActivationStatusHome from './components/SMSActivationStatusHome';

function SMSStatistic() {
    const [smsSend, setSmsSend] = useState(0);
    const [smsSendSeg, setSmsSendSeg] = useState(0);
    const [smsContacts, setContacts] = useState(0);
    const [smsList, setList] = useState(0);
    const [smsBalance, setBalance] = useState([]);
    const [pendingSmsCount, setPendingSmsCount] = useState(0);

    useEffect(() => {
        fetchTotalSMSSend();
        fetchTotalSMSSendSeg();
        fetchTotalContacts();
        fetchTotalList();
        fetchBalance();
        fetchPendingSmsCount();
    
        const intervalId = setInterval(() => {
            fetchTotalSMSSend();
            fetchTotalSMSSendSeg();
            fetchTotalContacts();
            fetchTotalList();
            fetchBalance();
            fetchPendingSmsCount();
        }, 3000); 
    
        return () => {
            clearInterval(intervalId); 
        };
    }, []);

    const fetchTotalSMSSend = async () => {
        try {
            const response = await api.get('/api/smsko/sms/sent/total/');
            setSmsSend(response.data.total);
        } catch (error) {
            // console.error('Failed to fetch', error);
        }
    };

    const fetchTotalSMSSendSeg = async () => {
        try {
            const response = await api.get('/api/smsko/sms/sent/segments/total/');
            setSmsSendSeg(response.data.total);
        } catch (error) {
            // console.error('Failed to fetch', error);
        }
    };

    const fetchTotalContacts = async () => {
        try {
            const response = await api.get('/api/smsko/sms/contacts/total/');
            setContacts(response.data.total);
        } catch (error) {
            // console.error('Failed to fetch', error);
        }
    };

    const fetchTotalList = async () => {
        try {
            const response = await api.get('/api/smsko/sms/list/total/');
            setList(response.data.total);
        } catch (error) {
            // console.error('Failed to fetch', error);
        }
    };

    const fetchBalance = async () => {
        try {
            const response = await api.get('/api/smsko/sms/balance/total/');
            setBalance(response.data);
        } catch (error) {
            // console.error('Failed to fetch', error);
        }
    };

    const fetchPendingSmsCount = async () => {
        try {
            const response = await api.get('/api/smsko/croncam-befiana-sms-count/');
            setPendingSmsCount(response.data.count);
        } catch (error) {
            // console.error('Failed to fetch pending SMS count', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>SMS by BEFIANA - BEFIANA Connect</title>
                <meta name="description" content="Créez et gérez des campagnes SMS en masse avec BEFIANA Connect. Solution simple et efficace pour atteindre vos clients par SMS." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>SMS by BEFIANA</Typography>
                <Divider>Dashboard</Divider>
                <br />

                <SMSActivationStatusHome />

                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={3}>
                        <WidgetSMS name={`SMS / ${smsSend} ${smsSend === 1 ? 'envoi' : 'envois'}`} nb={smsSendSeg} color="#17a2b8" colorT="#ffffff" icon="SendAndArchive" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <WidgetSMS name={smsContacts >= 2 ? "Contacts" : "Contact"} nb={smsContacts} color="#ff8c00" colorT="#ffffff" icon="ContactPhone" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <WidgetSMS name={smsList >= 2 ? "Groupes de contacts" : "Groupe de Contacts"} nb={smsList} color="#006837" colorT="#ffffff" icon="PlaylistAddCheckCircle" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <WidgetSMS name={smsBalance.validity} nb={smsBalance.balance} color="#dc3545" colorT="#ffffff" icon="ShoppingCart" />
                    </Grid>
                </Grid>

                {pendingSmsCount > 0 && <PendingSms count={pendingSmsCount} />}


                <GuideCard />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 3, mb: 1, p: 2, maxWidth: 899, borderRadius: 7 }}>
                    <ContactsDownloadTemplate />
                </Paper>

                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSStatistic;
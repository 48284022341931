import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box, Grid } from '@mui/material';
import api from '../../../../services/api';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ActiveVouchersSummary = () => {
    const [data, setData] = useState({ total_active_amount: 0, active_voucher_count: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/active-summary/')  // Ajoute un délai d'attente de 10 secondes
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <Box display="flex" alignItems="center">
                            <AttachMoneyIcon color="primary" fontSize="large" />
                            <Box ml={2}>
                                <Typography variant="h6">Total</Typography>
                                <Typography variant="h4" color="primary">
                                    {Number(data.total_active_amount).toLocaleString('fr-FR', { style: 'currency', currency: 'MGA', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display="flex" alignItems="center">
                            <ReceiptIcon color="secondary" fontSize="large" />
                            <Box ml={2}>
                                <Typography variant="h6">iBondacha</Typography>
                                <Typography variant="h4" color="secondary">{data.active_voucher_count}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ActiveVouchersSummary;
import React, { useState, useEffect } from 'react';
import {
    Box, Paper, Typography, IconButton, Tooltip, Snackbar, Alert
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    ToggleOn as ToggleOnIcon,
    ToggleOff as ToggleOffIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import api from '../../../../services/api';
import EditPeriodicReminderDialog from './EditPeriodicReminderDialog';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import 'dayjs/locale/fr'; 
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

function PeriodicRemindersList() {
    const [reminders, setReminders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);

    useEffect(() => {
        fetchReminders();
    }, []);

    const fetchReminders = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/reminders/periodic/');
            setReminders(response.data);
        } catch (error) {
            console.error('Error fetching reminders', error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = async (id) => {
        try {
            await api.post(`/api/smsko/reminders/periodic/${id}/toggle/`);
            fetchReminders();
        } catch (error) {
            console.error('Error toggling reminder', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/api/smsko/reminders/periodic/${id}/delete/`);
            setSnackbar({ open: true, message: 'Rappel supprimé avec succès.', severity: 'success' });
            fetchReminders();
        } catch (error) {
            console.error('Error deleting reminder', error);
            setSnackbar({ open: true, message: 'Erreur lors de la suppression du rappel.', severity: 'error' });
        }
    };

    const handleEdit = (reminder) => {
        setSelectedReminder(reminder);
        setOpenEditDialog(true);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'message', headerName: 'Message', flex: 1 },
        {
            field: 'is_active',
            headerName: 'Statut',
            width: 100,
            renderCell: (params) => (
                params.value ? 'Actif' : 'Inactif'
            )
        },
        {
            field: 'next_send_date',
            headerName: 'Prochain Envoi',
            width: 180,
            renderCell: (params) => (
                dayjs(params.value).format('DD/MM/YYYY HH:mm')
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Activer/Désactiver">
                        <IconButton onClick={() => handleToggle(params.row.id)}>
                            {params.row.is_active ? <ToggleOffIcon color="warning" /> : <ToggleOnIcon color="primary" />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Modifier">
                        <IconButton onClick={() => handleEdit(params.row)}>
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                        <IconButton onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }
    ];

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">

            <Typography variant="h5" gutterBottom>
                Mes Rappels Périodiques
                <IconButton onClick={fetchReminders} sx={{ ml: 2 }} color="primary">
                    <RefreshIcon />
                </IconButton>
            </Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={reminders}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    loading={loading}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            {openEditDialog && (
                <EditPeriodicReminderDialog
                    reminder={selectedReminder}
                    onClose={() => setOpenEditDialog(false)}
                    onSuccess={() => {
                        setOpenEditDialog(false);
                        fetchReminders();
                    }}
                />
            )}
        </LocalizationProvider>
        
        </Paper>
    );
}

export default PeriodicRemindersList;

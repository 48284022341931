import React, { useState } from 'react';
import { Grid, Box, Paper, TextField, Button, Typography } from '@mui/material';
import TheBefiana from '../components/TheBefiana';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function PasswordReset() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [btnCancel, setBtnCancel] = useState('Annuler');
    const [message, setMessage] = useState('');
    const [open, setOpen] = React.useState(false);
    const [errorCode, setErrorCode] = useState('success');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setOpen(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset-request/`, { email });
            setMessage(response.data.detail);
            setSubmitted(true);
            setBtnCancel('Connexion')
            setOpen(false);
        } catch (error) {
            setErrorCode('error')
            setMessage('Erreur lors de l\'envoi de la demande de réinitialisation.');
            setOpen(false);
        }
    };

    return (
        <Grid container sx={{ bgcolor: '#f0f2f5', height: '100vh' }}>
            <Grid item xs={12} md={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, paddingRight: { sm: '11px' } }}>
                <TheBefiana />
            </Grid>
            <Grid item xs={12} md={5} sx={{ bgcolor: '#f0f2f5', paddingRight: { sm: '11px' }, marginTop: { xs: '10vh', md: '0px', lg: '0px', xl: '0px' }, marginLeft: { xs: '9px', md: '0px', lg: '0px', xl: '0px' }, marginRight: { xs: '9px', md: '0px', lg: '0px', xl: '0px' } }} display="flex" alignItems="center" justifyContent="center">
                <Paper
                    elevation={8}
                    sx={{
                        width: '100%',
                        maxWidth: 500,
                        margin: 'auto',
                        padding: 4,
                        backgroundColor: 'white',
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.05) 0px 6px 10px 0px, rgba(0, 0, 0, 0.05) 0px 1px 18px 0px'
                    }}
                >
                    <Grid item>
                        <img src="/befiana.png" alt="Description" style={{ maxHeight: '71px', display: 'block', margin: '0 auto' }} />
                    </Grid>
                    <Typography variant="subtitle1" textAlign={'center'} component="h2" mt={2} gutterBottom>
                        Réinitialiser le mot de passe
                    </Typography>
                    <Helmet>
                        <title>Réinitialiser le mot de passe - BEFIANA Connect</title>
                        <meta name="description" content="Réinitialisez votre mot de passe pour accéder à Befiana Connect. Suivez les étapes simples pour rétablir l'accès à votre compte et continuer à utiliser nos services cloud centralisés de manière sécurisée." />
                    </Helmet>


                    {!submitted ? (
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Entrez votre adresse email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2, p: 2 }}
                            >
                                Réinitialiser
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <Alert severity={errorCode} sx={{ mt: 2 }}>{message} </Alert>
                        </Box>
                    )}

                    <Backdrop
                        sx={{ color: '#006837', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Button
                        component={RouterLink}
                        to="/login"
                        type="button"
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2, p: 1 }}
                    >
                        {btnCancel}
                    </Button>

                </Paper>
            </Grid>
        </Grid>
    )
}

export default PasswordReset
import React, { useState } from 'react';
import { Box, Button, TextField, Paper, Typography, Container, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import useBoxAuth from '../../../hooks/useBoxAuth';
import { Helmet } from 'react-helmet-async';

const BackgroundBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/tsena.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const CashierLogin = () => {
    useBoxAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Utilisation de useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/ibondacha/store-login/`, {
        user_store: username,
        pass_store: password,
      });
      const { store_reference, store_name, store_user } = response.data;
      localStorage.setItem('store_reference', store_reference);
      localStorage.setItem('store_name', store_name);
      localStorage.setItem('store_user', store_user);
      navigate('/App/iBondacha/cash');
    } catch (error) {
      setError('Les informations d\'identification invalides. Veuillez réessayer.');
    }
  };

  return (
    <BackgroundBox>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <StyledPaper>
          <img src="/ibondacha.png" width="200px" alt="Logo iBondacha" style={{ display: 'block', margin: '0 auto', paddingBottom: '25px', paddingTop: '25px'}} />
          <Typography component="h1" variant="h5">
            Connexion - Stores
          </Typography>
          <Helmet>
                <title>Connexion Caisse - iBondacha</title>
                <meta name="robots" content="noindex" />
            </Helmet>
          <StyledForm noValidate onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nom d'utilisateur"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" variant="contained" color="ib2" disableElevation fullWidth sx={{ mb:6 }}>
              Connexion
            </Button>
          </StyledForm>
        </StyledPaper>
      </Container>
    </BackgroundBox>
  );
};

export default CashierLogin;
import React from 'react';
import { Box, Toolbar, Typography, Divider, Card, CardContent, Paper } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';
import { Helmet } from 'react-helmet-async';

import AboutUpdateGender from '../../components/accounts/AboutGender';


function MyAccountGender() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Genre - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Divider>Befiana Connect</Divider>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                    Informations sur vous et vos préférences dans les services Befiana
                </Typography>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899, borderRadius: '17px' }}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <AboutUpdateGender />
                        </CardContent>
                    </Card>

                </Paper>

                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default MyAccountGender;
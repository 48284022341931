import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button, TextField, Paper, Grid, Stack, Backdrop, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { styled, keyframes } from '@mui/system';
import api from '../../../../services/api';

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const CustomPaper = styled(Paper)(({ error, success }) => ({
  height: 159,
  width: '100%',
  padding: '9px',
  borderRadius: '9px',
  elevation: 9,
  backgroundColor: error === 'true' ? '#ffe6e6' : (success === 'true' ? '#e6ffe6' : '#b8c0d7'),
  border: error === 'true' ? '3px solid red' : (success === 'true' ? '3px solid green' : '3px solid #b8c0d7'),
  animation: error === 'true' ? `${shake} 0.6s` : 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  transition: 'border 0.7s ease-in-out, background-color 0.9s ease-in-out'
}));

const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: '25px',
  fontWeight: 'bold',
  backgroundColor: '#4caf50',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#45a049',
  },
  '&.Mui-disabled': {
    backgroundColor: '#d6d6d6',
    color: '#9e9e9e'
  }
}));

const CashRegisterMobile = () => {
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherDetails, setVoucherDetails] = useState(null);
  const [msgVoucher, setMsgVoucher] = useState('');
  const [amountToConsume, setAmountToConsume] = useState('');
  const [confirmationAmount, setConfirmationAmount] = useState('');
  const [showConfirmationField, setShowConfirmationField] = useState(false);
  const [showAmountField, setShowAmountField] = useState(true);
  const [buttonColor, setButtonColor] = useState('#cead49'); // Initialement jaune
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [boxMessage, setBoxMessage] = useState(false);
  const [error, setError] = useState(false);
  const [buyOk, setBuyOk] = useState('#fff');
  const [loading, setLoading] = useState(false);
  const confirmationInputRef = useRef(null);
  const voucherCodeInputRef = useRef(null);
  const amountInputRef = useRef(null);

  useEffect(() => {
    voucherCodeInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (voucherDetails) {
      amountInputRef.current.focus();
    }
  }, [voucherDetails]);

  const handleVoucherCodeChange = (e) => {
    const code = e.target.value.replace(/\D/g, '');
    setVoucherCode(code);
    setBuyOk('#fff');
    setBoxMessage(false);

    if (code.length === 8) {
      checkVoucherCode(code);
    } else {
      setVoucherDetails(null);
      setError(false);
    }
  };

  const checkVoucherCode = async (code) => {
    setLoading(true);
    try {
      const response = await api.post('/api/ibondacha/box/check-voucher/', { voucher_code: code });
      setVoucherDetails(response.data);
      setError(false);
    } catch (err) {
      const response = await api.post('/api/ibondacha/box/check-voucher/v2/', { voucher_code: code })
      switch (response.data.status) {
        case 2:
            setMsgVoucher('Code Bloqué');
            break;
        case 3:
            setMsgVoucher('Erreur de caisse en cours');
            break;
        case 0:
            setMsgVoucher('Déjà utilisé');
            break;
        case 9:
            setMsgVoucher('Code Invalide');
            break;
        default:
            setMsgVoucher('Code Invalide');
            break;
      } 
      setError(true);
      setVoucherDetails(null);
    }
    setLoading(false);
  };

  const handleAmountChange = (e) => {
    setAmountToConsume(e.target.value);
    setShowConfirmationField(false);
    setConfirmationAmount('');
    setBuyOk('#fff');
    setButtonColor('#cead49'); // Réinitialiser la couleur à jaune
  };

  const handleConfirmationAmountChange = (e) => {
    setConfirmationAmount(e.target.value);
    if (e.target.value === amountToConsume) {
      setButtonColor('#4caf50'); // Vert
    } else {
      setButtonColor('#cead49'); // Jaune
    }
  };

  const handleShowConfirmationField = () => {
    setShowConfirmationField(true);
    setShowAmountField(false);
    setTimeout(() => {
      confirmationInputRef.current.focus();
    }, 100);
  };

  const sendSMS = async (phoneNumber) => {
    try {
      await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
    } catch (err) {
      setSnackbarMessage('Erreur lors de l\'envoi du SMS');
      //
    }
  };

  const handlePurchase = async () => {
    if (voucherDetails && amountToConsume > 0 && amountToConsume <= voucherDetails.amount) {
      setLoading(true);
      try {
        const storeReference = localStorage.getItem('store_reference');
        await api.post('/api/ibondacha/box/make-purchase/', {
          voucher_code: voucherDetails.voucher_code,
          amount: amountToConsume,
          store_reference: storeReference
        });
        setSnackbarMessage('Achat validé avec succès');
        setBuyOk('#e6ffe6');
        sendSMS(voucherDetails.phone_number);
        setVoucherDetails(null);
        setVoucherCode('');
        setAmountToConsume('');   
        setConfirmationAmount('');
        setShowConfirmationField(false);
        setShowAmountField(true);
        setBoxMessage(true);
        voucherCodeInputRef.current.focus();
      } catch (err) {
        setSnackbarMessage('Erreur lors de l\'achat');
        setBuyOk('#ffe6e6');
      }
      setLoading(false);
    } else {
      setSnackbarMessage('Montant invalide ou insuffisant');
    }
  };

  const confirmPurchase = () => {
    if (confirmationAmount === amountToConsume) {
      handlePurchase();
    } else {
      setSnackbarMessage('Les montants ne correspondent pas');
    }
  };

  const commonPaperStyles = {
    height: 259,
    width: '100%',
    display: 'block',
    p: 1,
    borderRadius: 4,
    elevation: 9,
  };

  return (
    <Box sx={{ mx: 'auto', maxWidth: 375, pl: 2, pr: 2 }}>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <CustomPaper error={error.toString()} success={voucherDetails ? 'true' : 'false'}>
            <Box sx={{ mb: 'auto', mt: 'auto', width: '100%' }}>
              <TextField
                id="filled-basic"
                label="Code à 8 chiffres"
                variant="standard"
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '9px 9px 0 0',
                  p: 2,
                  textAlign: 'center'
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '23px',
                    p: 2
                  },
                }}
                inputProps={{
                  maxLength: 8,
                  pattern: "[0-9]*",
                  style: { fontSize: '30px', p: 2, textAlign: 'center' }
                }}
                value={voucherCode}
                onChange={handleVoucherCodeChange}
                inputRef={voucherCodeInputRef}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt="auto">
              {voucherDetails ? (
                <>
                  <CheckCircleIcon color="primary" />
                  <Typography variant="body2" sx={{ fontSize: '18px' }} color="primary">
                    Code vérifié
                  </Typography>
                </>
              ) : (
                error && (
                  <>
                    <ErrorOutline color="error" />
                    <Typography variant="body2" sx={{ fontSize: '18px' }} color="error">
                      {msgVoucher}
                    </Typography>
                  </>
                )
              )}
            </Box>
          </CustomPaper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              ...commonPaperStyles,
              backgroundColor: buyOk,
              width: '100%',
              transition: 'border 0.7s ease-in-out, background-color 0.9s ease-in-out',
              display: 'block'
            }}
          >
            <Stack spacing={1}>
              <Box sx={{ backgroundColor: '#b8c0d7', height: 100, borderRadius: 2, textAlign: 'center' }}>
                <Button variant="text" size="large" sx={{ fontSize: '27px', textTransform: 'initial', margin: '0px', padding: '0px' }}>
                  {voucherDetails ? `${(voucherDetails.amount).toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} Ar` : '0 Ar'}
                </Button>
                <Typography variant="body2" sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                  {voucherDetails ? `0${voucherDetails.phone_number.slice(0, 2)} ${voucherDetails.phone_number.slice(2, 4)} ${voucherDetails.phone_number.slice(4, 6)} ${voucherDetails.phone_number.slice(6)}` : ' '}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff', fontSize: '16px' }}>
                  {voucherDetails ? voucherDetails.name_or_function : ' '} {boxMessage ? snackbarMessage : ' '}
                </Typography>
              </Box>
              {showAmountField && (
                <Box>
                  <TextField
                    id="filled-basic"
                    label="Montant à consommer"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: {
                        fontSize: '25px',
                        backgroundColor: '#fff',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: '20px',
                      },
                    }}
                    value={amountToConsume}
                    onChange={handleAmountChange}
                    disabled={!voucherDetails}
                    inputRef={amountInputRef}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' && voucherDetails && amountToConsume > 0 && amountToConsume <= voucherDetails.amount) {
                        handleShowConfirmationField();
                      }
                    }}
                  />
                </Box>
              )}
              {showConfirmationField && (
                <Box>
                  <TextField
                    id="confirmation-amount"
                    label="Confirmer Montant à consommer"
                    variant="filled"
                    fullWidth
                    InputProps={{
                      sx: {
                        fontSize: '25px',
                        backgroundColor: '#fff',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: '20px',
                      },
                    }}
                    value={confirmationAmount}
                    onChange={handleConfirmationAmountChange}
                    inputRef={confirmationInputRef}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        confirmPurchase();
                      }
                    }}
                  />
                </Box>
              )}
              <Box>
                <CustomButton 
                  variant="contained"
                  fullWidth
                  disableElevation
                  onClick={showConfirmationField ? confirmPurchase : handleShowConfirmationField}
                  disabled={!voucherDetails || amountToConsume <= 0 || amountToConsume > voucherDetails.amount}
                  sx={{
                    backgroundColor: showConfirmationField ? buttonColor : '#cead49', // Jaune par défaut
                    '&:hover': {
                      backgroundColor: showConfirmationField ? (amountToConsume === confirmationAmount ? '#45a049' : '#ffb300') : '#ffb300', // Vert plus foncé ou jaune plus foncé
                    },
                    textTransform: 'initial',
                    // marginTop: '10px'
                  }}
                >
                  {showConfirmationField ? `VALIDER ACHAT : ${(amountToConsume).toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} Ar` : 'VALIDER'}
                </CustomButton>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CashRegisterMobile;

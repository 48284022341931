import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import BusinessIcon from '@mui/icons-material/Business';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CalculateIcon from '@mui/icons-material/Calculate';


const NavHomeBC = { "color": "#69b0ee", "name": " ", "icon": <HomeIcon />, "to": "/App/iBondacha/v2" };

// const NavIBBox = { "color": "#69b0ee", "name": "iBondacha", "icon": <AddShoppingCartIcon />, "to": "/App/iBondacha/voucher" };
const NavIBBoxAdmin = { "color": "#69b0ee", "name": " ", "icon": <PointOfSaleIcon />, "to": "/App/iBondacha/box-v" };
const NavIBCompta = { "color": "#69b0ee", "name": " ", "icon": <CalculateIcon />, "to": "/App/iBondacha/compta" };
const NavIBStores = { "color": "#69b0ee", "name": " ", "icon": <StoreIcon />, "to": "/App/iBondacha/stores" };
const NavIBBusiness = { "color": "#69b0ee", "name": " ", "icon": <BusinessIcon />, "to": "/App/iBondacha/business" };
const NavIBVoucherAdmin = { "color": "#69b0ee", "name": " ", "icon": <AddShoppingCartIcon />, "to": "/App/iBondacha/voucher" };
const NavIBVoucher = { "color": "#69b0ee", "name": " ", "icon": <LocalActivityIcon />, "to": "/App/iBondacha/voucher-all" };

const NavLogOut = { "color": "#000", "name": " ", "icon": <LogoutIcon />, "to": "/" };

const iBondachanavDrawer = [NavHomeBC, NavIBVoucher, NavIBVoucherAdmin, NavIBStores, NavIBBusiness, NavIBBoxAdmin, NavIBCompta, NavLogOut];

export default iBondachanavDrawer;
import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Snackbar, IconButton, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

function ProfilPicture() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [redirectCountdown, setRedirectCountdown] = useState(3);
    const navigate = useNavigate();

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        let timer;
        if (redirectCountdown > 0 && snackbarOpen && loading === false) {
            timer = setTimeout(() => setRedirectCountdown(redirectCountdown - 1), 1000);
        } else if (redirectCountdown === 0) {
            navigate('/account');
        }
        return () => clearTimeout(timer);
    }, [redirectCountdown, snackbarOpen, loading, navigate]);

    const resizeImage = (file) => new Promise((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const maxDimension = 750;
            let newDimension = Math.min(image.width, image.height, maxDimension);
            const canvas = document.createElement('canvas');
            canvas.width = newDimension;
            canvas.height = newDimension;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, (image.width - newDimension) / 2, (image.height - newDimension) / 2, newDimension, newDimension, 0, 0, newDimension, newDimension);
            canvas.toBlob(resolve, 'image/jpeg', 0.92);
        };
        image.onerror = reject;
    });

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const resizedImage = await resizeImage(data.image[0]);
            const formData = new FormData();
            formData.append('file', resizedImage, 'profile-pic.jpg');

            await api.post('/api/profile-image/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setSnackbarMessage('Photo de profil mise à jour avec succès. Redirection dans ' + redirectCountdown + 's...');
            setSnackbarOpen(true);
            setLoading(false);
        } catch (error) {
            setSnackbarMessage('Erreur lors de la mise à jour de la photo de profil.');
            setSnackbarOpen(true);
            setLoading(false);
            console.error('Upload error:', error);
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
                Télécharger une photo de profil
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    accept="image/*"
                    type="file"
                    {...register('image', { required: true })}
                    style={{ marginBottom: 10 }}
                />
                {errors.image && <Typography color="error">Une image est requise.</Typography>}

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
                >
                    {loading ? 'Chargement...' : 'Télécharger'}
                </Button>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Box>
    );
}

export default ProfilPicture;
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Toolbar, Typography, Divider, Button, Card, CardContent, Paper } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';
import { Helmet } from 'react-helmet-async';

import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';

function MyAccountEmailVerify() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    verifyEmailToken(token);
  }, [token]);

  const verifyEmailToken = async (token) => {
    try {
      const response = await api.get(`/api/verify-new-email/${token}`);
      setMessage(response.data.detail);
    } catch (error) {
      setMessage('Erreur lors de la vérification de l\'email. Veuillez essayer de nouveau.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Helmet>
          <title>Vérification Courrier électronique - BEFIANA Connect</title>
      </Helmet>
      <AppBarComponent />
      <DrawerBarMD />

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />

        <Divider>Befiana Connect</Divider>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
          Informations sur vous et vos préférences dans les services Befiana
        </Typography>
        <br />

        <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899, borderRadius: '17px' }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Mettre à jour votre adresse e-mail
              </Typography>
              <Typography variant="body2" color="initial">{message}</Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Typography variant="h6">{message}</Typography>
                  <Button variant="contained" component={Link} to="/" sx={{ mt: 2 }}>
                    Retour à l'accueil
                  </Button>
                </>
              )}
            </CardContent>
          </Card>

        </Paper>

        <DrawerBarandTabsXS />
      </Box>
    </Box>
  );
}

export default MyAccountEmailVerify;
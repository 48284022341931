import React from 'react';
import { Box, Toolbar, Typography, Divider } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import CreateCountdownReminderForm from './components/CreateCountdownReminderForm';
import CountdownRemindersList from './components/CountdownRemindersList';

function SMSCountdownReminders() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="primary" align='center'>
                    Gestion des Rappels Avant Événement
                </Typography>
                <Divider />
                <Box sx={{ mt: 4 }}>
                    <CreateCountdownReminderForm />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <CountdownRemindersList />
                </Box>
                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSCountdownReminders;
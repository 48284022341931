import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Button, Tab, Tabs, CircularProgress, Snackbar, TextField, Badge, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Backdrop
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Alert } from '@mui/material';
import { Send as SendIcon, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';
import './VoucherTable.css';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SendSMS from './SendSMS';


const VoucherTableBoxMobile = () => {
    const [vouchers, setVouchers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorFormData, setErrorFormData] = useState({ correct_amount: '', message: '', reported_amount: '' });
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();

    const [showTabs, setShowTabs] = useState(true);
    const lastScrollY = useRef(0);

    const [openSendSMSDialog, setOpenSendSMSDialog] = useState(false);

    const handleOpenSendSMSDialog = () => {
        setOpenSendSMSDialog(true);
    };

    const handleCloseSendSMSDialog = () => {
        setOpenSendSMSDialog(false);
    };


    useEffect(() => {
        fetchVouchers();
    }, []);


    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue === 3) {
            handleLogout()
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY.current && window.scrollY > 30) {
                setShowTabs(false);
            } else {
                setShowTabs(true);
            }
            lastScrollY.current = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const tabsStyles = {
        position: 'fixed',
        bottom: showTabs ? 0 : -100,
        transition: 'bottom 0.7s ease-in-out',
        zIndex: 1000,
        backgroundColor: '#e750b2', width: '100%',
    };

    const fetchVouchers = async (params = '') => {
        setLoading(true);
        try {
            const response = await api.get(`/api/ibondacha/vouchers/box/${params}`);
            const vouchersWithDetails = await Promise.all(response.data.map(async (voucher) => {
                const storeResponse = voucher.store ? await api.get(`/api/ibondacha/stores/r/${voucher.store}/box/`) : null;
                return {
                    ...voucher,
                    storeName: storeResponse ? storeResponse.data.name : ''
                };
            }));
            setVouchers(vouchersWithDetails);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
        }
        setLoading(false);
    };

    const handleErrorClick = async (voucher) => {
        setSelectedVoucher(voucher);
        try {
            const response = await api.get(`/api/ibondacha/voucher-difference/${voucher.phone_number}/`);
            setErrorFormData({
                correct_amount: '',
                message: '',
                reported_amount: response.data.difference
            });
            setOpenErrorDialog(true);
        } catch (error) {
            console.error('Error fetching voucher difference:', error);
            setSnackbarMessage('Erreur lors de la récupération de la différence des vouchers.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleErrorChange = (e) => {
        const { name, value } = e.target;
        setErrorFormData({ ...errorFormData, [name]: value });
    };

    const handleErrorSubmit = async () => {
        setLoading(true);
        try {
            const data = {
                voucher_code: selectedVoucher.voucher_code,
                reported_amount: errorFormData.reported_amount,
                correct_amount: errorFormData.correct_amount
            };
            await api.post('/api/ibondacha/box/report-error/', data);
            setSnackbarMessage('Erreur de caisse soumise avec succès.');
            setSnackbarSeverity('success');
            fetchVouchers();
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Erreur lors de la soumission de l\'erreur de caisse.';
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            console.error('Error reporting error:', error);
        }
        setSnackbarOpen(true);
        setLoading(false);
        setOpenErrorDialog(false);
    };

    const sendSMS = async (phoneNumber) => {
        setLoading(true);
        try {
            await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
            setLoading(false);
            setSnackbarMessage('Le renvoi du SMS a été envoyé avec succès.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch {
            setLoading(false);
        }
    };

    const handleResendClick = (phone_number) => {
        sendSMS(phone_number);
    };

    const handleLogout = () => {
        localStorage.removeItem('store_reference');
        localStorage.removeItem('store_name');
        localStorage.removeItem('store_user');
        navigate('/caisse');
    };

    const formatDate = (date) => dayjs(date).format('HH:mm DD-MM-YYYY');

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const filteredVouchers = vouchers.filter((voucher) =>
        Object.values(voucher).some((value) =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        )
    );

    const columns = [
        {
            field: 'resend',
            headerName: 'SMS',
            width: 70,
            renderCell: (params) => (
                <IconButton
                    color="ib1"
                    onClick={() => handleResendClick(params.row.phone_number)}
                >
                    <Badge badgeContent={params.row.notification_count || 0} color="ib1">
                        <SendIcon />
                    </Badge>
                </IconButton>
            ),
        },
        { field: 'voucher_code', headerName: 'Transaction', width: 100 },
        {
            field: 'error',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <IconButton color="secondary" onClick={() => handleErrorClick(params.row)}>
                    <ErrorIcon />
                </IconButton>
            ),
        },
        { field: 'name_or_function', headerName: 'Nom', width: 150 },
        { field: 'phone_number', headerName: 'Phone', width: 95 },
        { 
            field: 'purchased', 
            headerName: 'Consommation', 
            width: 100, 
            renderCell: (params) => `${params.value ? parseInt(params.value, 10) : 0} Ar`
        },
        { field: 'purchased', headerName: 'Consommation', width: 100, renderCell: (params) => `${parseInt(params.value, 10)} Ar` },
        {
            field: 'status',
            headerName: 'État',
            width: 100,
            renderCell: (params) => {
                const statusMap = {
                    1: 'Actif',
                    2: 'Bloqué ou Perdu',
                    3: 'Erreur de caisse',
                    0: 'Déjà Utilisé'
                };
                return statusMap[params.value];
            },
            cellClassName: (params) => {
                const statusColorMap = {
                    1: 'status-active',
                    2: 'status-blocked',
                    3: 'status-error',
                    0: 'status-used'
                };
                return statusColorMap[params.value];
            }
        },
        { field: 'storeName', headerName: 'Magasin', width: 130 },
        { field: 'updated_at', headerName: 'Dernière action', width: 130, renderCell: (params) => formatDate(params.value) }
    ];

    return (
        <Box>
            <Box sx={{ p: 2, mx: 'auto', maxWidth: 375 }}>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={{ mb: 2 }}>
                    <TextField
                        fullWidth
                        label="Rechercher"
                        value={searchText}
                        onChange={handleSearchTextChange}
                        variant="outlined"
                        size="small"
                    />
                </Box>

                <Paper elevation={1} sx={{ p: 3 }}>
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={filteredVouchers}
                            columns={columns}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => {
                                const statusColorMap = {
                                    1: 'status-active',
                                    2: 'status-blocked',
                                    3: 'status-error',
                                    0: 'status-used'
                                };
                                return statusColorMap[params.row.status];
                            }}
                        />
                    </div>
                </Paper>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
                    <DialogTitle>
                        <Button variant="text" color="ib2" startIcon={<ShoppingCartOutlinedIcon />}>
                            Erreur de Caisse
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="reported_amount"
                            label="Montant Signalé"
                            type="number"
                            value={errorFormData.reported_amount}
                            disabled
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            name="correct_amount"
                            label="Montant Exact"
                            type="number"
                            value={errorFormData.correct_amount}
                            onChange={handleErrorChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenErrorDialog(false)} color="ib1" disableElevation>
                            Annuler
                        </Button>
                        <Button onClick={handleErrorSubmit} color="ib1" variant='contained' disableElevation>
                            Reclamer
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Box >
                <Box style={tabsStyles}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="iBondacha Mobile Navs"
                        centered
                        sx={{
                            '& .MuiTab-root': { color: '#e2e2e2' },
                            '& .MuiTab-iconWrapper': { color: '#e2e2e2' },
                            '& .Mui-selected': { color: 'white' },
                            '& .MuiTabs-indicator': { backgroundColor: '#48b6f8' }, 
                            opacity: 0.5
                        }}
                    >
                        <Tab icon={<SendIcon />} label="ENVOYER SMS" onClick={handleOpenSendSMSDialog} />
                        <Tab icon={<ExitToAppIcon />} label="FERMER" />
                    </Tabs>
                </Box>
            </Box>

            <Dialog open={openSendSMSDialog} onClose={handleCloseSendSMSDialog}>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button variant="text" color="ib1" startIcon={<SendIcon />}>
                            Envoyer un SMS
                        </Button>
                        <Button onClick={() => handleCloseSendSMSDialog(false)} color="secondary">
                            Annuler
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <SendSMS />
                </DialogContent>
            </Dialog>

        </Box>
    );
};

export default VoucherTableBoxMobile;
import React, { useState, useEffect } from 'react';
import { Box, Grid, Toolbar, Typography, Divider, Button, Paper, TextField, Snackbar } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../services/api';
import { useForm, Controller } from 'react-hook-form';
import CountrySelect from './CountrySelect';
import { useParams, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { Helmet } from 'react-helmet-async';

function MyAccountCoordDetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { control, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchAdresseDetails = async () => {
            try {
                const response = await api.get(`/api/addresses/${id}/`);
                reset(response.data); // Initialise le formulaire avec les données récupérées
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de l\'adresse:', error);
            }
        };

        fetchAdresseDetails();
    }, [id, reset]);

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            await api.put(`/api/addresses/${id}/`, data);
            setSnackbarMessage('Adresse mise à jour avec succès.');
            setOpenSnackbar(true);
            setIsLoading(false);
            navigate('/account'); // Redirige vers la liste des adresses après mise à jour
        } catch (error) {
            setSnackbarMessage('Erreur lors de la mise à jour de l\'adresse.');
            setOpenSnackbar(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    const handleDelete = async () => {
        setIsLoading(true);
        try {
            await api.delete(`/api/addresses/${id}/`);
            setSnackbarMessage('Adresse supprimée avec succès.');
            setOpenSnackbar(true);
            setIsLoading(false);
            navigate('/account'); // Redirige vers la liste des adresses après suppression
        } catch (error) {
            setSnackbarMessage('Erreur lors de la suppression de l\'adresse.');
            setOpenSnackbar(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Détails de l'adresse - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Divider>Befiana Connect</Divider>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                Vous avez la possibilité de sauvegarder diverses adresses en fonction de vos besoins sur Befiana Connect, telles que votre adresse de domicile, de facturation ou de travail. Cette fonctionnalité nous permet de mieux personnaliser et d'optimiser nos services pour répondre précisément à vos exigences.
                </Typography>
                <br />

                <Paper elevation={3} sx={{ p: 3, mt: 2, mb:10 }}>
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                        <HomeIcon color="primary" />
                        <Typography variant="h6" color="primary">
                            Détails de l'adresse
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="company_name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Nom d'entreprise" fullWidth margin="normal" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="address_line_1"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Adresse Ligne 1" fullWidth margin="normal" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="address_line_2"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Adresse Ligne 2" fullWidth margin="normal" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="city"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Ville" fullWidth margin="normal" />}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="region"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Région/État/Province" fullWidth margin="normal" />}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="postal_code"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Code Postal / ZIP" fullWidth margin="normal" />}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field }) => 
                                    <CountrySelect 
                                        {...field} 
                                        onChange={(option) => field.onChange(option.value)}
                                        fullWidth 
                                        margin="normal" 
                                    />}                                    
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Téléphone" fullWidth margin="normal" helperText="Commencez par l'indicatif du pays comme +26132xxxxxxx" />}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="address_type"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} label="Type d'adresse" fullWidth margin="normal" />}

                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={24} /> : 'Enregistrer'}
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={handleDelete} disabled={isLoading} sx={{ ml: 2 }}>
                                    Supprimer
                                </Button>

                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                />

                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default MyAccountCoordDetails;  
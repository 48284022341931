import React from 'react';
import { AppBar, Toolbar, Typography, Box, Paper, Button, Link, IconButton, Container, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Cookies() {
    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <img src="/befiana.png" alt="Befiana Logo" style={{ height: '50px', marginRight: '20px' }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                    </Typography>
                    <IconButton color="inherit" component={RouterLink} to="/account">
                        <MenuIcon />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Mon Compte
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>

                <Paper elevation={3} sx={{ p: 2, maxWidth: 899, mx: 'auto' }}>
                    <Typography variant="h4" gutterBottom>
                        Politique d'utilisation des cookies - Befiana Connect
                    </Typography>
                    <Helmet>
                        <title>Politique d'utilisation des cookies - BEFIANA Connect</title>
                        <meta name="description" content="Découvrez comment Befiana Connect utilise les cookies pour améliorer votre expérience utilisateur. Apprenez les types de cookies que nous utilisons, leur fonction et comment vous pouvez gérer vos préférences. Consultez notre Politique de cookies pour plus de détails." />
                    </Helmet>
                    <Typography paragraph>
                        La présente Politique en matière de cookies explique ce que sont les cookies et comment nous les utilisons. Vous devez lire cette politique afin de comprendre quel type de cookies nous utilisons, ou les informations que nous recueillons à l'aide de cookies et comment ces informations sont utilisées. Cette politique de cookies a été créée à l'aide du générateur de politique de cookies.
                    </Typography>
                    <Typography paragraph>
                    Les cookies ne contiennent généralement pas d'informations permettant d'identifier personnellement un utilisateur, mais les informations personnelles que nous stockons à votre sujet peuvent être liées aux informations stockées dans les cookies et obtenues à partir de ceux-ci. Pour plus d'informations sur la manière dont nous utilisons, stockons et protégeons vos données personnelles, consultez notre Politique de confidentialité.
                    </Typography>
                    <Typography paragraph>
                    Nous ne stockons pas d'informations personnelles sensibles, telles que les adresses postales, les mots de passe de compte, etc. dans les cookies que nous utilisons.
                    </Typography>
                    <Typography variant="subtitle2" color="initial">Date de dernière révision : 19 avril 2024</Typography>

                    <Divider sx={{ my: 1 }} />








                    <h2>Interpr&eacute;tation et d&eacute;finitions</h2>
<h3>Interpr&eacute;tation</h3>
<p>Les mots dont la lettre initiale est en majuscule ont des significations d&eacute;finies dans les conditions suivantes. Les d&eacute;finitions qui suivent ont la m&ecirc;me signification, qu'elles apparaissent au singulier ou au pluriel.</p>
<h3>D&eacute;finitions</h3>
<p>Aux fins de la pr&eacute;sente politique en mati&egrave;re de cookies :</p>
<ul>
<li><strong>La Soci&eacute;t&eacute;</strong> (ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; la Soci&eacute;t&eacute; &raquo;, &laquo; Nous &raquo;, &laquo; Notre &raquo; ou &laquo; Notre &raquo; dans la pr&eacute;sente Politique en mati&egrave;re de cookies) fait r&eacute;f&eacute;rence &agrave; Befiana Platform Corps Ltd, 71-75 Shelton Street, Covent Garden, Londres, Royaume-Uni, WC2H 9JQ.</li>
<li><strong>Les cookies</strong> sont de petits fichiers qui sont plac&eacute;s sur votre ordinateur, votre appareil mobile ou tout autre appareil par un site Web, contenant des d&eacute;tails sur votre historique de navigation sur ce site Web parmi ses nombreuses utilisations.</li>
<li><strong>Le site Web</strong> fait r&eacute;f&eacute;rence &agrave; Befiana Connect, accessible depuis <a href="https://www.befiana.cloud">https://www.befiana.cloud</a></li>
<li><strong>Vous</strong> d&eacute;signe la personne qui acc&egrave;de au site Web ou l'utilise, ou une soci&eacute;t&eacute;, ou toute entit&eacute; juridique au nom de laquelle cette personne acc&egrave;de au site Web ou l'utilise, selon le cas.</li>
</ul>
<h2>L'utilisation des Cookies</h2>
<h3>Type de cookies que nous utilisons</h3>
<p>Les cookies peuvent &ecirc;tre des cookies &laquo; persistants &raquo; ou des cookies &laquo; de session &raquo;. Les cookies persistants restent sur votre ordinateur personnel ou votre appareil mobile lorsque vous vous d&eacute;connectez, tandis que les cookies de session sont supprim&eacute;s d&egrave;s que vous fermez votre navigateur Web.</p>
<p>Nous utilisons &agrave; la fois des cookies de session et des cookies persistants aux fins &eacute;nonc&eacute;es ci-dessous :</p>
<p><strong>Cookies n&eacute;cessaires / essentiels</strong></p>
<p>Type : Cookies de session</p>
<p>Administr&eacute; par : Nous</p>
<p>Objectif : Ces cookies sont essentiels pour vous fournir les services disponibles sur le site Web et pour vous permettre d'utiliser certaines de ses fonctionnalit&eacute;s. Ils permettent d'authentifier les utilisateurs et d'emp&ecirc;cher l'utilisation frauduleuse des comptes d'utilisateurs. Sans ces cookies, les services que vous avez demand&eacute;s ne peuvent pas &ecirc;tre fournis, et nous n'utilisons ces cookies que pour vous fournir ces services.</p>
<p><strong>Cookies de fonctionnalit&eacute;</strong></p>
<p>Type : Cookies persistants</p>
<p>Administr&eacute; par : Nous</p>
<p>Objectif : Ces cookies nous permettent de nous souvenir des choix que vous faites lorsque vous utilisez le site Web, tels que la m&eacute;morisation de vos informations de connexion ou de vos pr&eacute;f&eacute;rences linguistiques. L'objectif de ces cookies est de vous offrir une exp&eacute;rience plus personnelle et de vous &eacute;viter d'avoir &agrave; saisir &agrave; nouveau vos pr&eacute;f&eacute;rences &agrave; chaque fois que vous utilisez le site Web.</p>
<h3>Vos choix en mati&egrave;re de cookies</h3>
<p>Si vous pr&eacute;f&eacute;rez &eacute;viter l'utilisation de cookies sur le site Web, vous devez d'abord d&eacute;sactiver l'utilisation de cookies dans votre navigateur, puis supprimer les cookies enregistr&eacute;s dans votre navigateur associ&eacute; &agrave; ce site Web. Vous pouvez utiliser cette option pour emp&ecirc;cher l'utilisation de cookies &agrave; tout moment.</p>
<p>Si vous n'acceptez pas nos cookies, vous risquez de rencontrer des inconv&eacute;nients lors de votre utilisation du site Web et certaines fonctionnalit&eacute;s peuvent ne pas fonctionner correctement.</p>
<p>Si vous souhaitez supprimer les cookies ou demander &agrave; votre navigateur Web de supprimer ou de refuser les cookies, veuillez consulter les pages d'aide de votre navigateur Web.</p>
<ul>
<li>Pour le navigateur Web Chrome, veuillez visiter cette page de Google : <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></li>
<li>Pour le navigateur Web Internet Explorer, veuillez consulter cette page de Microsoft : <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a></li>
<li>Pour le navigateur web Firefox, veuillez visiter cette page de Mozilla : <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
<li>Pour le navigateur Web Safari, veuillez visiter cette page d'Apple : <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
</ul>
<p>Pour tout autre navigateur Web, veuillez visiter les pages Web officielles de votre navigateur Web.</p>


                    <Divider sx={{ my: 1 }} />

                    <Typography variant="h6" gutterBottom>
                        Contactez-nous
                    </Typography>
                    <Typography paragraph>
                    Si vous avez des questions concernant cette politique de cookies, vous pouvez nous contacter :<br />
                        Par email : <Link href="mailto:policy@befiana.com">policy@befiana.com</Link><br />
                        Siège national : SITRAM Ankorondrano Zone Industrielle Route des Hydrocarbures B.P 171 – Antananarivo 101
                    </Typography>


                    <Button
                        component={RouterLink}
                        to="/"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Retour à l'accueil
                    </Button>
                </Paper>

            </Container>

            <Box component="footer" sx={{ bgcolor: 'background.paper', p: 2, mt: 4 }}>
                <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%' }}>
                        <Link href="/terms" underline="hover" sx={{ mx: 2, my: 1 }}>
                            CGU
                        </Link>
                        <Link href="/policy" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Politique de confidentialité
                        </Link>
                        <Link href="/cookies" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Cookies 
                        </Link>
                        <Link href="https://help.befiana.cloud" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Besoins d'aide
                        </Link>
                        <Link href="https://www.befiana.com/contact/" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Contact
                        </Link>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 2 }} align="center">
                        © {new Date().getFullYear()} Befiana Platform Corps
                    </Typography>
                </Container>
            </Box>


        </Box>
    );
}

export default Cookies;
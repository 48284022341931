import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Snackbar, IconButton, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; 
import api from '../../services/api';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBack } from '@mui/icons-material';

export default function AboutUpdatePhoneNumber() {
    const { control, handleSubmit, formState: { errors }, reset } = useForm();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const onSubmit = async data => {
        try {
            await api.put('/api/update-phone-number/', { phone_number: data.phone_number });
            setSnackbarMessage('Numéro de téléphone mis à jour avec succès');
            setSnackbarOpen(true);
            reset({ phone_number: data.phone_number }); 
        } catch (error) {
            setSnackbarMessage('Erreur lors de la mise à jour du numéro de téléphone');
            setSnackbarOpen(true);
        }
    };

    useEffect(() => {
        api.get('api/user-info/')
            .then(response => {
                reset({ phone_number: response.data.phone_number });
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nom d\'utilisateur:', error);
            });
    }, [reset]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const action = (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseSnackbar}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const navigate = useNavigate(); 
    const handleBack = () => {
        navigate(-1); 
    };

    return (
        <Box sx={{ mt: 3, mb: 3 }}>
            <IconButton onClick={handleBack} sx={{ mb: 2 }} color='primary'>
                <ArrowBack /> {/* Icône de retour */}
            </IconButton>

            <Typography mb={2} variant="subtitle2" color="initial">Votre numéro de téléphone doit débuter par l'indicatif du pays, par exemple : +261.</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="phone_number"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Le numéro de téléphone est requis", pattern: { value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/, message: "Format de numéro invalide" }}}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Numéro de téléphone"
                            variant="outlined"
                            fullWidth
                            error={!!errors.phone_number}
                            helperText={errors.phone_number ? errors.phone_number.message : ""}
                        />
                    )}
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Mettre à jour
                </Button>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                action={action}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#006837",
                        marginBottom: '75px'
                    },
                }}
            />
        </Box>
    );
}
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CircularProgress, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import api from '../../../../services/api';

const NotificationCountPerMonthTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/notification-count-per-month')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Card elevation={0}>
            <CardContent>
                <TableContainer component={Paper}>
                    <Table aria-label="notification count table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><strong>Mois</strong></TableCell>
                                <TableCell align="center"><strong>Total SMS</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{row.month}</TableCell>
                                    <TableCell align="center">{row.total_notifications}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default NotificationCountPerMonthTable;
import React, {useState} from 'react';
import { Box, Toolbar, Typography } from '@mui/material';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import BuySMS from './components/BuySMS';
import PaidInvoices from './components/PaidInvoices';
import UnpaidInvoices from './components/UnpaidInvoices';
import { Helmet } from 'react-helmet-async';

function SMSShop() {
    const [refreshUnpaid, setRefreshUnpaid] = useState(false);

    const handleRefreshUnpaidInvoices = () => {
        setRefreshUnpaid(prev => !prev);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Acheter des SMS - BEFIANA Connect</title>
                <meta name="description" content="Créez et gérez des campagnes SMS en masse avec BEFIANA Connect. Solution simple et efficace pour atteindre vos clients par SMS." />
            </Helmet>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h4" color="initial">Acheter des SMS</Typography>
                <Typography variant="subtitle2" color="initial">Bientôt, cette fonctionnalité sera automatisée avec Befiana Pay.</Typography>
                <br />
                    <BuySMS onPurchaseSuccess={handleRefreshUnpaidInvoices}/>
                    <br />

                    <UnpaidInvoices refresh={refreshUnpaid} />
                    <br />
                    <PaidInvoices />

                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSShop;
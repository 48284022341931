import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import api from '../../../../services/api';  

function ContactCounter() {
    const [totalContacts, setTotalContacts] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchTotalContacts();
        }, 2000); 

        return () => clearInterval(intervalId); 
    }, []);

    const fetchTotalContacts = async () => {
        try {
            const response = await api.get('/api/smsko/contacts/total/');
            setTotalContacts(response.data.total_contacts);
            setLoading(false);
        } catch (error) {
            // console.error('Failed to fetch total contacts', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 100 }}>
            {loading ? (
                <CircularProgress />
            ) : (
                <Typography variant="h6">
                    Nombre total de contacts : {totalContacts}
                </Typography>
            )}
        </Box>
    );
}

export default ContactCounter;
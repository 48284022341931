import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CashRegister from './components/CashRegister';
import VoucherTableBox from './components/VoucherTableBox';
import { Link, useNavigate } from 'react-router-dom';
import useStoreAuth from '../../../hooks/useStoreAuth ';
import { Helmet } from 'react-helmet-async';

function IBCashBox() {
    useStoreAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Detect if the device is a phone (excluding iPad)
        const isPhone = /iPhone|Android/i.test(navigator.userAgent) && !/iPad/i.test(navigator.userAgent);

        if (isPhone) {
            // Redirect to the mobile-specific URL
            navigate('/App/iBondacha/cash-m');
        }
    }, [navigate]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Caisse - iBondacha</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Box sx={{ display: 'block', margin: '0 auto', maxWidth: 1200 }}>
                    <Link to="/App/iBondacha/cash">
                        <img src="/ibondacha.png" width="200px" alt="Logo iBondacha" style={{marginLeft:'49px',  paddingBottom: '25px'}} />
                    </Link>
                </Box>
                <CashRegister />

                <VoucherTableBox />

            </Box>
        </Box>
    );
}

export default IBCashBox;
import React, { useState, useEffect } from 'react';
import {
  Box, Button, CircularProgress, Snackbar, TextField, Badge, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Backdrop
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { fr } from 'dayjs/locale/fr';
import 'dayjs/locale/fr';
import { Alert } from '@mui/material'; 
import { Send as SendIcon, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';
import './VoucherTable.css';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; 
import localizedFormat from 'dayjs/plugin/localizedFormat';
import SendSMS from './SendSMS';
dayjs.extend(localizedFormat);
dayjs.locale('fr');

const VoucherTableBox = () => {
  const [vouchers, setVouchers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorFormData, setErrorFormData] = useState({ correct_amount: '', message: '', reported_amount: '' });
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('day'));
  const navigate = useNavigate();
  const [openSendSMSDialog, setOpenSendSMSDialog] = useState(false);

  const handleOpenSendSMSDialog = () => {
    setOpenSendSMSDialog(true);
  };

  const handleCloseSendSMSDialog = () => {
      setOpenSendSMSDialog(false);
  };

  useEffect(() => {
    dayjs.locale(fr);
    fetchVouchers();
  }, []);

  const fetchVouchers = async (params = '') => {
    setLoading(true);
    try {
      const response = await api.get(`/api/ibondacha/vouchers/box/${params}`);
      const vouchersWithDetails = await Promise.all(response.data.map(async (voucher) => {
        const storeResponse = voucher.store ? await api.get(`/api/ibondacha/stores/r/${voucher.store}/box/`) : null;
        return {
          ...voucher,
          storeName: storeResponse ? storeResponse.data.name : ''
        };
      }));
      setVouchers(vouchersWithDetails);
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
    setLoading(false);
  };

  const handleErrorClick = async (voucher) => {
    setSelectedVoucher(voucher);
    try {
      const response = await api.get(`/api/ibondacha/voucher-difference/${voucher.phone_number}/`);
      setErrorFormData({ 
        correct_amount: '', 
        message: '', 
        reported_amount: response.data.difference 
      });
      setOpenErrorDialog(true);
    } catch (error) {
      console.error('Error fetching voucher difference:', error);
      setSnackbarMessage('Erreur lors de la récupération de la différence des vouchers.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleErrorChange = (e) => {
    const { name, value } = e.target;
    setErrorFormData({ ...errorFormData, [name]: value });
  };

  const handleErrorSubmit = async () => {
    setLoading(true);
    try {
      const data = {
        voucher_code: selectedVoucher.voucher_code,
        reported_amount: errorFormData.reported_amount,
        correct_amount: errorFormData.correct_amount
      };
      await api.post('/api/ibondacha/box/report-error/', data);
      setSnackbarMessage('Erreur de caisse soumise avec succès.');
      setSnackbarSeverity('success');
      fetchVouchers();
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Erreur lors de la soumission de l\'erreur de caisse.';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      console.error('Error reporting error:', error);
    }
    setSnackbarOpen(true);
    setLoading(false);
    setOpenErrorDialog(false);
  };

  const handleActualise = () => {
    fetchVouchers();
  };

  const handleDownload = async () => {
    const store = localStorage.getItem('store_reference');
    const storeUser = localStorage.getItem('store_user');
  
    if (!store || !storeUser) {
      alert('Store or store_user information is missing in local storage.');
      return;
    }
  
    try {
      const response = await api.get('/api/ibondacha/vouchers/export/', {
        params: {
          store,
          store_user: storeUser,
        },
        responseType: 'blob', 
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `iBondacha-${new Date().toLocaleDateString('fr-CA')}.xlsx`); 
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the file', error);
      alert('Failed to download the file. Please try again.');
    }
  };

  const handleDownloadByDate = async () => {
    setLoading(true);
    const store = localStorage.getItem('store_reference');
    const storeUser = localStorage.getItem('store_user');
  
    if (!store || !storeUser) {
      alert('Store or store_user information is missing in local storage.');
      setLoading(false);
      return;
    }
  
    try {
      const response = await api.get('/api/ibondacha/vouchers/export-by-date/', {
        params: {
          store,
          store_user: storeUser,
          date: selectedDate.format('YYYY-MM-DD'),
        },
        responseType: 'blob', 
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `iBondacha-${selectedDate.format('YYYY-MM-DD')}.xlsx`); 
      document.body.appendChild(link);
      link.click();
      link.remove();
      setOpenDateDialog(false);
    } catch (error) {
      console.error('Error downloading the file', error);
      alert('Failed to download the file. Please try again.');
    }
    setLoading(false);
  };

  const sendSMS = async (phoneNumber) => {
    setLoading(true);
    try {
      await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
      setLoading(false);
      setSnackbarMessage('Le renvoi du SMS a été envoyé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch {
      setLoading(false);
    }
  };

  const handleResendClick = (phone_number) => {
    sendSMS(phone_number);
  };

  const handleShowStoreReference = () => {
    const storeReference = localStorage.getItem('store_reference');
    const storeName = localStorage.getItem('store_name');
    if (storeReference && storeName) {
      setSnackbarMessage(`${storeReference} (${storeName})`);
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage('Aucune information de caisse trouvée.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('store_reference');
    localStorage.removeItem('store_name');
    localStorage.removeItem('store_user');
    navigate('/caisse');
  };

  const handleMobile = () => {
    navigate('/App/iBondacha/cash-m');
  };

  const formatDate = (date) => dayjs(date).format('HH:mm DD-MM-YYYY');

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredVouchers = vouchers.filter((voucher) => 
    Object.values(voucher).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const columns = [
    {
      field: 'resend',
      headerName: 'SMS',
      width: 70,
      renderCell: (params) => (
        <IconButton
          color="ib1"
          onClick={() => handleResendClick(params.row.phone_number)}
        >
          <Badge badgeContent={params.row.notification_count || 0} color="ib1">
            <SendIcon />
          </Badge>
        </IconButton>
      ),
    },
    { field: 'voucher_code', headerName: 'Transaction', width: 100 },
    {
      field: 'error',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleErrorClick(params.row)}>
          <ErrorIcon />
        </IconButton>
      ),
    },
    { field: 'name_or_function', headerName: 'Nom', width: 150 },
    { field: 'phone_number', headerName: 'Phone', width: 95 },
    { field: 'amount', headerName: 'Montant', width: 100, renderCell: (params) => `${parseInt(params.value, 10)} Ar` },
    { 
      field: 'purchased', 
      headerName: 'Consommation', 
      width: 100, 
      renderCell: (params) => `${params.value ? parseInt(params.value, 10) : 0} Ar`
    },
    {
      field: 'status',
      headerName: 'État',
      width: 100,
      renderCell: (params) => {
        const statusMap = {
          1: 'Actif',
          2: 'Bloqué ou Perdu',
          3: 'Erreur de caisse',
          0: 'Déjà Utilisé'
        };
        return statusMap[params.value];
      },
      cellClassName: (params) => {
        const statusColorMap = {
          1: 'status-active',
          2: 'status-blocked',
          3: 'status-error',
          0: 'status-used'
        };
        return statusColorMap[params.value];
      }
    },
    { field: 'storeName', headerName: 'Magasin', width: 130 },
    { field: 'updated_at', headerName: 'Dernière action', width: 130, renderCell: (params) => formatDate(params.value) }
  ];

  return (
    <Box sx={{ p: 5, mx: 'auto', maxWidth: 1200 }}>
      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Rechercher"
          value={searchText}
          onChange={handleSearchTextChange}
          variant="outlined"
          size="small"
        />
      </Box>
      
      <Paper elevation={1} sx={{ p: 3 }}>
        <div style={{ height: 600, width: '100%' }}>
          <DataGrid 
              rows={filteredVouchers} 
              columns={columns} 
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText} 
              getRowClassName={(params) => {
                  const statusColorMap = {
                    1: 'status-active',
                    2: 'status-blocked',
                    3: 'status-error',
                    0: 'status-used'
                  };
                  return statusColorMap[params.row.status];
              }}
          />
        </div>
      </Paper>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)}>
        <DialogTitle>
          <Button variant="text" color="ib2" startIcon={<ShoppingCartOutlinedIcon />}>
          Erreur de Caisse
          </Button>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            name="reported_amount"
            label="Montant Signalé"
            type="number"
            value={errorFormData.reported_amount}
            disabled
          />
          <TextField
            fullWidth
            margin="normal"
            name="correct_amount"
            label="Montant Exact"
            type="number"
            value={errorFormData.correct_amount}
            onChange={handleErrorChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="ib1" disableElevation>
            Annuler
          </Button>
          <Button onClick={handleErrorSubmit} color="ib1" variant='contained' disableElevation>
            Reclamer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDateDialog} onClose={() => setOpenDateDialog(false)}>
        <DialogTitle>Télécharger les iBondacha par date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <DatePicker
              label="Sélectionner la date"
              value={selectedDate}
              onChange={(newValue) => setSelectedDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              inputFormat="DD/MM/YYYY"
              maxDate={dayjs()}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDateDialog(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleDownloadByDate} color="primary">
            Télécharger
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSendSMSDialog} onClose={handleCloseSendSMSDialog}>
        <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button variant="text" color="ib1" startIcon={<SendIcon />}>
                    Envoyer un SMS
                </Button>
                <Button onClick={() => handleCloseSendSMSDialog(false)} color="secondary">
                    Annuler
                </Button>
            </Box>
        </DialogTitle>
        <DialogContent>
            <SendSMS />
        </DialogContent>
      </Dialog>

      <SpeedDial
        ariaLabel="IB Action"
        sx={{ position: 'fixed', top: 69, right: 26, '& .MuiFab-primary': { backgroundColor: '#e84eb2', opacity: '0.7', height: 40, width: 40 } }}
        icon={<SpeedDialIcon color='ib2' />}
        direction='down'
      >
        <SpeedDialAction
          icon={<ReplayOutlinedIcon color='info' />}
          tooltipTitle="Actualiser"
          onClick={handleActualise}
        />
        <SpeedDialAction
          icon={<SendIcon color='info' />}
          tooltipTitle="Envoyer SMS"
          onClick={handleOpenSendSMSDialog}
        />
        <SpeedDialAction
          icon={<SaveAsIcon color='info' />}
          tooltipTitle="Télécharger"
          onClick={handleDownload}
        />
        <SpeedDialAction
          icon={<CalendarMonthIcon color='info' />}
          tooltipTitle="Télécharger par Date"
          onClick={() => setOpenDateDialog(true)}
        />
        <SpeedDialAction
          icon={<AppShortcutIcon color='info' />}
          tooltipTitle="Version Mobile"
          onClick={handleMobile}
        />
        <SpeedDialAction
          icon={<ExitToAppIcon color='error' />}
          tooltipTitle="Fermer la caisse"
          onClick={handleLogout}
        />
      </SpeedDial>
      
    <Button color='ib1' variant="contained" sx={{ position: 'absolute', top: 16, right: 16, color: '#fff', fontSize: '16px', padding: '9px', opacity: '0.7', zIndex: 999 }} onClick={handleShowStoreReference}>
    {localStorage.getItem('store_name') || 'Nom du magasin'}
    </Button>

    </Box>
  );
};

export default VoucherTableBox;

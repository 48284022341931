import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, TextField, Typography, Tooltip, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import dayjs from 'dayjs';
import api from '../../../../services/api';  

import {
    Send as SendIcon,
    CheckCircle as CheckCircleIcon,
    Help as HelpIcon,
    Error as ErrorIcon,
    Schedule as ScheduleIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

function SentSMSList() {
    const [smsList, setSmsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedStatusInfo, setSelectedStatusInfo] = useState(null);

    // useEffect(() => {
    //     fetchSentSMS();
    // }, []);

    useEffect(() => {
        fetchSentSMS(); 
    
        const intervalId = setInterval(() => {
            fetchSentSMSNoLoad(); 
        }, 9000); 
    
        return () => {
            clearInterval(intervalId); 
        };
    }, []);


    const fetchSentSMS = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/sms/sent-sms/');
            const formattedData = response.data.map(sms => ({
                ...sms,
                send_date: dayjs(sms.send_date).format('DD/MM/YYYY HH:mm:ss')
            }));
            setSmsList(formattedData);
        } catch (error) {
            // console.error('Failed to fetch sent SMS:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchSentSMSNoLoad = async () => {
        try {
            const response = await api.get('/api/smsko/sms/sent-sms/');
            const formattedData = response.data.map(sms => ({
                ...sms,
                send_date: dayjs(sms.send_date).format('DD/MM/YYYY HH:mm:ss')
            }));
            setSmsList(formattedData);
        } catch (error) {
            // console.error('Failed to fetch sent SMS:', error);
        } finally {
            // setLoading(false);
        }
    };

    // Mapping des statuts aux libellés, icônes et descriptions
    const statusMapping = {
        'DeliveredToNetwork': {
            label: 'Message envoyé',
            icon: <SendIcon style={{ color: '#1976d2', marginTop: '13px' }} />,
            color: '#1976d2',
            description: 'Votre message a été envoyé avec succès vers le réseau et est en cours de traitement. Le message est en route vers le destinataire.'
        },
        'DeliveredToTerminal': {
            label: 'Message reçu par le destinataire',
            icon: <CheckCircleIcon style={{ color: '#4caf50', marginTop: '13px' }} />,
            color: '#4caf50',
            description: 'Votre message a été remis au téléphone du destinataire. Le destinataire a reçu le message.'
        },
        'DeliveryUncertain': {
            label: 'Statut de livraison inconnu',
            icon: <HelpIcon style={{ color: '#ff9800', marginTop: '13px' }} />,
            color: '#ff9800',
            description: 'Nous ne sommes pas en mesure de confirmer la livraison de votre message pour le moment. Le statut est incertain ; il peut être nécessaire de vérifier ultérieurement.'
        },
        'DeliveryImpossible': {
            label: 'Message non délivré au destinataire',
            icon: <ErrorIcon style={{ color: '#f44336', marginTop: '13px' }} />,
            color: '#f44336',
            description: 'Votre message n\'a pas pu être remis au destinataire avant expiration. Le message n\'a pas été reçu par le destinataire. Cela peut être dû à plusieurs raisons, comme le téléphone éteint ou hors couverture pendant une période prolongée.'
        },
        'MessageWaiting': {
            label: 'Envoi en cours',
            icon: <ScheduleIcon style={{ color: '#2196f3', marginTop: '13px' }} />,
            color: '#2196f3',
            description: 'Votre message est en attente d\'être envoyé. Le message sera envoyé prochainement.'
        },
        'null': {
            label: 'Statut inconnu',
            icon: <InfoIcon style={{ color: '#9e9e9e', marginTop: '13px' }} />,
            color: '#9e9e9e',
            description: 'Le statut de ce message est inconnu.'
        }
    };

    const handleStatusClick = (statusInfo) => {
        setSelectedStatusInfo(statusInfo);
        setOpenModal(true);
    };

    const columns = [
        {
            field: 'delivery_status',
            headerName: 'Délivrance',
            width: 50,
            renderCell: (params) => {
                const status = params.value || 'null';
                const statusInfo = statusMapping[status] || statusMapping['null'];

                return (
                    <Box display="flex" alignItems="center" onClick={() => handleStatusClick(statusInfo)} style={{ cursor: 'pointer' }}>
                        {statusInfo.icon}
                    </Box>
                );
            }
        },
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'phone_number', headerName: 'Numéro', width: 130 },
        { field: 'message', headerName: 'Message', width: 150, flex: 1 },
        { field: 'send_date', headerName: 'Date d\'envoi', width: 180 },
        { field: 'callback_data', headerName: 'Ref SMS by BEFIANA', width: 250 },
    ];

    const handleSearch = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const filteredSMS = smsList.filter((sms) => {
        return sms.message.toLowerCase().includes(searchText) ||
               sms.phone_number.includes(searchText);
    });

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <TextField
                label="Rechercher"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleSearch}
                sx={{ mb: 2 }}
            />
            {loading ? (
                <CircularProgress />
            ) : (
                <DataGrid
                    rows={filteredSMS}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            )}

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="status-modal-title"
                aria-describedby="status-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedStatusInfo && (
                        <>
                            <Box display="flex" alignItems="center" mb={2}>
                                {selectedStatusInfo.icon}
                                <Typography id="status-modal-title" variant="h6" component="h2" sx={{ ml: 2 }}>
                                    {selectedStatusInfo.label}
                                </Typography>
                            </Box>
                            <Typography id="status-modal-description">
                                {selectedStatusInfo.description}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
}

export default SentSMSList;
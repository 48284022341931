import React, { useState, useEffect } from 'react';
import { Box, Toolbar, Typography, Divider, Button, TextField, Card, CardContent, Paper, Stack } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';

import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../services/api';
import { Helmet } from 'react-helmet-async';


function MyAccountBirthday() {
    const [message, setMessage] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [back, setBack] = useState("Annuler");
    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        api.get('api/user-info/')
            .then(response => {
                const birthDate = response.data.date_of_birth ? dayjs(response.data.date_of_birth) : null;
                setDateOfBirth(birthDate);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des informations utilisateur:', error);
            });
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');

        try {
            await api.put('api/update-date-of-birth/', { date_of_birth: formattedDate });
            setOpen(true);
            setMessage('Votre date de naissance a été mise à jour avec succès.');
            setIsLoading(false)
            setBack("Retour")
        } catch (error) {
            setMessage('Erreur lors de la mise à jour de la date de naissance.');
            console.error(error);
            setIsLoading(false)
        }
    };

    const [selectedDate, setSelectedDate] = useState(null);
    const minAge = 13;
    const maxAge = 99;

    const handleChangeBirthDate = (newValue) => {
        setSelectedDate(newValue);
    };

    const isBirthDateValid = () => {
        if (!selectedDate) return false;
    
        const today = dayjs();
        const age = today.diff(selectedDate, 'year');
        return age >= minAge && age <= maxAge;
    };
    const canProceedToNextStep = isBirthDateValid();


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Helmet>
                    <title>Date de naissance - BEFIANA Connect</title>
                </Helmet>
                <AppBarComponent />
                <DrawerBarMD />

                <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                    <Toolbar />

                    <Divider>Befiana Connect</Divider>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                        Informations sur vous et vos préférences dans les services Befiana
                    </Typography>
                    <br />

                    <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899, borderRadius: '17px' }}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <form onSubmit={handleSubmit}>
                                    <DatePicker
                                        label="Date de naissance"
                                        value={dateOfBirth}
                                        onChange={handleChangeBirthDate}
                                        format="DD/MM/YYYY"
                                        renderInput={(params) => <TextField {...params} 
                                        fullWidth 
                                        helperText="Nous avons besoin de cette information pour vérifier l'accès à nos services." />}
                                        minDate={dayjs().subtract(maxAge, 'year')}
                                        maxDate={dayjs().subtract(minAge, 'year')}
                                        sx={{ mt: 2 }}
                                    />
                                    <Typography sx={{ fontSize: "11px", m: 1, color: "#757575" }} component="p" gutterBottom>
                                        Nous avons besoin de cette information pour vérifier l'accès à nos services.
                                    </Typography>

                                    <Stack direction="row" spacing={2} justifyContent="space-between">

                                        <Button
                                            variant="outlined"
                                            sx={{ mt: 2, mb: 2, p: 1 }}
                                            startIcon={<ArrowBackIcon />}
                                            size="small"
                                            component={Link}
                                            to="/account"
                                        >
                                            {back}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{ mt: 2, mb: 2, p: 1 }}
                                            startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}
                                            size="small"
                                            disabled={!canProceedToNextStep}
                                        >
                                            Mettre à jour
                                        </Button>
                                    </Stack>

                                </form>

                                <div>
                                    <Snackbar
                                        open={open}
                                        autoHideDuration={4000}
                                        onClose={handleClose}
                                        message={message}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                backgroundColor: '#006837',
                                                marginBottom: '75px'
                                            },
                                        }}
                                    />
                                </div>


                            </CardContent>
                        </Card>

                    </Paper>

                    <DrawerBarandTabsXS />
                </Box>
            </Box>
        </LocalizationProvider>

    );
}

export default MyAccountBirthday;
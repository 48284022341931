import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, Backdrop, CircularProgress, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import api from '../../../../services/api';  

const Alert = React.forwardRef(function Alert(props, ref) {
     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });

function ContactUpdateForm({ contact, onUpdate }) {
    const [contactData, setContactData] = useState({
        phone_number: '',
        first_name: '',
        birthday: null,
        office_email: '',
        home_email: '',
        company: '',
        position: '',
        address: ''
    });

    const [loading, setLoading] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (contact) {
            setContactData({
                phone_number: contact.phone_number || '',
                first_name: contact.first_name || '',
                birthday: contact.birthday ? dayjs(contact.birthday) : null,  
                office_email: contact.office_email || '',
                home_email: contact.home_email || '',
                company: contact.company || '',
                position: contact.position || '',
                address: contact.address || ''
            });
        }
    }, [contact]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setContactData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (newValue) => {
        setContactData(prev => ({ ...prev, birthday: newValue }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await api.post('/api/smsko/add-contact/', {
                ...contactData,
                birthday: contactData.birthday ? contactData.birthday.format('YYYY-MM-DD') : null
            });
            setSnackbarMessage('La mise à jour des informations de votre numéro a été effectuée avec succès !');
            setOpenSnackbar(true);
            onUpdate();
        } catch (error) {
            setSnackbarMessage('La mise à jour du contact a échoué. Essayez de consulter les informations dans les options avancées.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await api.delete(`/api/smsko/contacts/delete/${contact.id}/`);
            setSnackbarMessage('Contact supprimé avec succès !');
            setOpenSnackbar(true);
            onUpdate(); 
        } catch (error) {
            setSnackbarMessage('Échec de la suppression du contact.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
                <TextField
                    label="Numéro de téléphone (+261)"
                    variant="outlined"
                    name="phone_number"
                    value={contactData.phone_number}
                    onChange={handleChange}
                    required
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Nom"
                    variant="outlined"
                    name="first_name"
                    value={contactData.first_name}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                {showOptions && (
                <>
                    
                    <DatePicker
                    label="Date de naissance"
                    value={contactData.birthday}
                    onChange={handleDateChange}
                    helperText="Cette information ce sera utile comme souhaiter anniversaire automatiquement vos clients"
                    renderInput={(params) => <TextField {...params} />}
                    />
                    <Typography sx={{ fontSize: "11px", m: 1, color: "#757575" }} component="p" gutterBottom>
                    Cette information est essentielle si vous souhaitez automatiser l'envoi de messages d'anniversaire à vos contacts au sein de votre entreprise ou organisation.
                    </Typography>
                    <TextField
                        label="Email professionnel"
                        variant="outlined"
                        name="office_email"
                        value={contactData.office_email}
                        onChange={handleChange}
                        format="DD/MM/YYYY"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Email personnel"
                        variant="outlined"
                        name="home_email"
                        value={contactData.home_email}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Société"
                        variant="outlined"
                        name="company"
                        value={contactData.company}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Poste"
                        variant="outlined"
                        name="position"
                        value={contactData.position}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Adresse"
                        variant="outlined"
                        name="address"
                        value={contactData.address}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                </>
                )}
                <Button onClick={() => setShowOptions(!showOptions)} color="primary">
                {showOptions ? 'Afficher moins' : 'Afficher plus d\'options'}
                </Button>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Mettre à jour
                </Button>
                <Button onClick={handleDelete} variant="outlined" color="error" fullWidth sx={{ mt: 2 }}>
                    Supprimer
                </Button>
                <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                    <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </form>
        </LocalizationProvider>
    );
}

export default ContactUpdateForm;
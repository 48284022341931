import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress, Box, Grid } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExpiredIcon from '@mui/icons-material/HourglassDisabled';
import UnusedIcon from '@mui/icons-material/MonetizationOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import api from '../../../../services/api';
import { teal, orange, green } from '@mui/material/colors';

const GlobalStats = () => {
    const [data, setData] = useState({
        total_used_vouchers: 0,
        total_expired_vouchers: 0,
        total_unused_amount: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/stats')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Card elevation={1}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <ReceiptIcon fontSize="large" sx={{ color: teal[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">iBondacha Utilisés</Typography>
                                <Typography variant="h4" color="primary">{data.total_used_vouchers}</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card elevation={1}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <ExpiredIcon fontSize="large" sx={{ color: orange[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">iBondacha Expirés</Typography>
                                <Typography variant="h4" color="primary">{data.total_expired_vouchers}</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
                <Card elevation={1}>
                    <CardContent>
                        <Box display="flex" alignItems="center">
                            <UnusedIcon fontSize="large" sx={{ color: green[500] }} />
                            <Box ml={2}>
                                <Typography variant="h6">Montant Non Utilisé</Typography>
                                <Typography variant="h4" color="primary">
                                    {Number(data.total_unused_amount).toLocaleString('fr-FR', { style: 'currency', currency: 'MGA', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default GlobalStats;
import React, { useState, useEffect } from 'react';
import { 
    Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
    Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Divider, 
    CircularProgress, Backdrop, Snackbar, Alert, Tooltip, FormControlLabel, Switch, TextField, Grid
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CakeIcon from '@mui/icons-material/Cake';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import api from '../../../../services/api';
import ContactUpdateForm from './ContactUpdateForm'; 
import SMSHappyBirthday from '../../../../components/lotties/HappyBirthday';

function BirthdaySMSToggle() {
    const [birthdaySMS, setBirthdaySMS] = useState(false);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [companyName, setCompanyName] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const maxCompanyNameLength = 19;

    const [birthdaySMSTemplate, setBirthdaySMSTemplate] = useState('');
    const [newBirthdaySMSTemplate, setNewBirthdaySMSTemplate] = useState('');
    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

    const [birthdayContactsDialogOpen, setBirthdayContactsDialogOpen] = useState(false);
    const [birthdayContacts, setBirthdayContacts] = useState([]);
    const [loadingBirthdays, setLoadingBirthdays] = useState(false);

    const [selectedContact, setSelectedContact] = useState(null);
    const [openUpdateForm, setOpenUpdateForm] = useState(false);

    useEffect(() => {
        const fetchBirthdaySMSStatus = async () => {
            try {
                const response = await api.get('/api/smsko/toggle-birthday-sms/status/');
                if (response.data.config_found) {
                    setBirthdaySMS(response.data.birthday_sms_enabled);
                } else {
                    // Pas de config trouvée
                    setBirthdaySMS(false);
                    setSnackbarMessage('Aucune configuration d\'anniversaire trouvée, veuillez configurer le service.');
                    setSnackbarSeverity('info');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du statut Birthday SMS.', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCompanyName = async () => {
            try {
                const response = await api.get('/api/smsko/company-name/');
                if (response.data.config_found) {
                    setCompanyName(response.data.company_name || '');
                } else {
                    // Pas de company_name défini
                    setCompanyName('');
                    setSnackbarMessage('Aucune configuration d\'entreprise trouvée, veuillez en définir une.');
                    setSnackbarSeverity('info');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du nom de l\'entreprise:', error);
            }
        };

        const fetchBirthdaySMSTemplate = async () => {
            try {
                const response = await api.get('/api/smsko/get-birthday-sms-template/');
                if (response.data.config_found) {
                    setBirthdaySMSTemplate(response.data.birthday_sms_template || '');
                } else {
                    // Aucun template défini
                    setBirthdaySMSTemplate('');
                    setSnackbarMessage('Aucun template SMS d\'anniversaire défini, veuillez le personnaliser.');
                    setSnackbarSeverity('info');
                    setSnackbarOpen(true);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du template SMS d\'anniversaire:', error);
            }
        };

        fetchBirthdaySMSStatus();
        fetchCompanyName();
        fetchBirthdaySMSTemplate();
    }, []);

    const handleToggle = async () => {
        try {
            const response = await api.post('/api/smsko/toggle-birthday-sms/');
            setBirthdaySMS(response.data.birthday_sms_enabled);
            setSnackbarMessage('Le paramètre SMS d\'anniversaire a été mis à jour avec succès');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Échec de la mise à jour des paramètres SMS d\'anniversaire');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCompanyNameChange = (event) => {
        const value = event.target.value;
        if (value.length <= maxCompanyNameLength) {
            setNewCompanyName(value);
        }
    };

    const handleCompanyNameSave = async () => {
        try {
            const response = await api.post('/api/smsko/update-company-name/', { company_name: newCompanyName });
            setCompanyName(response.data.company_name);
            setSnackbarMessage('Nom de l\'entreprise mis à jour avec succès');
            setSnackbarSeverity('success');
            setDialogOpen(false);
        } catch (error) {
            setSnackbarMessage('Échec de la mise à jour du nom de l\'entreprise');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const isGSMCharacter = (char) => {
        const GSM_03_38_REGEX = /^[A-Za-z0-9 \r\n@£$¥èéêëùûüîïâäàôöçÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€°œŒ«»‘’“”‹›±×÷√∞∑∏π∂∆≈≠≤≥∫∬∮°µ‰∞]*$/;
        const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
        return GSM_03_38_REGEX.test(char) || emojiRegex.test(char);
    };

    const getMessageLength = (message) => {
        const adjustedMessage = message.replace(/\*\|FNAME\|\*/g, ' '.repeat(33)).replace(/\*\|COMPANY\|\*/g, ' '.repeat(20));
        return adjustedMessage.split('').reduce((count, char) => {
            const doubleCountChars = ['ê', 'ë', 'î', 'ï', 'â', 'ä', 'ô', 'ö', 'û', 'ü', 'œ', 'Œ'];
            const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
            if (emojiRegex.test(char)) {
                return count + 3;
            }
            return count + (doubleCountChars.includes(char) ? 2 : 1);
        }, 0);
    };

    const getSmsCount = (messageLength) => {
        if (messageLength <= 160) {
            return 1;
        } else if (messageLength <= 320) {
            return 2;
        }
        return Math.ceil(messageLength / 160);
    };

    const handleBirthdaySMSTemplateChange = (event) => {
        let newMessage = event.target.value;

        if (newMessage.endsWith('*|') && !newMessage.endsWith('*|FNAME|*') && !newMessage.endsWith('*|COMPANY|*')) {
            if (newMessage.endsWith('*|F')) {
                newMessage = newMessage.slice(0, -3) + '*|FNAME|*';
            } else if (newMessage.endsWith('*|C')) {
                newMessage = newMessage.slice(0, -3) + '*|COMPANY|*';
            }
        }

        if (newMessage.endsWith('%f') && !newMessage.endsWith('*|FNAME|*')) {
            newMessage = newMessage.slice(0, -2) + '*|FNAME|*';
        }

        if (newMessage.endsWith('%c') && !newMessage.endsWith('*|COMPANY|*')) {
            newMessage = newMessage.slice(0, -2) + '*|COMPANY|*';
        }

        if (newMessage.split('').every(isGSMCharacter) && getMessageLength(newMessage) <= 320) {
            setNewBirthdaySMSTemplate(newMessage);
        }
    };

    const handleBirthdaySMSTemplateSave = async () => {
        try {
            const response = await api.post('/api/smsko/update-birthday-sms-template/', { birthday_sms_template: newBirthdaySMSTemplate });
            setBirthdaySMSTemplate(response.data.birthday_sms_template);
            setSnackbarMessage('Template SMS d\'anniversaire mis à jour avec succès');
            setSnackbarSeverity('success');
            setTemplateDialogOpen(false);
        } catch (error) {
            setSnackbarMessage('Échec de la mise à jour du template SMS d\'anniversaire');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const previewMessage = birthdaySMSTemplate
        ? birthdaySMSTemplate.replace('*|FNAME|*', 'Prénom').replace('*|COMPANY|*', companyName || '[NOM DE L\'ENTREPRISE]')
        : `Toute l'équipe de ${companyName || '[NOM DE L\'ENTREPRISE]'} vous souhaite un très joyeux anniversaire ! Profitez de cette journée spéciale et que tous vos rêves se réalisent.`;

    const handleTemplateDialogOpen = () => {
        setNewBirthdaySMSTemplate(birthdaySMSTemplate);
        setTemplateDialogOpen(true);
    };

    const handleTemplateDialogClose = () => {
        setTemplateDialogOpen(false);
    };

    const handleBirthdayContactsOpen = async () => {
        setLoadingBirthdays(true);
        try {
            const response = await api.get('/api/smsko/contacts/birthday/');
            setBirthdayContacts(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des contacts avec anniversaire:', error);
        } finally {
            setLoadingBirthdays(false);
            setBirthdayContactsDialogOpen(true);
        }
    };

    const handleBirthdayContactsClose = () => {
        setBirthdayContactsDialogOpen(false);
    };

    const handleOpenUpdateForm = (contact) => {
        setSelectedContact(contact);
        setOpenUpdateForm(true);
    };

    const handleUpdate = () => {
        // Après la mise à jour d'un contact, on peut rafraîchir la liste si nécessaire
        handleBirthdayContactsOpen();
        setOpenUpdateForm(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <SMSHappyBirthday />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" color="initial">
                        Faites plaisir à vos clients ou membres de votre établissement pour leur anniversaire 🎉🥳 ! Activez l'option SMS d'anniversaire by BEFIANA pour envoyer automatiquement un message chaleureux à vos contacts le jour de leur anniversaire. <b>Assurez-vous simplement que la date de naissance de votre contact est indiquée dans son profil.</b>
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
                <Button variant="text" color="secondary" onClick={handleDialogOpen}>
                    {companyName ? companyName : "Entrer Nom de l'entreprise"}
                </Button>
                <FormControlLabel
                    control={
                        <Switch
                            checked={birthdaySMS}
                            onChange={handleToggle}
                            name="birthdaySMS"
                            color="primary"
                            disabled={loading}
                        />
                    }
                    label="Activer les SMS d'anniversaire"
                />
                <br />
                <Button variant="outlined" color="primary" onClick={handleTemplateDialogOpen} sx={{ mr: 2 }}>
                    Personnaliser le message d'anniversaire
                </Button>
                <Button variant="contained" color="primary" onClick={handleBirthdayContactsOpen} startIcon={<CakeIcon />}>
                    Voir les anniversaires à venir
                </Button>
            </Box>

            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle2" color="primary">
                <b>Aperçu du message :</b><br />
                {previewMessage}
            </Typography>

            {/* Dialog nom entreprise */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    <Button variant="text" color="primary" startIcon={<BusinessIcon />}>
                        Entrer Nom de l'entreprise
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{mb:1}}>Personnalisez le nom de votre entreprise pour les SMS d'anniversaire.</Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom de l'entreprise"
                        type="text"
                        fullWidth
                        value={newCompanyName}
                        onChange={handleCompanyNameChange}
                        inputProps={{ maxLength: maxCompanyNameLength }}
                        helperText={`${newCompanyName.length}/${maxCompanyNameLength} caractères`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleCompanyNameSave} color="primary">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog template SMS anniversaire */}
            <Dialog open={templateDialogOpen} onClose={handleTemplateDialogClose}>
                <DialogTitle>
                    <Button variant="text" color="primary" startIcon={<CakeIcon />}>
                        Personnaliser le message d'anniversaire
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        Personnalisez le message envoyé le jour de l'anniversaire. Champs dynamiques :
                    </Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                        <li><Typography variant="body2">*|FNAME|* : Prénom du contact</Typography></li>
                        <li><Typography variant="body2">*|COMPANY|* : Nom de l'entreprise</Typography></li>
                    </Box>
                    <Typography variant="caption" display="block" sx={{mb:1}}>
                        Raccourcis : *| ou %f pour *|FNAME|*, %c pour *|COMPANY|*.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Message d'anniversaire"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={newBirthdaySMSTemplate}
                        onChange={handleBirthdaySMSTemplateChange}
                        helperText={`${getMessageLength(newBirthdaySMSTemplate)}/320 caractères (${getSmsCount(getMessageLength(newBirthdaySMSTemplate))} SMS)`}
                        inputProps={{ maxLength: 320 }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleTemplateDialogClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleBirthdaySMSTemplateSave} color="primary">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog liste anniversaires */}
            <Dialog open={birthdayContactsDialogOpen} onClose={handleBirthdayContactsClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Button variant="text" color="primary" startIcon={<CakeIcon />}>
                        Anniversaires à venir
                    </Button>
                </DialogTitle>
                <DialogContent>
                    {loadingBirthdays ? (
                        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <>
                            {birthdayContacts.length === 0 ? (
                                <Typography variant="body2">Aucun contact avec une date d'anniversaire enregistrée.</Typography>
                            ) : (
                                <List>
                                    {birthdayContacts.map(contact => (
                                        <React.Fragment key={contact.id}>
                                            <ListItem
                                                secondaryAction={
                                                    <Tooltip title="Modifier la date de naissance">
                                                        <IconButton edge="end" onClick={() => handleOpenUpdateForm(contact)}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            >
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: '#F8BBD0' }}>
                                                        {contact.birthday ? <CakeIcon /> : <PersonIcon />}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={contact.first_name || 'Contact sans nom'}
                                                    secondary={`Anniversaire: ${contact.birthday || 'Non renseigné'} - Numéro: ${contact.phone_number}`}
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                                </List>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBirthdayContactsClose}>Fermer</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog mise à jour contact */}
            <Dialog open={openUpdateForm} onClose={() => setOpenUpdateForm(false)} fullWidth maxWidth="sm">
                <DialogTitle>Modifier les informations du contact</DialogTitle>
                <DialogContent>
                    {selectedContact && (
                        <ContactUpdateForm contact={selectedContact} onUpdate={handleUpdate} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateForm(false)}>Annuler</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BirthdaySMSToggle;
import React, { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, Box, Snackbar, Typography, Divider, IconButton } from '@mui/material';
import api from '../../services/api';

import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'; 

function AboutUpdateGender() {
    const [gender, setGender] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await api.put('/api/update-gender/', { gender });
            setSnackbarMessage('Genre mis à jour avec succès.');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Erreur lors de la mise à jour du genre.');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const navigate = useNavigate(); 
    const handleBack = () => {
        navigate(-1); 
    };

    useEffect(() => {
        api.get('api/user-info/')
            .then(response => {
                setGender(response.data.gender);
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') {console.error('Erreur de récupération du genre: ', error);}
                
            });
    }, []);

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <IconButton onClick={handleBack} sx={{ mb: 2 }} color='primary'>
                <ArrowBack /> {/* Icône de retour */}
            </IconButton>

            <Typography mb={2} variant="body1" color="initial">Nous utilisons votre genre pour personnaliser votre expérience avec les services Befiana, y compris la façon dont nous nous adressons à vous.</Typography>
            <Divider />
            <br />
    
            <FormControl component="fieldset">
                <FormLabel component="legend">Genre</FormLabel>
                <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                >
                    <FormControlLabel value="F" control={<Radio />} label="Femme" />
                    <FormControlLabel value="H" control={<Radio />} label="Homme" />
                    <FormControlLabel value="N" control={<Radio />} label="Non précisé" />
                </RadioGroup>
            </FormControl>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Mettre à jour
            </Button>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#006837',
                        marginBottom: '75px'
                    },
                }}
            />
        </Box>
    );
}

export default AboutUpdateGender;
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../features/auth/authSlice'; 

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Supprime les cookies ou les données de session
    Cookies.remove('befiana_tc');
    Cookies.remove('befiana_rc');
    Cookies.remove('befiana_id');

    // Réinitialise l'état d'authentification dans Redux
    dispatch(logoutSuccess());

    // Redirige vers la page de connexion ou la page d'accueil
    navigate('/login');
  }, [navigate, dispatch]);

  return null;
}

export default Logout;
import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; 
import api from '../../../../services/api';

function PaidInvoices() {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);

    dayjs.locale('fr');  

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/sms/purchases/paid/');
            setInvoices(response.data);
        } catch (error) {
            console.error('Failed to fetch paid invoices:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { field: 'purchase_date', headerName: 'Date d\'Achat', width: 200, valueGetter: ({ value }) => dayjs(value).format('DD MMMM YYYY à HH:mm') },
        { field: 'quantity', headerName: 'Quantité', type: 'number', width: 120 },
        { field: 'total_price', headerName: 'Prix Total', type: 'number', width: 130 },
        { field: 'payment_method', headerName: 'Méthode de Paiement', width: 180 },
        { field: 'transaction_ref', headerName: 'Référence de Transaction', width: 200 },
        {
            field: 'actions',
            headerName: 'Télécharger (Soon)',
            sortable: false,
            renderCell: (params) => (
                <Button
                    startIcon={<DownloadIcon />}
                    onClick={() => downloadInvoice(params.id)}
                    color="primary"
                    disabled
                >
                    Télécharger
                </Button>
            ),
            width: 180
        }
    ];

    const downloadInvoice = (invoiceId) => {
        console.log('Downloading invoice:', invoiceId);
        // Implémentez la logique de téléchargement ici
    };

    return (
        <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
                <Typography variant="h6" color="primary" m={1}>Historique de mes Achats</Typography>

            <Box sx={{ height: 400, width: '100%' }}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <DataGrid
                        rows={invoices}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        components={{
                            Toolbar: GridToolbarContainer,
                        }}
                        disableSelectionOnClick
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                )}
            </Box>
        </Paper>
    );
}

export default PaidInvoices;

import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    CircularProgress,
    Box,
    Grid,
    Badge,
    Tooltip,
    Avatar,
    Divider
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import api from '../../../../services/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { teal, orange, blue, green, red, purple, pink } from '@mui/material/colors';

const BeneficiaryStats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        api.get('/api/ibondacha/vouchers/beneficiary-stats/')
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération des données', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Typography variant="h6" color="error">
                    <ErrorOutlineIcon /> Erreur lors de la récupération des données.
                </Typography>
            </Box>
        );
    }

    const colors = [teal[500], orange[500], blue[500], green[500], red[500], purple[500], pink[500]];

    return (
        <Grid container spacing={3}>
            {data.map((beneficiary, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                    <Card elevation={1}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    sx={{ bgcolor: colors[index % colors.length], width: 56, height: 56 }}
                                >
                                    <BusinessIcon fontSize="large" />
                                </Avatar>
                            }
                            title={
                                <Typography variant="h6" fontWeight="bold">
                                    {beneficiary.beneficiary_name}
                                </Typography>
                            }
                            action={
                                <Tooltip title={`${beneficiary.used_voucher_count} iBondacha utilisés`} arrow>
                                    <Badge
                                        badgeContent={beneficiary.used_voucher_count}
                                        color="secondary"
                                        overlap="circular"
                                        max={9999999} 
                                    >
                                        <ReceiptIcon fontSize="large" color="action" />
                                    </Badge>
                                </Tooltip>
                            }
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <MonetizationOnIcon color="action" />
                                        <Typography variant="body1" color="textSecondary" sx={{ ml: 1 }}>
                                            Total iBondacha :
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color="primary" fontWeight="bold">
                                        {Number(beneficiary.total_vouchers).toLocaleString('fr-FR', {
                                            style: 'currency',
                                            currency: 'MGA',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center">
                                        <MonetizationOnIcon color="action" />
                                        <Typography variant="body1" color="textSecondary" sx={{ ml: 1 }}>
                                            iBondacha actifs :
                                        </Typography>
                                    </Box>
                                    <Typography variant="h5" color="primary" fontWeight="bold">
                                        {Number(beneficiary.total_active_vouchers).toLocaleString('fr-FR', {
                                            style: 'currency',
                                            currency: 'MGA',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center">
                                        <ShoppingCartIcon color="action" />
                                        <Typography variant="body1" color="textSecondary" sx={{ ml: 1 }}>
                                            Panier moyen :
                                        </Typography>
                                    </Box>
                                    <Typography variant="h6" color="textPrimary" fontWeight="bold">
                                        {Number(beneficiary.average_cart).toLocaleString('fr-FR', {
                                            style: 'currency',
                                            currency: 'MGA',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BeneficiaryStats;
import React, { useState, useEffect } from 'react';
import {
    TextField, Button, FormControl, Box, CircularProgress, Backdrop, Snackbar, Alert, Paper
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import api from '../../../../services/api';

const CreateVoucher = () => {
    const [voucherGroups, setVoucherGroups] = useState([]);
    const [formData, setFormData] = useState({ voucher_group: '', name_or_function: '', phone_number: '', amount: '' });
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        fetchVoucherGroups();
    }, []);

    const fetchVoucherGroups = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/ibondacha/voucher-groups-details/');
            setVoucherGroups(response.data);
        } catch (error) {
            console.error('Error fetching voucher groups:', error);
        }
        setLoading(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number' && value.length > 9) {
            return;
        }
        setFormData({ ...formData, [name]: value });
        validateForm({ ...formData, [name]: value });
    };

    const handleAutocompleteChange = (event, newValue) => {
        setFormData({ ...formData, voucher_group: newValue ? newValue.id : '' });
        validateForm({ ...formData, voucher_group: newValue ? newValue.id : '' });
    };

    const validateForm = (data) => {
        const { voucher_group, name_or_function, phone_number, amount } = data;
        const isFormValid = voucher_group && name_or_function && /^[1-9]\d{0,8}$/.test(phone_number) && amount > 0;
        setIsValid(isFormValid);
    };

    const sendSMS = async (phoneNumber) => {
        await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await api.post('/api/ibondacha/vouchers/', formData);
            setSnackbarMessage('iBondacha créé avec succès. Un SMS a été envoyé au propriétaire.');
            setSnackbarSeverity('success');
            setFormData({ voucher_group: '', name_or_function: '', phone_number: '', amount: '' });
            sendSMS(formData.phone_number);
            setIsValid(false);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Erreur lors de la création du iBondacha.';
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            console.error('Error creating voucher:', error);
        }
        setSnackbarOpen(true);
        setLoading(false);
    };

    return (
        <Box sx={{ p: 3, mx: 'auto' }}>
            <Paper elevation={1} sx={{ p: 3 }}>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Button variant="text" color="ib1" startIcon={<AddCircleOutlineIcon />}>
                    Créer un iBondacha
                </Button>
                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        options={voucherGroups}
                        getOptionLabel={(option) => `${option.name} (${option.beneficiary_name})`}
                        onChange={handleAutocompleteChange}
                        renderInput={(params) => <TextField {...params} label="Groupe de iBondacha" />}
                    />
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    name="name_or_function"
                    label="Nom ou Fonction"
                    value={formData.name_or_function}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="phone_number"
                    label="Numéro de Téléphone"
                    value={formData.phone_number}
                    onChange={handleChange}
                    helperText="9 caractères maximum, sans zéro au début"
                    inputProps={{ maxLength: 9 }}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="amount"
                    label="Montant"
                    type="number"
                    value={formData.amount}
                    onChange={handleChange}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="ib2"
                    onClick={handleSubmit}
                    disabled={!isValid}
                    sx={{ mt: 2 }}
                >
                    Créer
                </Button>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Paper>
        </Box>
    );
};

export default CreateVoucher;
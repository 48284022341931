import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { store } from '../store';
import { refreshAccessToken } from '../features/auth/authSlice';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

function isTokenExpired(token) {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; 
    return decodedToken.exp < currentTime;
}

api.interceptors.request.use(
    async (config) => {
        const accessToken = Cookies.get('befiana_tc');
        const refreshToken = Cookies.get('befiana_rc');

        if (isTokenExpired(accessToken) && refreshToken) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/token/refresh/`, { refresh: refreshToken });
                if (response.status === 200) {
                    Cookies.set('befiana_tc', response.data.access, { expires: 1 / 24, sameSite: 'Lax' });
                    store.dispatch(refreshAccessToken({
                        accessToken: response.data.access,
                    }));
                    config.headers['Authorization'] = `Bearer ${response.data.access}`;
                }
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Token refresh error:', error);
                }
            }
        } else if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
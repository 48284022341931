import React from 'react';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { CloudDownload as CloudDownloadIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { AccessTime, ContactPhone, Update, VerifiedUser, PhoneAndroid, FormatAlignJustify, ImportExport, QuestionAnswer } from '@mui/icons-material';

function ContactsDownloadTemplate() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://download.befiana.cloud/template/Contacts_Example_Befiana.xlsx'; 
    link.setAttribute('download', 'Contacts_Example_Befiana.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const sections = [
    { icon: <ContactPhone />, title: "Comment remplir le fichier modèle", detail: "Assurez-vous de remplir le fichier selon le modèle fourni, en incluant tous les contacts nécessaires avec leurs informations complètes. Tous les autres champs sont optionnels mais recommandés pour une gestion efficace des contacts." },
    { icon: <QuestionAnswer />, title: "Importance de la date d'anniversaire", detail: "La date d'anniversaire est facultative mais recommandée pour automatiser l'envoi de messages lors des anniversaires et pour mieux comprendre votre clientèle." },
    { icon: <PhoneAndroid />, title: "Format des numéros de téléphone", detail: "Les numéros stockés sur notre plateforme doivent commencer directement par 32, 33, 34, 37 ou 38, sans le zéro initial. À noter que notre système formate automatiquement les numéros en supprimant les espaces, le préfixe +261 ou le zéro initial, si nécessaire. Cependant, pour une performance optimale, notamment avec un grand volume de contacts, il est recommandé de fournir les numéros déjà formatés en commençant directement par 3 et sans espace." },
    { icon: <ImportExport />, title: "Gestion des doublons", detail: "Les doublons sont automatiquement filtrés lors de l'importation; seule la première occurrence est conservée." },
    { icon: <AccessTime />, title: "Format des dates d'anniversaire", detail: "Les dates d'anniversaire doivent être saisies au format YYYY-MM-DD pour garantir une compatibilité." },
    { icon: <Update />, title: "Mise à jour des contacts existants", detail: "Le système vérifie et met à jour les informations des contacts existants sans créer de doublons." },
    { icon: <VerifiedUser />, title: "Importation multiple et mise à jour", detail: "Vous pouvez mettre à jour plusieurs contacts en utilisant un seul fichier Excel. Les informations à jour seront appliquées et les nouveaux contacts ajoutés." },
    { icon: <FormatAlignJustify />, title: "Champs requis dans le fichier", detail: "Le numéro de téléphone est le seul champ obligatoire. Tous les autres champs sont optionnels mais recommandés pour une gestion efficace des contacts." }
  ];

  return (
    <Box sx={{ textAlign: 'left', p: 2 }}>
      <Typography variant="h6" gutterBottom textAlign="center">
        Guide pratique pour l'utilisation du modèle Excel
      </Typography>
      <Button sx={{m:1}} variant="contained" color="secondary" startIcon={<CloudDownloadIcon />} onClick={handleDownload}>
        Télécharger le Modèle .XLSX
      </Button>

      {sections.map((section, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="div"><Box sx={{ display: 'flex', alignItems: 'left', gap: 1 }}>{section.icon}{section.title}</Box></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div">{section.detail}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      
    </Box>
  );
}

export default ContactsDownloadTemplate;
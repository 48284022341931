import React, { useState } from 'react';
import { Box, Button, Snackbar, Typography, CircularProgress  } from '@mui/material';
import api from '../../services/api';  

export default function EmailVerificationAlert() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleResendEmail = async () => {
        setIsLoading(true);
        try {
            const response = await api.post('/api/resend-verification-email/');
            if (response.status === 200) {
                setSnackbarMessage('Email de vérification renvoyé avec succès. Veuillez vérifier votre boîte mail.');
                setSnackbarOpen(true);
            } else {
                throw new Error('Failed to resend verification email');
            }
        } catch (error) {
            setSnackbarMessage("Erreur lors du renvoi de l'email de vérification.");
            setSnackbarOpen(true);
        } 
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ my: 2, p: 2, bgcolor: 'background.paper', border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Vérification de l'adresse e-mail requise
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Votre compte doit être vérifié pour continuer à utiliser nos services. Veuillez vérifier votre e-mail pour le lien de vérification.
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleResendEmail}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}
            >
                Renvoyer l'email de vérification
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: "#FF8C00",
                        marginBottom: '75px'
                    },
                }}
            />
        </Box>
    );
}
import React from 'react';
import { Box, Toolbar, Typography, Paper} from '@mui/material';
import Divider from '@mui/material/Divider';
import SMSAppBarComponent from './components/AppBar';
import SMSDrawerBarMD from './drawer/DrawerBarMD';
import SMSDrawerBarandTabsXS from './drawer/DrawerBarXS';
import APIKeyManager from './components/APIKeyManager';
import GuideCard from './components/GuideCard';
import PostmanRunButton from './components/PostmanRunButton';
import GuideCardDev from './components/GuideCardDev';

function SMSDeveloper() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SMSAppBarComponent />
            <SMSDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>Befiana for Developer</Typography>
                <Divider>SMS by BEFIANA</Divider>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 2}}>
                    <APIKeyManager />
                </Paper>

                <PostmanRunButton />

                <GuideCardDev />

                <SMSDrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default SMSDeveloper;
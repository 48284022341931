import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import Tongasoa from './pages/Tongasoa';
import PasswordReset from './pages/PasswordReset';
import EmailVerification from './pages/EmailVerification';
import Home from './pages/Home';
import PrivateRoute from './components/PrivateRoute'; 
import Account from './pages/Account';
import Setting from './pages/Setting'; 
import Subscription from './pages/Subscription';
import Privacy from './pages/Privacy';
import Help from './pages/Help';
import History from './pages/History';
import Notification from './pages/Notification';
import AppsLoading from './pages/AppsLoading';
import Logout from './pages/Logout';
import PasswordResetValidate from './pages/PasswordResetValidate';
import MyAccountName from './pages/MyAccount/Name';
import MyAccountBirthday from './pages/MyAccount/Birthday';
import MyAccountUsername from './pages/MyAccount/Username';
import MyAccountCoord from './pages/MyAccount/Coord';
import MyAccountCoordDetails from './pages/MyAccount/CoordDetails';
import MyAccountGender from './pages/MyAccount/Gender';
import MyAccountPhone from './pages/MyAccount/Phone';
import MyAccountEmail from './pages/MyAccount/Email';
import MyAccountEmailVerify from './pages/MyAccount/EmailVerify';
import MyAccountProfilPicture from './pages/MyAccount/ProfilPicture';
import TermsOfService from './pages/TermsOfService';
import Policy from './pages/Policy';
import Cookies from './pages/Cookies';
import SMSsend from './pages/App/SmsKo/Send';
import SMSStatistic from './pages/App/SmsKo/Statistics';
import SMSaddContact from './pages/App/SmsKo/AddContact';
import SMSimportContacts from './pages/App/SmsKo/ImportContacts';
import SMSContacts from './pages/App/SmsKo/Contacts';
import SMSSetting from './pages/App/SmsKo/Setting';
import SMSContactGroup from './pages/App/SmsKo/ContactGroups';
import SMSShop from './pages/App/SmsKo/Shop';
import SMSall from './pages/App/SmsKo/SMSall';
import SMSCampaign from './pages/App/SmsKo/Campaign';
import IBStatistic from './pages/App/iBondacha/Dashboard';
import IBStores from './pages/App/iBondacha/Stores';
import IBBusiness from './pages/App/iBondacha/Business';
import IBBoxAdmin from './pages/App/iBondacha/BoxAdmin';
import IBVoucher from './pages/App/iBondacha/Voucher';
import IBSetting from './pages/App/iBondacha/setting';
import IBVoucherAll from './pages/App/iBondacha/AllVouchers';
import IBCashBox from './pages/App/iBondacha/CashBox';
import CashierLogin from './pages/App/iBondacha/CashierLogin';
import IBCashBoxMobile from './pages/App/iBondacha/CashBoxMobile';
import IBCompta from './pages/App/iBondacha/Compta';
import SMSDeveloper from './pages/App/SmsKo/Developer';
import CatTinder from './pages/App/tiaSaka/CatTinder';

import SMSPeriodicReminders from './pages/App/SmsKo/SMSPeriodicReminders';
import SMSCountdownReminders from './pages/App/SmsKo/SMSCountdownReminders';
import SMSReminders from './pages/App/SmsKo/SMSReminders';


const theme = createTheme({
  palette: {
    primary: {
      main: '#006837', 
    },
    secondary: {
      main: '#FF8C00',
    },
    error: {
      main: '#D32F2F', 
    },
    warning: {
      main: '#FFA726', 
    },
    info: {
      main: '#1976D2', 
    },
    success: {
      main: '#388E3C', 
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF', 
    },
    text: {
      primary: '#212121', 
      ib1: '#212121', 
      secondary: '#757575', 
      disabled: '#BDBDBD', 
    },
    ib2: {
      main: '#e84eb2', 
    },
    ib1: {
      main: '#45aff0', 
    },
  },
  typography: {
    fontFamily: '"Ruda", sans-serif', 
    befianaLora: '"Lora", serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <HelmetProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/tongasoa" element={<Tongasoa />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/password-reset/:uidb64/:token" element={<PasswordResetValidate />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />

        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/cookies" element={<Cookies />} />

        <Route path="/caisse" element={<CashierLogin />} />
        <Route path="/App/iBondacha/cash" element={<IBCashBox />} />
        <Route path="/App/iBondacha/cash-m" element={<IBCashBoxMobile />} />

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/help" element={<Help />} />
          <Route path="/history" element={<History />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/future" element={<AppsLoading />} />
          <Route path="/myAccount/name" element={<MyAccountName />} />
          <Route path="/myAccount/birthday" element={<MyAccountBirthday />} />
          <Route path="/myAccount/username" element={<MyAccountUsername />} />
          <Route path="/myAccount/gender" element={<MyAccountGender />} />
          <Route path="/myAccount/phone" element={<MyAccountPhone />} />
          <Route path="/myAccount/profil-picture" element={<MyAccountProfilPicture />} />
          <Route path="/myAccount/add-new-email/" element={<MyAccountEmail />} />
          <Route path="/myAccount/add-adress" element={<MyAccountCoord />} />
          <Route path="/myAccount/adresses/:id" element={<MyAccountCoordDetails />} />
          <Route path="/myAccount/verify-new-email/:token" element={<MyAccountEmailVerify />} />


          <Route path="/App/SmsKo/home" element={<SMSStatistic />} />
          <Route path="/App/SmsKo/send" element={<SMSsend />} />
          <Route path="/App/SmsKo/add-contact" element={<SMSaddContact />} />
          <Route path="/App/SmsKo/import-contacts" element={<SMSimportContacts />} />
          <Route path="/App/SmsKo/contacts" element={<SMSContacts />} />
          <Route path="/App/SmsKo/setting" element={<SMSSetting />} />
          <Route path="/App/SmsKo/contact-groups" element={<SMSContactGroup />} />
          <Route path="/App/SmsKo/shop" element={<SMSShop />} />
          <Route path="/App/SmsKo/sent" element={<SMSall />} />
          <Route path="/App/SmsKo/campaign" element={<SMSCampaign />} />
          <Route path="/App/SmsKo/developer" element={<SMSDeveloper />} />

          <Route path="/App/SmsKo/reminders" element={<SMSReminders />} />
          <Route path="/App/SmsKo/periodic-reminders" element={<SMSPeriodicReminders />} />
          <Route path="/App/SmsKo/countdown-reminders" element={<SMSCountdownReminders />} />


          <Route path="/App/iBondacha" element={<IBVoucherAll />} />
          <Route path="/App/iBondacha/v2" element={<IBStatistic />} />
          <Route path="/App/iBondacha/stores" element={<IBStores />} />
          <Route path="/App/iBondacha/voucher" element={<IBVoucher />} />
          <Route path="/App/iBondacha/voucher-all" element={<IBVoucherAll />} />
          <Route path="/App/iBondacha/business" element={<IBBusiness />} />
          <Route path="/App/iBondacha/box-v" element={<IBBoxAdmin />} />
          <Route path="/App/iBondacha/setting" element={<IBSetting />} />
          <Route path="/App/iBondacha/compta" element={<IBCompta />} />


          <Route path="/App/tiaSaka" element={<CatTinder />} />

        </Route>
      </Routes>
    </Router>
    </HelmetProvider>
  </ThemeProvider>
  );
}

export default App;

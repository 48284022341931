import React, { useState, useEffect } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, CircularProgress,
  Backdrop, Paper
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import api from '../../../../services/api';

const CashRegisterErrors = () => {
  const [errors, setErrors] = useState([]);
  const [selectedError, setSelectedError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchErrors();
  }, []);

  const fetchErrors = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/ibondacha/box/cash-register-errors/');
      const errorsData = response.data.filter(voucher => voucher.voucher_status === 3);
      const detailedErrorsData = await Promise.all(errorsData.map(async (error) => {
        const voucherResponse = await api.get(`/api/ibondacha/voucher-detail/${error.voucher_code}/`);
        const voucherData = voucherResponse.data;
        return { ...error, ...voucherData };
      }));
      setErrors(detailedErrorsData);
    } catch (error) {
      console.error('Error fetching errors:', error);
    }
    setLoading(false);
  };

  const handleActionClick = (error, action) => {
    setSelectedError(error);
    setDialogAction(action);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const sendSMS = async (phoneNumber) => {
    await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
  };

  const handleDialogConfirm = async () => {
    setLoading(true);
    try {
      await api.post('/api/ibondacha/box/validate-error/v2/', {
        voucher_code: selectedError.voucher_code,
        action: dialogAction
      });

      setSnackbarMessage(`Erreur de caisse ${dialogAction === 'validate' ? 'validée' : 'refusée'} avec succès.`);
      setSnackbarSeverity('success');

      // Envoyer le SMS avec le numéro de téléphone récupéré
      sendSMS(selectedError.phone_number);

      fetchErrors();
    } catch (error) {
      setSnackbarMessage('Erreur lors du traitement de l\'action.');
      setSnackbarSeverity('error');
    }
    setLoading(false);
    setSnackbarOpen(true);
    setDialogOpen(false);
  };

  const columns = [
    { field: 'voucher_code', headerName: 'Transaction', width: 150 },
    { field: 'name_or_function', headerName: 'Nom/Fonction', width: 250 },
    { field: 'phone_number', headerName: 'Téléphone', width: 150 },
    { field: 'reported_amount', headerName: 'Montant Signalé', width: 150 },
    { field: 'corrected_amount', headerName: 'Montant Exact', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<CheckIcon />}
            onClick={() => handleActionClick(params.row, 'validate')}
            sx={{ mr: 1 }}
          >
            Valider
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<CloseIcon />}
            onClick={() => handleActionClick(params.row, 'reject')}
          >
            Refuser
          </Button>
        </Box>
      )
    }
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <Backdrop open={loading} style={{ zIndex: 999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Button variant="text" color="ib2" sx={{ fontSize: '25px' }} startIcon={<InventoryOutlinedIcon />}>
          Erreurs de Caisse à Valider
        </Button>
        <DataGrid
          rows={errors}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>
          <Button variant="text" color="ib2" startIcon={<InventoryOutlinedIcon />}>
            {dialogAction === 'validate' ? 'Valider' : 'Refuser'} l'Erreur de Caisse
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir {dialogAction === 'validate' ? 'valider' : 'refuser'} cette erreur de caisse pour le bon {selectedError?.voucher_code} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="ib1" disableElevation>
            Annuler
          </Button>
          <Button onClick={handleDialogConfirm} variant='contained' disableElevation color="ib1">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CashRegisterErrors;

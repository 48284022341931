import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../../../services/api'; 

function ContactsInList({ listId, handleClose }) {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Utilisez useCallback pour créer une version mémorisée de fetchContacts
    const fetchContacts = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get(`/api/smsko/contacts/lists/${listId}/contacts/`);
            setContacts(response.data);
        } catch (error) {
            console.error('Failed to fetch contacts', error);
            setSnackbarMessage('Failed to fetch contacts');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    }, [listId]);  // Ajoutez listId comme dépendance

    useEffect(() => {
        fetchContacts();
    }, [fetchContacts]);  // Ajoutez fetchContacts comme dépendance pour respecter les recommandations de ESLint

    const handleDelete = async (contactId) => {
        try {
            const response = await api.delete(`/api/smsko/contacts/lists/${listId}/contacts/${contactId}/remove/`);
            if (response.status === 204) {
                setContacts(contacts.filter(contact => contact.id !== contactId));
                setSnackbarMessage('Contact supprimé avec succès de la liste.');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage('Erreur lors de la suppression du contact');
            setSnackbarOpen(true);
        }
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const filteredContacts = contacts.filter(contact => {
        return contact.phone_number.toString().includes(searchText) || (contact.first_name && contact.first_name.toLowerCase().includes(searchText));
    });

    const columns = [
        {
            field: 'Supprimer',
            headerName: 'Supprimer',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <IconButton onClick={() => handleDelete(params.id)} color="error">
                    <DeleteIcon />
                </IconButton>
            ),
        },
        { field: 'phone_number', headerName: 'Numéro Téléphone', width: 200 },
        { field: 'first_name', headerName: 'Prénom', width: 200 },
        { field: 'position', headerName: 'Position', width: 200 },
        { field: 'company', headerName: 'Entreprise', width: 200 },
    ];

    return (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>{`Contacts dans la liste Réf #${listId}`}</DialogTitle>
            <DialogContent>
                <TextField
                    value={searchText}
                    onChange={handleSearchChange}
                    placeholder="Chercher des contacts..."
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={filteredContacts}
                        columns={columns}
                        pageSize={50}
                        rowsPerPageOptions={[50, 100, 250]}
                        loading={loading}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </DialogContent>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default ContactsInList;

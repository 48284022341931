import React, { useState, useEffect } from 'react';
import {
    Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel,
    Checkbox, Button, Snackbar, Alert, Grid, Typography, Paper, RadioGroup, Radio
} from '@mui/material';
import api from '../../../../services/api';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import 'dayjs/locale/fr'; 
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const isGSMCharacter = (char) => {
    const GSM_03_38_REGEX = /^[A-Za-z0-9 \r\n@£$¥èéêëùûüîïâäàôöçÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€°œŒ«»‘’“”‹›±×÷√∞∑∏π∂∆≈≠≤≥∫∬∮°µ‰∞]*$/;
    const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
    return GSM_03_38_REGEX.test(char) || emojiRegex.test(char);
};

const getMessageLength = (message) => {
    const adjustedMessage = message.replace(/\*\|FNAME\|\*/g, ' '.repeat(33));
    return adjustedMessage.split('').reduce((count, char) => {
        const doubleCountChars = ['ê', 'ë', 'î', 'ï', 'â', 'ä', 'ô', 'ö', 'û', 'ü', 'œ', 'Œ'];
        const emojiRegex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/u;
        if (emojiRegex.test(char)) {
            return count + 3;
        }
        return count + (doubleCountChars.includes(char) ? 2 : 1);
    }, 0);
};

const getSmsCount = (messageLength) => {
    if (messageLength <= 160) {
        return 1;
    } else if (messageLength <= 320) {
        return 2;
    }
    return Math.ceil(messageLength / 160);
};

function CreatePeriodicReminderForm({ onSuccess }) {
    const [message, setMessage] = useState('');
    const [contactGroupId, setContactGroupId] = useState('');
    const [phoneNumbers, setPhoneNumbers] = useState('');
    const [startDate, setStartDate] = useState(dayjs());
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [repeatUnit, setRepeatUnit] = useState('days');
    const [specificDaysOfWeek, setSpecificDaysOfWeek] = useState([]);
    const [endOption, setEndOption] = useState('never');
    const [endDate, setEndDate] = useState(null);
    const [maxOccurrences, setMaxOccurrences] = useState('');
    const [contactGroups, setContactGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    const processPhoneNumbers = (input) => {
        let value = input.replace(/\s+/g, '');
        value = value.replace(/[^0-9,]/g, '');
        value = value.replace(/,+/g, ',');
        let newValue = '';
        let digitCount = 0;
        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            if (char >= '0' && char <= '9') {
                if (digitCount === 0 && char !== '3') {
                    continue;
                }
                newValue += char;
                digitCount++;
                if (digitCount === 9 && i !== value.length - 1) {
                    newValue += ',';
                    digitCount = 0;
                }
            }
        }
        if (newValue.endsWith(',')) {
            newValue = newValue.slice(0, -1);
        }
        let numbers = newValue.split(',');
        numbers = numbers.map(number => {
            if (number.length > 1) {
                return number.charAt(0) + number.slice(1).replace(/^0+/, '');
            } else {
                return number;
            }
        });
        return numbers.join(',');
    };      

    const handleMessageChange = (event) => {
        let newMessage = event.target.value;

        if (newMessage.endsWith('*|') && !newMessage.endsWith('*|FNAME|*')) {
            newMessage = newMessage.slice(0, -2) + '*|FNAME|*';
        }

        if (newMessage.endsWith('%f') && !newMessage.endsWith('*|FNAME|*')) {
            newMessage = newMessage.slice(0, -2) + '*|FNAME|*';
        }

        if (newMessage.split('').every(isGSMCharacter) && getMessageLength(newMessage) <= 320) {
            setMessage(newMessage);
        }
    };

    const daysOfWeekOptions = [
        { label: 'Lundi', value: 'Monday' },
        { label: 'Mardi', value: 'Tuesday' },
        { label: 'Mercredi', value: 'Wednesday' },
        { label: 'Jeudi', value: 'Thursday' },
        { label: 'Vendredi', value: 'Friday' },
        { label: 'Samedi', value: 'Saturday' },
        { label: 'Dimanche', value: 'Sunday' },
    ];
    

    useEffect(() => {
        // Fetch contact groups
        api.get('/api/smsko/contacts/lists/')
            .then(res => setContactGroups(res.data))
            .catch(err => console.error('Error fetching contact groups', err));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {

            const processedPhoneNumbers = processPhoneNumbers(phoneNumbers);

            const payload = {
                message,
                contact_group_id: contactGroupId || null,
                phone_numbers: processedPhoneNumbers  || null,
                start_date: startDate.utc().format('YYYY-MM-DD HH:mm:ss'),
                repeat_every: repeatEvery,
                repeat_unit: repeatUnit,
                specific_days_of_week: specificDaysOfWeek.length > 0 ? specificDaysOfWeek.join(',') : '', 
                end_option: endOption,
                end_date: endDate ? endDate.utc().format('YYYY-MM-DD') : null,
                max_occurrences: maxOccurrences || null,
            };

            await api.post('/api/smsko/reminders/periodic/create/', payload);
            setSnackbar({ open: true, message: 'Rappel périodique créé avec succès!', severity: 'success' });
            setMessage('');
            setContactGroupId('');
            setPhoneNumbers('');
            setStartDate(dayjs());
            setRepeatEvery(1);
            setRepeatUnit('days');
            setSpecificDaysOfWeek([]);
            setEndOption('never');
            setEndDate(null);
            setMaxOccurrences('');
            if (onSuccess) onSuccess();
        } catch (error) {
            console.error('Error creating periodic reminder', error);
            setSnackbar({ open: true, message: 'Erreur lors de la création du rappel.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
                Créer un Rappel Périodique
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Message"
                        value={message}
                        onChange={handleMessageChange}
                        multiline
                        rows={4}
                        helperText={`${getMessageLength(message)}/320 caractères (${getSmsCount(getMessageLength(message))} SMS) | Utilisez *|FNAME|* pour insérer le prénom dynamique. Raccourcis : *| ou %f.`}
                        inputProps={{ maxLength: 320 }}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="contact-group-select-label">Groupe de Contacts</InputLabel>
                        <Select
                            labelId="contact-group-select-label"
                            id="contact-group-select"
                            value={contactGroupId}
                            label="Groupe de Contacts"
                            onChange={(e) => setContactGroupId(e.target.value)}
                        >
                            {contactGroups.map((group) => (
                                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Numéros de téléphone (séparés par des virgules sans zéros)"
                        value={phoneNumbers}
                        onChange={(e) => setPhoneNumbers(processPhoneNumbers(e.target.value))}
                        sx={{ mb: 2 }}
                        helperText="Entrez des numéros commençant par '3'; une virgule sera automatiquement ajoutée après chaque numéro de 9 chiffres."
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <DateTimePicker
                        label="Date de début"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                    />
                    <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Répéter tous les"
                                type="number"
                                value={repeatEvery}
                                onChange={(e) => setRepeatEvery(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="repeat-unit-select-label">Unité</InputLabel>
                                <Select
                                    labelId="repeat-unit-select-label"
                                    id="repeat-unit-select"
                                    value={repeatUnit}
                                    label="Unité"
                                    onChange={(e) => setRepeatUnit(e.target.value)}
                                >
                                    <MenuItem value="days">Jours</MenuItem>
                                    <MenuItem value="weeks">Semaines</MenuItem>
                                    <MenuItem value="months">Mois</MenuItem>
                                    <MenuItem value="years">Années</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {repeatUnit === 'weeks' && (
                        <FormControl component="fieldset" sx={{ mb: 2 }}>
                            <Typography variant="subtitle1">Jours spécifiques de la semaine :</Typography>
                            <Box>
                                {daysOfWeekOptions.map((day) => (
                                    <FormControlLabel
                                        key={day.value}
                                        control={
                                            <Checkbox
                                                checked={specificDaysOfWeek.includes(day.value)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSpecificDaysOfWeek([...specificDaysOfWeek, day.value]);
                                                    } else {
                                                        setSpecificDaysOfWeek(specificDaysOfWeek.filter(d => d !== day.value));
                                                    }
                                                }}
                                            />
                                        }
                                        label={day.label}
                                    />
                                ))}
                            </Box>
                        </FormControl>
                    )}
                    <FormControl component="fieldset" sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Option de fin :</Typography>
                        <RadioGroup
                            value={endOption}
                            onChange={(e) => setEndOption(e.target.value)}
                        >
                            <FormControlLabel
                                value="never"
                                control={<Radio />}
                                label="Jamais"
                            />
                            <FormControlLabel
                                value="date"
                                control={<Radio />}
                                label="À une date spécifique"
                            />
                            {endOption === 'date' && (
                                <DateTimePicker
                                    label="Date de fin"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
                                />
                            )}
                            <FormControlLabel
                                value="occurrences"
                                control={<Radio />}
                                label="Après un nombre d'occurrences"
                            />
                            {endOption === 'occurrences' && (
                                <TextField
                                    fullWidth
                                    label="Nombre d'occurrences"
                                    type="number"
                                    value={maxOccurrences}
                                    onChange={(e) => setMaxOccurrences(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <Button variant="contained" color="primary" type="submit" disabled={loading}>
                        {loading ? 'En cours...' : 'Créer le Rappel'}
                    </Button>
                </Box>
            </LocalizationProvider>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

export default CreatePeriodicReminderForm;
import React from 'react';
import { Box, Typography} from '@mui/material';
import backgroundImage from '../images/design/nantenaina-andrianjaka.jpg';

const TheBefiana = ({TheBefiana}) => {
  return (
    <Box sx={{
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'fixed',
        width: '56%',
    }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', height: '100%' }}>
            <Box sx={{ alignSelf: 'flex-end' }}>
                <Typography m={1} variant="subtitle2" color="white">(c) Antsirabe Nord, SAVA, Madagascar | Photo par Nantenaina Andrianjaka </Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default TheBefiana
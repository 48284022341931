import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import api from '../../../../services/api';  
import { Helmet } from 'react-helmet-async';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DeleteAllContacts() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        setOpen(false);
        setLoading(true);
        try {
            await api.delete('/api/smsko/contacts/delete-all/');
            setSnackbarMessage('Tous les contacts ont été supprimés avec succès.');
            setOpenSnackbar(true);
        } catch (error) {
            setSnackbarMessage('Échec de la suppression des contacts.');
            setOpenSnackbar(true);
            // console.error("Error deleting contacts:", error);
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <div>
            <Button variant="contained" color="error" onClick={handleClickOpen}>
                Supprimer tous mes contacts
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmer la suppression de tous les contacts"}
                    <Helmet>
                        <title>Supprimer tous mes contacts - BEFIANA Connect</title>
                    </Helmet>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    La suppression de tous les contacts est irréversible et supprimera tous vos contacts enregistrés.  Êtes-vous sur de vouloir continuer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Je confirme
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default DeleteAllContacts;
import React, { useEffect, useState } from 'react';
import { List, ListItemText, Paper, CircularProgress, Grid, Typography, Divider, ListItemButton, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';

function ListeAdresses() {
    const [adresses, setAdresses] = useState(null);
    const [billPerso, setBillPerso] = useState(null);

    useEffect(() => {
        const fetchAdresses = async () => {
            try {
                const response = await api.get('/api/addresses/');
                setAdresses(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des adresses:", error);
            }
        };

        const fullName = async () => {
            try {
                const responseFull = await api.get('/api/user-info/');
                setBillPerso(responseFull.data.first_name + " " + responseFull.data.last_name)
            } catch (error) {
                console.error("Erreur lors de la récupération des infos d'utilisateur:", error);
            }
        }

        fetchAdresses();
        fullName();
    }, []);

    if (!adresses) {
        return <CircularProgress />;
    }

    return (
        <Paper elevation={1} sx={{ padding: 2, margin: 'auto', marginBottom: 8, marginTop: 2, maxWidth: 899, borderRadius: '17px' }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6" color="primary">
                        Mes Adresses
                    </Typography>
                </Grid>
                <Grid item>
                    <Link to="/myAccount/add-adress" style={{ textDecoration: 'none' }}>
                        <AddHomeWorkIcon color="primary" />
                    </Link>
                </Grid>
            </Grid>
            <Divider />

            <List>
                {adresses.map((adresse) => (
                    // Utiliser un Fragment React ici pour appliquer la clé
                    <React.Fragment key={adresse.id}>
                        <ListItemButton component={Link} to={`/myAccount/adresses/${adresse.id}`}>
                            <ListItemText primary={`${adresse.company_name ? adresse.company_name : billPerso} - ${adresse.address_line_1}, ${adresse.city} ${adresse.postal_code}`} />
                        </ListItemButton>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>

            <Button variant="outlined" color="primary" fullWidth component={Link} to="/myAccount/add-adress">
                Créer une nouvelle adresse
            </Button>
        </Paper>
    );
}
export default ListeAdresses;
import React from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SmsIcon from '@mui/icons-material/Sms';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloudIcon from '@mui/icons-material/Cloud';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PetsIcon from '@mui/icons-material/Pets';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StoreIcon from '@mui/icons-material/Store';

const AppsSMSKo = { "release": false, "color": "#f7ab00", "name": "SMS", "icon": <SmsIcon />, "to": "/App/SmsKo/home" }
const AppsNamako = { "release": true, "color": "#d21114", "name": "Namako", "icon": <FavoriteIcon />, "to": "/future" }
const AppsKotoAI = { "release": true, "color": "#159c7c", "name": "Koto-AI", "icon": <SmartToyIcon />, "to": "/future" }
const AppsPayPoketra = { "release": true, "color": "#002a86", "name": "PayPoketra", "icon": <AccountBalanceWalletIcon />, "to": "/future" }
const AppsBefianaDrive = { "release": true, "color": "#27a2e3", "name": "Befiana Drive", "icon": <CloudIcon />, "to": "/future" }
const AppsPets = { "release": false, "color": "#ffadde", "name": "tiaSaka", "icon": <PetsIcon />, "to": "/App/tiaSaka" }
const AppsIB = { "release": false, "color": "#45aff0", "name": "iBondacha", "icon": <ReceiptLongIcon />, "to": "/App/iBondacha/v2" }
const AppsIBCash = { "release": false, "color": "#45aff0", "name": "Cash", "icon": <StoreIcon />, "to": "/App/iBondacha/cash" }

const apps = [
    AppsSMSKo,
    // AppsIB,
    // AppsIBCash,
    
    AppsNamako,
    AppsKotoAI,
    AppsPayPoketra,
    AppsBefianaDrive,
    AppsPets
];

export default apps;
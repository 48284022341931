import React, { useState, useEffect } from 'react';
import { Box, Toolbar, Typography, Divider, Button, TextField, Card, CardContent, Paper, Stack } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';

import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { Helmet } from 'react-helmet-async';

import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../services/api';


function MyAccountUsername() {
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [back, setBack] = useState("Annuler");
    const [open, setOpen] = React.useState(false);
    const [colorError, setColorError] = useState("#006837");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        api.get('api/user-info/')
            .then(response => {
                setUsername(response.data.username);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nom d\'utilisateur:', error);
            });
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            await api.put('api/update-username/', { username });
            setOpen(true);
            setMessage('Votre nom d\'utilisateur a été mis à jour avec succès.');
            setIsLoading(false)
            setBack("Retour")
        } catch (error) {
            setOpen(true);
            if (error.response && error.response.data) {
                setColorError("#D32F2F");
                setMessage(error.response.data.username || 'Erreur lors de la mise à jour du nom d\'utilisateur.');
            } else {
                setColorError("#D32F2F");
                setMessage('Erreur lors de la mise à jour du nom d\'utilisateur.');
            }
            setIsLoading(false)
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Nom d'utilisateur - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Divider>Befiana Connect</Divider>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                    Informations sur vous et vos préférences dans les services Befiana
                </Typography>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899, borderRadius: '17px' }}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <form onSubmit={handleSubmit}>

                                <TextField
                                    fullWidth
                                    label="Nom d'utilisateur"
                                    name="username" value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                    sx={{ my: 2 }}
                                    inputProps={{ maxLength: 31 }}
                                    helperText="Votre nom d'utilisateur sera utilisé dans nos différents services. Vous pouvez vous identifier ou vous connecter avec cela au lieu d'utiliser votre e-mail." />

                                <Stack direction="row" spacing={2} justifyContent="space-between">

                                    <Button
                                        variant="outlined"
                                        sx={{ mt: 2, mb: 2, p: 1 }}
                                        startIcon={<ArrowBackIcon />}
                                        size="small"
                                        component={Link}
                                        to="/account"
                                    >
                                        {back}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{ mt: 2, mb: 2, p: 1 }}
                                        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}
                                        size="small"
                                    >
                                        Mettre à jour
                                    </Button>
                                </Stack>

                            </form>

                            <div>
                                <Snackbar
                                    open={open}
                                    autoHideDuration={4000}
                                    onClose={handleClose}
                                    message={message}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            backgroundColor: colorError,
                                            marginBottom: '75px'
                                        },
                                    }}
                                />
                            </div>


                        </CardContent>
                    </Card>

                </Paper>

                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default MyAccountUsername;
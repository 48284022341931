import React, { useState, useEffect } from 'react';
import { Checkbox, Button, TextField, List, ListItem, ListItemText, ListItemButton, CircularProgress, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Badge, Typography } from '@mui/material';
import api from '../../../../services/api';

function ManageContactsToList(refreshTrigger) {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [lists, setLists] = useState([]);
    const [selectedList, setSelectedList] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        fetchContacts();
        fetchLists();
    }, [refreshTrigger]);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/contacts/');
            setContacts(response.data);
            setFilteredContacts(response.data);
        } catch (error) {
            console.error('Failed to fetch contacts', error);
        }
        setLoading(false);
    };

    const fetchLists = async () => {
        try {
            const response = await api.get('/api/smsko/contacts/lists/');
            setLists(response.data);
        } catch (error) {
            console.error('Failed to fetch lists', error);
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const newFiltered = contacts.filter(contact =>
            contact.phone_number.toString().includes(value) ||
            (contact.first_name && contact.first_name.toLowerCase().includes(value)) || (contact.list_name && contact.list_name.toLowerCase().includes(value))
        );
        setFilteredContacts(newFiltered);
        setSelectAll(false);
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedContacts([]);
        } else {
            setSelectedContacts(filteredContacts.map(contact => contact.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectContact = (contactId) => {
        setSelectedContacts(prev => {
            if (prev.includes(contactId)) {
                return prev.filter(id => id !== contactId);
            } else {
                return [...prev, contactId];
            }
        });
    };

    const handleAddContactsToList = async () => {
        if (!selectedList || selectedContacts.length === 0) {
            setSnackbarMessage("Veuillez sélectionner une liste et au moins un contact.");
            setSnackbarOpen(true);
            return;
        }

        setIsSubmitting(true);
        try {
            await api.post(`/api/smsko/contacts/lists/add-to-list/${selectedList}/`, {
                contacts: selectedContacts,
                list: selectedList
            });
            setSnackbarMessage(`Contacts ajoutés avec succès à la liste ! Total:  ${selectedContacts.length}`);
            setSelectedContacts([]);
            setSelectAll(false);
        } catch (error) {
            console.error('Erreur lors de l\'ajout de contacts à la liste :', error);
            setSnackbarMessage('Échec de l\'ajout de contacts à la liste.');
        } finally {
            setIsSubmitting(false);
            setSnackbarOpen(true);
        }
    };

    const handleListItemClick = (contactId) => {
        handleSelectContact(contactId);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h3" color="primary" sx={{fontSize: '2em', mb: 3}}>Ajouter des contacts à un groupe</Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Mes listes de groupes de contacts (abonnés)</InputLabel>
                <Select
                    value={selectedList}
                    label="Mes listes de groupes de contacts (abonnés)"
                    onChange={(e) => setSelectedList(e.target.value)}
                >
                    {lists.map(list => (
                        <MenuItem key={list.id} value={list.id}>
                            {list.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                label="Rechercher des Contacts ..."
                variant="outlined"
                onChange={handleSearch}
                sx={{ mb: 2 }}
            />
            <Button onClick={toggleSelectAll} variant="outlined" sx={{ mr: 2 }}>
                {selectAll ? 'Tout déselectionner' : 'Tout sélectionner'}
            </Button>
            <Button onClick={handleAddContactsToList} variant="contained" color="primary" disabled={isSubmitting || selectedContacts.length === 0} sx={{ mr: 2 }}>
                {isSubmitting ? <CircularProgress size={24} /> : 'Ajouter à la liste'}
            </Button>
            <Badge badgeContent={`${selectedContacts.length}/${filteredContacts.length}`} color="primary">
                <Button variant="contained" color="secondary">
                    Sélection
                </Button>
            </Badge>
            {loading ? (
                <CircularProgress />
            ) : (
                <List dense>
                    {filteredContacts.map(contact => (
                        <ListItem
                            key={contact.id}
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={() => handleListItemClick(contact.id)}
                                    checked={selectedContacts.includes(contact.id)}
                                />
                            }
                            disablePadding
                            onClick={() => handleListItemClick(contact.id)}
                        >
                            <ListItemButton>
                                <ListItemText primary={`${contact.phone_number} ${contact.first_name ? `- ${contact.first_name}` : ''} | ${contact.list_name}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Box>
    );
}

export default ManageContactsToList;

import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Fade,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
} from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PetsIcon from '@mui/icons-material/Pets';
import CollectionsIcon from '@mui/icons-material/Collections';
import CloseIcon from '@mui/icons-material/Close';
import { useSwipeable } from 'react-swipeable';
import { Player } from '@lottiefiles/react-lottie-player';
import Masonry from '@mui/lab/Masonry';
import api from '../../../services/api';
import catAnimation from './catbefiana.json';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function CatTinder() {
  const [cats, setCats] = useState([]);
  const [currentCatIndex, setCurrentCatIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showBackground, setShowBackground] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [favorites, setFavorites] = useState(new Set());
  const [showFavorites, setShowFavorites] = useState(false);
  const [favoriteCats, setFavoriteCats] = useState([]);
  const [favoritePage, setFavoritePage] = useState(1);
  const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [hasMoreFavorites, setHasMoreFavorites] = useState(true);

  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCats();
  }, []);

  useEffect(() => {
    let timer;
    if (showBackground) {
      timer = setTimeout(() => {
        setShowBackground(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showBackground]);

  const fetchCats = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/tiasaka/fetch-cats/');
      setCats(response.data.images);
      setCurrentCatIndex(0);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des chats :', error);
      setLoading(false);
    }
  };

  const fetchFavoriteCats = async (page = 1) => {
    try {
      if (!hasMoreFavorites) return;
      setLoadingFavorites(true);
      const response = await api.get(`/api/tiasaka/favorites/?page=${page}`);
      const images = response.data.results.images;
      setFavoriteCats((prev) => (page === 1 ? images : [...prev, ...images]));
      setHasMoreFavorites(!!response.data.next); // Vérifie s'il y a une page suivante
      setLoadingFavorites(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des favoris :', error);
      setLoadingFavorites(false);
    }
  };

  const handleSwipe = (direction) => {
    setShowBackground(true);
    if (currentCatIndex < cats.length - 1) {
      setCurrentCatIndex((prevIndex) => prevIndex + 1);
    } else {
      fetchCats();
    }
  };

  const handleToggleFavorite = async () => {
    if (cats.length === 0) return;

    const imageUrl = cats[currentCatIndex];
    try {
      const response = await api.post('/api/tiasaka/add-favorite/', { image_url: imageUrl });
      if (response.data.message === "Cat added to favorites!") {
        setFavorites((prevFavorites) => new Set(prevFavorites).add(imageUrl));
        setSnackbarMessage('Miaou ajoutée à vos favoris !');
      } else if (response.data.message === "Cat removed from favorites!") {
        setFavorites((prevFavorites) => {
          const newFavorites = new Set(prevFavorites);
          newFavorites.delete(imageUrl);
          return newFavorites;
        });
        setSnackbarMessage('Miaou retiré des favoris !');
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Échec de la mise à jour des favoris.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenFavorites = () => {
    setShowFavorites(true);
    fetchFavoriteCats(1);
  };

  const handleCloseFavorites = () => {
    setShowFavorites(false);
    setFavoritePage(1);
    setFavoriteCats([]);
    setHasMoreFavorites(true);
  };

  const handleLoadMoreFavorites = () => {
    fetchFavoriteCats(favoritePage + 1);
    setFavoritePage((prev) => prev + 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('Left'),
    onSwipedRight: () => handleSwipe('Right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleNext = () => {
    handleSwipe('Left');
  };

  const handleRemoveFavorite = async (imageUrl) => {
    try {
      const response = await api.post('/api/tiasaka/add-favorite/', { image_url: imageUrl });
      if (response.data.message === "Cat removed from favorites!") {
        setFavoriteCats((prev) => prev.filter((cat) => cat !== imageUrl));
        setSnackbarMessage('Miaou retiré des favoris !');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Échec de la suppression des favoris.');
      setSnackbarOpen(true);
    }
  };
  

  if (loading) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: '1px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#ffadde',
          height: '100vh',
        }}
      >
        <Player
          autoplay
          loop
          src={catAnimation}
          style={{ height: '300px', width: '300px' }}
        />
        <div>Miaou Miaou miaou...</div>
      </div>
    );
  }

  if (cats.length === 0) {
    return <div>Aucun Miaou disponible pour le moment.</div>;
  }

  const isCurrentCatFavorite = favorites.has(cats[currentCatIndex]);

  return (
    <>
      <div
        {...handlers}
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: 650,
          margin: '0 auto',
          backgroundColor: '#ffadde',
        //   backgroundImage: 'url(/cat.gif)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '5px',
          borderRadius: '10px',
          height: '100vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <PetsIcon fontSize="large" style={{ marginRight: '10px' }} />
          <h1
            style={{
              fontFamily: 'Roboto, sans-serif',
              fontSize: '24px',
              color: '#006837',
              padding: '2px 8px',
              backgroundColor: '#fff',
              borderRadius: '2px 8px',
            }}
          >
            tiaSaka
          </h1>
        </div>

        {showBackground ? (
          <div
            style={{
              width: '100%',
              height: '500px',
              backgroundColor: '#ffadde',
              backgroundImage: 'url(/cat.gif)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '20px',
            }}
          />
        ) : (
          <Fade
            in={!showBackground}
            timeout={500}
            key={cats[currentCatIndex]}
          >
            <div
              style={{
                backgroundImage: `url(${cats[currentCatIndex]})`,
                width: '100%',
                height: '500px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '20px',
                border: '5px solid white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </Fade>
        )}

        <IconButton
          style={{
            position: 'fixed',
            bottom: 20,
            left: 20,
            backgroundColor: 'white',
            color: '#006837',
          }}
          onClick={() => navigate('/')}
        >
          <HomeIcon fontSize="large" />
        </IconButton>

        <IconButton
          style={{
            position: 'fixed',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'white',
            color: isCurrentCatFavorite ? '#e91e63' : '#006837',
          }}
          onClick={handleToggleFavorite}
        >
          {isCurrentCatFavorite ? (
            <FavoriteIcon fontSize="large" />
          ) : (
            <FavoriteBorderIcon fontSize="large" />
          )}
        </IconButton>

        <IconButton
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            backgroundColor: 'white',
            color: '#006837',
          }}
          onClick={handleNext}
        >
          <SkipNextIcon fontSize="large" />
        </IconButton>

        <IconButton
          style={{
            position: 'fixed',
            bottom: 80,
            right: 20,
            backgroundColor: 'white',
            color: '#006837',
          }}
          onClick={handleOpenFavorites}
        >
          <CollectionsIcon fontSize="large" />
        </IconButton>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>

      <Dialog fullScreen open={showFavorites} onClose={handleCloseFavorites}>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <h2>Mes Miaou préférés</h2>
            <IconButton onClick={handleCloseFavorites}>
              <CloseIcon />
            </IconButton>
          </div>
          <Masonry columns={isMobile ? 1 : 2} spacing={2}>
            {favoriteCats.map((cat, index) => (
                <div
                key={index}
                style={{
                    position: 'relative',
                    borderRadius: '8px',
                    overflow: 'hidden',
                }}
                >
                <img
                    src={cat}
                    alt="favorite cat"
                    style={{
                    width: '100%',
                    borderRadius: '8px',
                    display: 'block',
                    }}
                />
                <IconButton
                    onClick={() => handleRemoveFavorite(cat)}
                    style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    color: '#e91e63',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </div>
            ))}
            </Masonry>

          {loadingFavorites && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </div>
          )}
          {hasMoreFavorites && !loadingFavorites && (
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Button onClick={handleLoadMoreFavorites}>Plus Miaou miaou...</Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CatTinder;

import React from 'react';
import { AppBar, Toolbar, Typography, Box, Paper, Button, Link, IconButton, Container, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function Policy() {
    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <img src="/befiana.png" alt="Befiana Logo" style={{ height: '50px', marginRight: '20px' }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                    </Typography>
                    <IconButton color="inherit" component={RouterLink} to="/account">
                        <MenuIcon />
                        <Typography variant="body1" sx={{ ml: 1 }}>
                            Mon Compte
                        </Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>

                <Paper elevation={3} sx={{ p: 2, maxWidth: 899, mx: 'auto' }}>
                    <Typography variant="h4" gutterBottom>
                        Politique de confidentialité - Befiana Connect
                    </Typography>
                    <Helmet>
                        <title>Politique de confidentialité - BEFIANA Connect</title>
                        <meta name="description" content="Découvrez comment Befiana Connect collecte, utilise et protège vos informations personnelles. Lisez notre Politique de confidentialité pour connaître vos droits à la vie privée et comprendre les mesures que nous prenons pour sécuriser vos données." />
                    </Helmet>
                    <Typography paragraph>
                        La présente Politique de confidentialité décrit Nos politiques et procédures relatives à la collecte, à l'utilisation et à la divulgation de vos informations lorsque vous utilisez le Service et vous informe de vos droits à la vie privée et de la manière dont la loi vous protège.
                    </Typography>
                    <Typography variant="subtitle2" color="initial">Date de dernière révision : 19 avril 2024</Typography>

                    <Divider sx={{ my: 1 }} />


                    <h2>Interpr&eacute;tation et d&eacute;finitions</h2>
                    <h3>Interpr&eacute;tation</h3>
                    <p>Les mots dont la lettre initiale est en majuscule ont des significations d&eacute;finies dans les conditions suivantes. Les d&eacute;finitions qui suivent ont la m&ecirc;me signification, qu'elles apparaissent au singulier ou au pluriel.</p>
                    <h3>D&eacute;finitions</h3>
                    <p>Aux fins de la pr&eacute;sente Politique de confidentialit&eacute; :</p>
                    <p><strong>Compte</strong> d&eacute;signe un compte unique cr&eacute;&eacute; pour vous permettre d'acc&eacute;der &agrave; notre service ou &agrave; des parties de notre service.</p>
                    <p><strong>Affili&eacute;</strong> d&eacute;signe une entit&eacute; qui contr&ocirc;le, est contr&ocirc;l&eacute;e par ou est sous contr&ocirc;le commun avec une partie, o&ugrave; le terme &laquo; contr&ocirc;le &raquo; d&eacute;signe la propri&eacute;t&eacute; de 50 % ou plus des actions, des participations ou d'autres titres ayant le droit de vote pour l'&eacute;lection d'administrateurs ou d'une autre autorit&eacute; de gestion.</p>
                    <p><strong>Application</strong> d&eacute;signe Befiana Connect, le logiciel fourni par la Soci&eacute;t&eacute;.</p>
                    <p><strong>La Soci&eacute;t&eacute;</strong> (ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; la Soci&eacute;t&eacute; &raquo;, &laquo; Nous &raquo;, &laquo; Notre &raquo; ou &laquo; Notre &raquo; dans le pr&eacute;sent Contrat) fait r&eacute;f&eacute;rence &agrave; Befiana Plaftorm Corps Ltd, 71-75 Shelton Street, Covent Garden, Londres, Royaume-Uni, WC2H 9JQ</p>
                    <p><strong>Les cookies</strong> sont de petits fichiers qui sont plac&eacute;s sur votre ordinateur, votre appareil mobile ou tout autre appareil par un site Web, contenant les d&eacute;tails de votre historique de navigation sur ce site Web parmi ses nombreuses utilisations.</p>
                    <p><strong>Pays</strong> faisant r&eacute;f&eacute;rence &agrave;&nbsp;: Royaume-Uni</p>
                    <p><strong>Appareil</strong> d&eacute;signe tout appareil pouvant acc&eacute;der au Service, tel qu'un ordinateur, un t&eacute;l&eacute;phone portable ou une tablette num&eacute;rique.</p>
                    <p><strong>Les donn&eacute;es personnelles</strong> sont toutes les informations qui se rapportent &agrave; une personne identifi&eacute;e ou identifiable.</p>
                    <p><strong>Le Service</strong> fait r&eacute;f&eacute;rence &agrave; l'Application ou au Site Web ou aux deux.</p>
                    <p><strong>Prestataire</strong> d&eacute;signe toute personne physique ou morale qui traite les donn&eacute;es pour le compte de la Soci&eacute;t&eacute;. Il s'agit de soci&eacute;t&eacute;s tierces ou de personnes employ&eacute;es par la Soci&eacute;t&eacute; pour faciliter le Service, pour fournir le Service au nom de la Soci&eacute;t&eacute;, pour fournir des services li&eacute;s au Service ou pour aider la Soci&eacute;t&eacute; &agrave; analyser la fa&ccedil;on dont le Service est utilis&eacute;.</p>
                    <p><strong>Les donn&eacute;es d'utilisation</strong> font r&eacute;f&eacute;rence aux donn&eacute;es collect&eacute;es automatiquement, soit g&eacute;n&eacute;r&eacute;es par l'utilisation du Service, soit &agrave; partir de l'infrastructure du Service elle-m&ecirc;me (par exemple, la dur&eacute;e de la visite d'une page).</p>
                    <p><strong>Le site Web</strong> fait r&eacute;f&eacute;rence &agrave; Befiana Connect, accessible depuis <a href="https://www.befiana.cloud">https://www.befiana.cloud</a></p>
                    <p><strong>Vous</strong> d&eacute;signez la personne qui acc&egrave;de au Service ou qui l'utilise, ou la soci&eacute;t&eacute; ou toute autre entit&eacute; juridique au nom de laquelle cette personne acc&egrave;de au Service ou l'utilise, selon le cas.</p>
                    <h2>Collecte et utilisation de vos donn&eacute;es personnelles</h2>
                    <h3>Types de donn&eacute;es collect&eacute;es</h3>
                    <h4>Donn&eacute;es personnelles</h4>
                    <p>Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent &ecirc;tre utilis&eacute;es pour vous contacter ou vous identifier. Les informations personnellement identifiables peuvent inclure, sans s'y limiter :</p>
                    <p>Adresse courriel</p>
                    <p>Pr&eacute;nom et nom de famille</p>
                    <p>Adresse, &Eacute;tat, Province, Code postal, Ville</p>
                    <p>Donn&eacute;es d'utilisation</p>
                    <h4>Donn&eacute;es d'utilisation</h4>
                    <p>Les donn&eacute;es d'utilisation sont collect&eacute;es automatiquement lors de l'utilisation du service.</p>
                    <p>Les donn&eacute;es d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre appareil (par exemple, l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps pass&eacute; sur ces pages, les identifiants uniques de l'appareil et d'autres donn&eacute;es de diagnostic.</p>
                    <p>Lorsque vous acc&eacute;dez au service par ou via un appareil mobile, nous pouvons collecter automatiquement certaines informations, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre syst&egrave;me d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres donn&eacute;es de diagnostic.</p>
                    <p>Nous pouvons &eacute;galement collecter des informations que votre navigateur envoie chaque fois que vous visitez notre service ou lorsque vous acc&eacute;dez au service par ou via un appareil mobile.</p>
                    <h4>Informations collect&eacute;es lors de l'utilisation de l'application</h4>
                    <p>Lors de l'utilisation de notre application, afin de fournir des fonctionnalit&eacute;s de notre application, nous pouvons recueillir, avec votre autorisation pr&eacute;alable :</p>
                    <ul>
                        <li>Informations relatives &agrave; votre localisation</li>
                    </ul>
                    <p>Nous utilisons ces informations pour fournir des fonctionnalit&eacute;s de notre service, pour am&eacute;liorer et personnaliser notre service. Les informations peuvent &ecirc;tre t&eacute;l&eacute;charg&eacute;es sur les serveurs de la Soci&eacute;t&eacute; et/ou sur le serveur d'un Fournisseur de services ou elles peuvent simplement &ecirc;tre stock&eacute;es sur Votre appareil.</p>
                    <p>Vous pouvez activer ou d&eacute;sactiver l'acc&egrave;s &agrave; ces informations &agrave; tout moment, via les param&egrave;tres de votre appareil.</p>
                    <h4>Technologies de suivi et cookies</h4>
                    <p>Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activit&eacute; sur notre service et stocker certaines informations. Les technologies de suivi utilis&eacute;es sont des balises, des balises et des scripts pour collecter et suivre des informations et pour am&eacute;liorer et analyser notre service. Les technologies que nous utilisons peuvent inclure :</p>
                    <ul>
                        <li><strong>Cookies ou cookies de navigateur.</strong> Un cookie est un petit fichier plac&eacute; sur votre appareil. Vous pouvez demander &agrave; votre navigateur de refuser tous les cookies ou d'indiquer quand un cookie est envoy&eacute;. Cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-&ecirc;tre pas utiliser certaines parties de notre service. &Agrave; moins que vous n'ayez r&eacute;gl&eacute; les param&egrave;tres de votre navigateur de mani&egrave;re &agrave; ce qu'il refuse les cookies, notre service peut utiliser des cookies.</li>
                        <li><strong>Balises Web.</strong> Certaines sections de notre Service et de nos e-mails peuvent contenir de petits fichiers &eacute;lectroniques connus sous le nom de balises Web (&eacute;galement appel&eacute;s GIF invisibles, pixels invisibles et GIF &agrave; pixel unique) qui permettent &agrave; la Soci&eacute;t&eacute;, par exemple, de compter les utilisateurs qui ont visit&eacute; ces pages ou ouvert un e-mail et d'autres statistiques connexes sur le site Web (par exemple, enregistrer la popularit&eacute; d'une certaine section et v&eacute;rifier l'int&eacute;grit&eacute; du syst&egrave;me et du serveur).</li>
                    </ul>
                    <p>Les cookies peuvent &ecirc;tre des cookies &laquo; persistants &raquo; ou des cookies &laquo; de session &raquo;. Les cookies persistants restent sur votre ordinateur personnel ou votre appareil mobile lorsque vous vous d&eacute;connectez, tandis que les cookies de session sont supprim&eacute;s d&egrave;s que vous fermez votre navigateur Web.</p>
                    <p>Nous utilisons &agrave; la fois des cookies de session et des cookies persistants aux fins &eacute;nonc&eacute;es ci-dessous :</p>
                    <p><strong>Cookies n&eacute;cessaires / essentiels</strong></p>
                    <p>Type : Cookies de session</p>
                    <p>Administr&eacute; par : Nous</p>
                    <p>Objectif : Ces cookies sont essentiels pour vous fournir les services disponibles sur le site Web et pour vous permettre d'utiliser certaines de ses fonctionnalit&eacute;s. Ils permettent d'authentifier les utilisateurs et d'emp&ecirc;cher l'utilisation frauduleuse des comptes d'utilisateurs. Sans ces cookies, les services que vous avez demand&eacute;s ne peuvent pas &ecirc;tre fournis, et nous n'utilisons ces cookies que pour vous fournir ces services.</p>
                    <p><strong>Politique de cookies / Cookies d'acceptation des avis</strong></p>
                    <p>Type : Cookies persistants</p>
                    <p>Administr&eacute; par : Nous</p>
                    <p>Finalit&eacute; : Ces cookies identifient si les utilisateurs ont accept&eacute; l'utilisation de cookies sur le site Web.</p>
                    <p><strong>Cookies de fonctionnalit&eacute;</strong></p>
                    <p>Type : Cookies persistants</p>
                    <p>Administr&eacute; par : Nous</p>
                    <p>Objectif : Ces cookies nous permettent de nous souvenir des choix que vous faites lorsque vous utilisez le site Web, tels que la m&eacute;morisation de vos informations de connexion ou de vos pr&eacute;f&eacute;rences linguistiques. L'objectif de ces cookies est de vous offrir une exp&eacute;rience plus personnelle et de vous &eacute;viter d'avoir &agrave; saisir &agrave; nouveau vos pr&eacute;f&eacute;rences &agrave; chaque fois que vous utilisez le site Web.</p>
                    <p>Pour plus d'informations sur les cookies que nous utilisons et vos choix en mati&egrave;re de cookies, veuillez consulter notre Politique en mati&egrave;re de cookies ou la section Cookies de notre Politique de confidentialit&eacute;.</p>
                    <h3>Utilisation de vos donn&eacute;es personnelles</h3>
                    <p>La Soci&eacute;t&eacute; peut utiliser les Donn&eacute;es Personnelles pour les finalit&eacute;s suivantes :</p>
                    <p><strong>Pour fournir et maintenir notre Service</strong>, y compris pour surveiller l'utilisation de notre Service.</p>
                    <p><strong>Pour g&eacute;rer votre compte :</strong> pour g&eacute;rer votre inscription en tant qu'utilisateur du service. Les donn&eacute;es personnelles que vous fournissez peuvent vous donner acc&egrave;s &agrave; diff&eacute;rentes fonctionnalit&eacute;s du service qui sont &agrave; votre disposition en tant qu'utilisateur enregistr&eacute;.</p>
                    <p><strong>Pour l'ex&eacute;cution d'un contrat :</strong> l'&eacute;laboration, le respect et l'ex&eacute;cution du contrat d'achat des produits, articles ou services que vous avez achet&eacute;s ou de tout autre contrat conclu avec nous par le biais du service.</p>
                    <p><strong>Pour vous contacter :</strong> pour vous contacter par e-mail, appels t&eacute;l&eacute;phoniques, SMS ou autres formes &eacute;quivalentes de communication &eacute;lectronique, telles que les notifications push d'une application mobile concernant les mises &agrave; jour ou les communications informatives li&eacute;es aux fonctionnalit&eacute;s, produits ou services contract&eacute;s, y compris les mises &agrave; jour de s&eacute;curit&eacute;, lorsque cela est n&eacute;cessaire ou raisonnable pour leur mise en &oelig;uvre.</p>
                    <p><strong>Pour vous fournir</strong> des actualit&eacute;s, des offres sp&eacute;ciales et des informations g&eacute;n&eacute;rales sur d'autres biens, services et &eacute;v&eacute;nements que nous proposons et qui sont similaires &agrave; ceux que vous avez d&eacute;j&agrave; achet&eacute;s ou sur lesquels vous vous &ecirc;tes renseign&eacute;, sauf si vous avez choisi de ne pas recevoir ces informations.</p>
                    <p><strong>Pour g&eacute;rer vos demandes :</strong> Pour r&eacute;pondre et g&eacute;rer vos demandes qui nous sont adress&eacute;es.</p>
                    <p><strong>Pour les transferts d'entreprise :</strong> Nous pouvons utiliser vos informations pour &eacute;valuer ou mener une fusion, un dessaisissement, une restructuration, une r&eacute;organisation, une dissolution ou toute autre vente ou transfert d'une partie ou de la totalit&eacute; de nos actifs, que ce soit dans le cadre d'une exploitation ou dans le cadre d'une faillite, d'une liquidation ou d'une proc&eacute;dure similaire, dans laquelle les donn&eacute;es personnelles que nous d&eacute;tenons sur les utilisateurs de nos services font partie des actifs transf&eacute;r&eacute;s.</p>
                    <p><strong>&Agrave; d'autres fins </strong>: Nous pouvons utiliser vos informations &agrave; d'autres fins, telles que l'analyse de donn&eacute;es, l'identification des tendances d'utilisation, la d&eacute;termination de l'efficacit&eacute; de nos campagnes promotionnelles et l'&eacute;valuation et l'am&eacute;lioration de notre service, de nos produits, de nos services, de notre marketing et de votre exp&eacute;rience.</p>
                    <p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>
                    <ul>
                        <li><strong>Avec les fournisseurs de services :</strong> Nous pouvons partager vos informations personnelles avec des fournisseurs de services afin de surveiller et d'analyser l'utilisation de notre service, afin de vous contacter.</li>
                        <li><strong>Pour les transferts d'entreprise :</strong> Nous pouvons partager ou transf&eacute;rer vos informations personnelles dans le cadre de, ou pendant les n&eacute;gociations de, toute fusion, vente d'actifs de la soci&eacute;t&eacute;, financement ou acquisition de tout ou partie de notre entreprise &agrave; une autre soci&eacute;t&eacute;.</li>
                        <li><strong>Avec les soci&eacute;t&eacute;s affili&eacute;es :</strong> Nous pouvons partager vos informations avec nos soci&eacute;t&eacute;s affili&eacute;es, auquel cas nous exigerons de ces soci&eacute;t&eacute;s affili&eacute;es qu'elles respectent la pr&eacute;sente politique de confidentialit&eacute;. Les soci&eacute;t&eacute;s affili&eacute;es comprennent notre soci&eacute;t&eacute; m&egrave;re et toutes les autres filiales, partenaires de coentreprise ou autres soci&eacute;t&eacute;s que nous contr&ocirc;lons ou qui sont sous contr&ocirc;le commun avec nous.</li>
                        <li><strong>Avec des partenaires commerciaux :</strong> Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.</li>
                        <li><strong>Avec d'autres utilisateurs :</strong> lorsque vous partagez des informations personnelles ou interagissez dans les espaces publics avec d'autres utilisateurs, ces informations peuvent &ecirc;tre consult&eacute;es par tous les utilisateurs et peuvent &ecirc;tre distribu&eacute;es publiquement &agrave; l'ext&eacute;rieur.</li>
                        <li><strong>Avec votre consentement </strong>: Nous pouvons divulguer vos renseignements personnels &agrave; toute autre fin avec votre consentement.</li>
                    </ul>
                    <h3>Conservation de vos donn&eacute;es personnelles</h3>
                    <p>La Soci&eacute;t&eacute; ne conservera vos donn&eacute;es personnelles que le temps n&eacute;cessaire aux fins &eacute;nonc&eacute;es dans la pr&eacute;sente politique de confidentialit&eacute;. Nous conserverons et utiliserons vos donn&eacute;es personnelles dans la mesure n&eacute;cessaire pour nous conformer &agrave; nos obligations l&eacute;gales (par exemple, si nous sommes tenus de conserver vos donn&eacute;es pour nous conformer aux lois applicables), r&eacute;soudre les litiges et faire respecter nos accords et politiques juridiques.</p>
                    <p>La Soci&eacute;t&eacute; conservera &eacute;galement les Donn&eacute;es d'utilisation &agrave; des fins d'analyse interne. Les donn&eacute;es d'utilisation sont g&eacute;n&eacute;ralement conserv&eacute;es pendant une p&eacute;riode plus courte, sauf lorsque ces donn&eacute;es sont utilis&eacute;es pour renforcer la s&eacute;curit&eacute; ou am&eacute;liorer la fonctionnalit&eacute; de notre service, ou lorsque nous sommes l&eacute;galement tenus de conserver ces donn&eacute;es pendant des p&eacute;riodes plus longues.</p>
                    <h3>Transfert de vos donn&eacute;es personnelles</h3>
                    <p>Vos informations, y compris les donn&eacute;es personnelles, sont trait&eacute;es dans les bureaux d'exploitation de la Soci&eacute;t&eacute; et dans tout autre lieu o&ugrave; se trouvent les parties impliqu&eacute;es dans le traitement. Cela signifie que ces informations peuvent &ecirc;tre transf&eacute;r&eacute;es et conserv&eacute;es sur des ordinateurs situ&eacute;s en dehors de votre &eacute;tat, province, pays ou autre juridiction gouvernementale o&ugrave; les lois sur la protection des donn&eacute;es peuvent diff&eacute;rer de celles de votre juridiction.</p>
                    <p>Votre consentement &agrave; la pr&eacute;sente politique de confidentialit&eacute;, suivi de votre soumission de ces informations, repr&eacute;sente votre accord &agrave; ce transfert.</p>
                    <p>La Soci&eacute;t&eacute; prendra toutes les mesures raisonnablement n&eacute;cessaires pour s'assurer que vos donn&eacute;es sont trait&eacute;es en toute s&eacute;curit&eacute; et conform&eacute;ment &agrave; la pr&eacute;sente politique de confidentialit&eacute; et qu'aucun transfert de vos donn&eacute;es personnelles n'aura lieu vers une organisation ou un pays &agrave; moins qu'il n'y ait des contr&ocirc;les ad&eacute;quats en place, y compris la s&eacute;curit&eacute; de vos donn&eacute;es et autres informations personnelles.</p>
                    <h3>Supprimer vos donn&eacute;es personnelles</h3>
                    <p>Vous avez le droit de supprimer ou de demander que nous vous aidions &agrave; supprimer les donn&eacute;es personnelles que nous avons collect&eacute;es &agrave; votre sujet.</p>
                    <p>Notre Service peut Vous donner la possibilit&eacute; de supprimer certaines informations Vous concernant &agrave; partir du Service.</p>
                    <p>Vous pouvez mettre &agrave; jour, modifier ou supprimer vos informations &agrave; tout moment en vous connectant &agrave; votre compte, si vous en avez un, et en visitant la section des param&egrave;tres du compte qui vous permet de g&eacute;rer vos informations personnelles. Vous pouvez &eacute;galement nous contacter pour demander l'acc&egrave;s, la correction ou la suppression de toute information personnelle que vous nous avez fournie.</p>
                    <p>Veuillez noter, cependant, que nous pouvons avoir besoin de conserver certaines informations lorsque nous avons une obligation l&eacute;gale ou une base l&eacute;gale pour le faire.</p>
                    <h3>Divulgation de vos donn&eacute;es personnelles</h3>
                    <h4>Transactions commerciales</h4>
                    <p>Si la Soci&eacute;t&eacute; est impliqu&eacute;e dans une fusion, une acquisition ou une vente d'actifs, Vos Donn&eacute;es Personnelles peuvent &ecirc;tre transf&eacute;r&eacute;es. Nous vous informerons avant que vos donn&eacute;es personnelles ne soient transf&eacute;r&eacute;es et ne soient soumises &agrave; une politique de confidentialit&eacute; diff&eacute;rente.</p>
                    <h4>Forces de l&rsquo;ordres</h4>
                    <p>Dans certaines circonstances, la Soci&eacute;t&eacute; peut &ecirc;tre tenue de divulguer Vos Donn&eacute;es personnelles si la loi l'exige ou en r&eacute;ponse &agrave; des demandes valides des autorit&eacute;s publiques (par exemple, un tribunal ou une agence gouvernementale).</p>
                    <h4>Autres exigences l&eacute;gales</h4>
                    <p>La Soci&eacute;t&eacute; peut divulguer Vos Donn&eacute;es Personnelles en croyant de bonne foi qu'une telle action est n&eacute;cessaire pour :</p>
                    <ul>
                        <li>Se conformer &agrave; une obligation l&eacute;gale</li>
                        <li>Prot&eacute;ger et d&eacute;fendre les droits ou la propri&eacute;t&eacute; de la Soci&eacute;t&eacute;</li>
                        <li>Pr&eacute;venir ou enqu&ecirc;ter sur d'&eacute;ventuels actes r&eacute;pr&eacute;hensibles en lien avec le Service</li>
                        <li>Prot&eacute;ger la s&eacute;curit&eacute; personnelle des Utilisateurs du Service ou du public</li>
                        <li>Prot&eacute;gez-vous contre la responsabilit&eacute; l&eacute;gale</li>
                    </ul>
                    <h3>S&eacute;curit&eacute; de vos donn&eacute;es personnelles</h3>
                    <p>La s&eacute;curit&eacute; de vos donn&eacute;es personnelles est importante pour nous, mais n'oubliez pas qu'aucune m&eacute;thode de transmission sur Internet ou de stockage &eacute;lectronique n'est s&ucirc;re &agrave; 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour prot&eacute;ger vos donn&eacute;es personnelles, nous ne pouvons pas garantir leur s&eacute;curit&eacute; absolue.</p>
                    <h2>Protection de la vie priv&eacute;e des enfants</h2>
                    <p>Notre Service ne s'adresse pas aux personnes de moins de 13 ans. Nous ne recueillons pas sciemment d'informations personnelles identifiables aupr&egrave;s de personnes de moins de 13 ans. Si vous &ecirc;tes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des donn&eacute;es personnelles, veuillez nous contacter. Si nous apprenons que nous avons collect&eacute; des donn&eacute;es personnelles aupr&egrave;s de personnes de moins de 13 ans sans v&eacute;rification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.</p>
                    <p>Si nous devons nous appuyer sur le consentement comme base juridique pour le traitement de vos informations et que votre pays exige le consentement d'un parent, nous pouvons exiger le consentement de votre parent avant de collecter et d'utiliser ces informations.</p>
                    <h2>Liens vers d'autres sites Web</h2>
                    <p>Notre Service peut contenir des liens vers d'autres sites Web qui ne sont pas exploit&eacute;s par Nous. Si vous cliquez sur le lien d'un tiers, vous serez dirig&eacute; vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialit&eacute; de chaque site que vous visitez.</p>
                    <p>Nous n'avons aucun contr&ocirc;le et n'assumons aucune responsabilit&eacute; quant au contenu, aux politiques de confidentialit&eacute; ou aux pratiques des sites ou services tiers.</p>
                    <h2>Modifications apport&eacute;es &agrave; la pr&eacute;sente Politique de confidentialit&eacute;</h2>
                    <p>Nous pouvons mettre &agrave; jour notre politique de confidentialit&eacute; de temps &agrave; autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialit&eacute; sur cette page.</p>
                    <p>Nous vous en informerons par e-mail et/ou par un avis bien visible sur notre service, avant l'entr&eacute;e en vigueur de la modification, et nous mettrons &agrave; jour la date de &laquo; derni&egrave;re mise &agrave; jour &raquo; en haut de la pr&eacute;sente politique de confidentialit&eacute;.</p>
                    <p>Nous vous conseillons de consulter r&eacute;guli&egrave;rement la pr&eacute;sente Politique de confidentialit&eacute; pour prendre connaissance de tout changement. Les modifications apport&eacute;es &agrave; la pr&eacute;sente politique de confidentialit&eacute; entrent en vigueur lorsqu'elles sont publi&eacute;es sur cette page.</p>


                    <Divider sx={{ my: 1 }} />

                    <Typography variant="h6" gutterBottom>
                        Contactez-nous
                    </Typography>
                    <Typography paragraph>
                        Si vous avez des questions sur cette Politique de confidentialité, vous pouvez nous contacter:<br />
                        Par email : <Link href="mailto:policy@befiana.com">policy@befiana.com</Link><br />
                        Siège national : SITRAM Ankorondrano Zone Industrielle Route des Hydrocarbures B.P 171 – Antananarivo 101
                    </Typography>


                    <Button
                        component={RouterLink}
                        to="/"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Retour à l'accueil
                    </Button>
                </Paper>

            </Container>

            <Box component="footer" sx={{ bgcolor: 'background.paper', p: 2, mt: 4 }}>
                <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '100%' }}>
                        <Link href="/terms" underline="hover" sx={{ mx: 2, my: 1 }}>
                            CGU
                        </Link>
                        <Link href="/policy" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Politique de confidentialité
                        </Link>
                        <Link href="/cookies" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Cookies 
                        </Link>
                        <Link href="https://help.befiana.cloud" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Besoins d'aide
                        </Link>
                        <Link href="https://www.befiana.com/contact/" underline="hover" sx={{ mx: 2, my: 1 }}>
                            Contact
                        </Link>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 2 }} align="center">
                        © {new Date().getFullYear()} Befiana Platform Corps
                    </Typography>
                </Container>
            </Box>


        </Box>
    );
}

export default Policy;
import React, { useEffect, useState } from 'react';
import { Box, Toolbar, Typography, Paper, Grid, Container, Divider } from '@mui/material';
import AppBarComponent from '../components/AppBar';
import DrawerBarandTabsXS from '../components/drawer/DrawerBarXS';
import Weather from '../components/meteo/Weather';
import AvaibleProgress from '../components/drive/AvaibleProgress';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import backgroundImage2 from '../images/design/Background.jpg';
import { Link } from 'react-router-dom';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SmsIcon from '@mui/icons-material/Sms';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloudIcon from '@mui/icons-material/Cloud';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PetsIcon from '@mui/icons-material/Pets';
import Footer from '../components/Footer';

import api from '../services/api';
import colorCode from '../services/randomColor';
import EmailVerificationAlert from '../components/messages/ResendEmail';
import { Helmet } from 'react-helmet-async';

function Home() {
  const [profilPicture, setProfilPicture] = useState(false)
  const [userProfile, setUserProfile] = useState({
    username: ' ', 
    firstName: ' ', 
    lastName: ' ', 
    email_verified: true, 
    imageUrl: null,
  });

  useEffect(() => {
    api.get('api/user-info/')
      .then(response => {
        setUserProfile({
          username: response.data.username,
          firstName: response.data.first_name, 
          lastName: response.data.last_name, 
          email_verified: response.data.email_verified
        });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des informations utilisateur:', error);
      });

     // Récupération de l'image de profil
     api.get('/api/profile-image/')
     .then(response => {
       if (response.data && response.data.image) {
         setProfilPicture(true)
         setUserProfile(prevState => ({ ...prevState, imageUrl: response.data.image }));
       }
     })
     .catch(error => {
       console.error("Erreur lors de la récupération de l'image de profil:", error);
     });

  }, []);

  function getInitials(fullname) {
    const words = fullname.split(' ');
    let initials = words[0][0]; 
    if (words.length > 1) {
      initials += words[1][0]; 
    }
    return initials;
  }
  const initials = getInitials(userProfile.firstName + " " + userProfile.lastName);


  return (
    <>
    <Container maxWidth="xl" sx={{ backgroundImage: `url(${backgroundImage2})`, backgroundPosition: 'center', backgroundSize: 'cover', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Helmet>
            <title>BEFIANA Connect - Osez l'infini !</title>
            <meta name="description" content="Osez l'infini avec BEFIANA Connect !" />
        </Helmet>
        <AppBarComponent />

        <Box component="main" sx={{ flexGrow: 1, marginTop: '30px' }}>
          <Toolbar />

          <Container maxWidth="md">
            <Grid container spacing={2}>

              {!userProfile.email_verified && (
                  <Grid item xs={12}>
                      <EmailVerificationAlert />
                  </Grid>
              )}
              
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ margin: 'auto', mt: 1, p: 2, minHeight: '300px', borderRadius: '17px' }}>
                    {profilPicture ? (
                      <Avatar alt={`${userProfile.firstName} ${userProfile.lastName}`} src={userProfile.imageUrl} sx={{ width: 119, height: 119, margin: '0 auto' }} />
                    ) : (
                      <Avatar sx={{ bgcolor: colorCode, width: 119, height: 119, fontSize: 65, margin: '0 auto' }} alt={`${userProfile.firstName} ${userProfile.lastName}`} >{initials}</Avatar>
                    )}
                  <Typography variant="h5" color="initial">{userProfile.firstName}</Typography>
                  <Typography variant="body1" color="initial">@{userProfile.username}</Typography>
                  <AvaibleProgress />
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  elevation={3}
                  sx={{
                    margin: 'auto',
                    mt: 1, p: 2,
                    backgroundColor: '#FFFFFFC4',
                    minHeight: '300px',
                    borderRadius: '17px' 
                  }}
                >
                  <Weather />
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{
                    margin: 'auto',
                    mt: 1, p: 2,
                    backgroundColor: '#FFFFFFC4',
                    minHeight: '290px',
                    borderRadius: '17px' 
                  }}
                >
                  <Divider>
                    <Chip label="Befiana for Business" size="small" />
                  </Divider>

                  <Grid container spacing={2} m={2} >
                    <Grid item xs={5}>
                      <Link to="/App/SmsKo/home" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <SmsIcon style={{ fontSize: 60, color: '#f7ab00' }} />
                          <Typography variant="subtitle1">SMS</Typography>
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={5}>
                      <Link to="/future" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <SmartToyIcon style={{ fontSize: 60, color: '#159c7c' }} />
                          <Typography variant="subtitle1">Koto-AI</Typography>
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={5}>
                      <Link to="/future" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <CloudIcon style={{ fontSize: 60, color: '#27a2e3' }} />
                          <Typography variant="subtitle1">Drive</Typography>
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={5}>
                      <Link to="/future" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <AccountBalanceWalletIcon style={{ fontSize: 60, color: '#002a86' }} />
                          <Typography variant="subtitle1">PayPoketra</Typography>
                        </Paper>
                      </Link>
                    </Grid>
                    

                  </Grid>



                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{
                    margin: 'auto',
                    mt: 1, p: 2,
                    backgroundColor: '#FFFFFFC4',
                    minHeight: '290px',
                    borderRadius: '17px' 
                  }}
                >
                  <Divider>
                    <Chip label="Moi & Befiana" size="small" />
                  </Divider>

                  <Grid container spacing={2} m={2} >
                    <Grid item xs={5}>
                      <Link to="/App/tiaSaka" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <PetsIcon style={{ fontSize: 60, color: '#ffadde' }} />
                          <Typography variant="subtitle1">tiaSaka</Typography>
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={5}>
                      <Link to="/future" style={{ textDecoration: 'none' }}>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                          }}
                        >
                          <FavoriteIcon style={{ fontSize: 60, color: '#d21114' }} />
                          <Typography variant="subtitle1">Namako</Typography>
                        </Paper>
                      </Link>
                    </Grid>

                  </Grid>


                </Paper>
              </Grid>

              <Grid item xs={12} sx={{ mb: 10, }}></Grid>


            </Grid>
          </Container>


          <DrawerBarandTabsXS />
        </Box>

      </Box>
    </Container>


    <Footer />
    </>
  );
}

export default Home;
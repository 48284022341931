import React from 'react';
import { Box, Toolbar, Typography, Divider } from '@mui/material';
import AppBarComponent from '../../components/AppBar';
import DrawerBarandTabsXS from '../../components/drawer/DrawerBarXS';
import DrawerBarMD from '../../components/drawer/DrawerBarMD';
import MyAccountCoordAdd from '../../components/accounts/AboutAdressAdd';
import { Helmet } from 'react-helmet-async';




function MyAccountCoord() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Ajouter une adresse - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Divider>Befiana Connect</Divider>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                Vous avez la possibilité de sauvegarder diverses adresses en fonction de vos besoins sur Befiana Connect, telles que votre adresse de domicile, de facturation ou de travail. Cette fonctionnalité nous permet de mieux personnaliser et d'optimiser nos services pour répondre précisément à vos exigences.
                </Typography>
                <br />

                <MyAccountCoordAdd />         

               
                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default MyAccountCoord;  
import React from 'react'
import { Box, Typography, Grid, Stack, ButtonGroup, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box
    sx={{
      backgroundColor: '#f2f2f7',
      margin: 0,
      p: 2,
      display: {xs: 'none', md: 'block'}
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack direction="row" spacing={2}>
          <ButtonGroup variant="text" color="primary" aria-label="Menu">
            <Button component={Link} to="/policy">Confidentialité</Button>
            <Button component={Link} to="/help">Aide et Assistance</Button>
            <Button component={Link} to="/history">Historique et Connexion</Button>
            
          </ButtonGroup>
          
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="body2" marginTop={'11px'} align='center'>Befiana Platform Corps © 2024 | Madagascar - Royaume-Uni</Typography>
      </Grid>

    </Grid>
  </Box>
  )
}

export default Footer
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Box } from '@mui/material';
import api from '../../services/api'; 
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function MyAccountDelete() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [userEmail, setUserEmail] = useState('user@example.com'); 

    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        api.get('api/user-info/')
            .then(response => {
                setUserEmail(response.data.email);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nom d\'utilisateur:', error);
            });
    }, []);

    const handleDeleteAccount = async () => {
        try {
            await api.post('api/delete-my-account/');
                navigate('/logout');
        } catch (error) {
            console.error('Erreur lors de la suppression du compte', error);
        }
        
    };

    const isEmailMatch = email === userEmail;

    return (
        <Box>
            <Typography variant="h5" color="error">Suppression de compte</Typography>
            <Typography variant="body2" color="initial" mb={2}>En supprimant votre compte, nous nous engageons à respecter votre vie privée et à effacer toutes vos données dans un délai de 30 jours. Durant cette période, l'accès à votre compte ne sera plus possible. Par ailleurs, si nous suspectons une violation de nos conditions de service, nous nous réservons le droit de passer en revue votre activité sur notre plateforme pendant ces 30 jours.</Typography>
            <Button variant="outlined" color="error" onClick={handleClickOpen}>
                Supprimer mon compte
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation de suppression</DialogTitle>
                <Helmet>
                    <title>Suppression de compte - BEFIANA Connect</title>
                </Helmet>
                <DialogContent>
                    <Typography gutterBottom>
                        Cette action est irréversible. Pour confirmer la suppression de votre compte, veuillez retaper votre adresse e-mail.
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Adresse e-mail"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={handleDeleteAccount} color="error" disabled={!isEmailMatch}>
                        Confirmer la suppression
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
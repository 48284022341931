import React from 'react';
import { Box, Toolbar, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import api from '../../../services/api';
import IBAppBarComponent from './components/AppBar';
import IBDrawerBarMD from './drawer/Drawer';
import IBDrawerTabsXS from './drawer/DrawerXS';
import AddListBusiness from './components/AddListBusiness';
import { Helmet } from 'react-helmet-async';

function IBBusiness() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Bénéficiaire - iBondacha</title>
            </Helmet>
            <IBAppBarComponent />
            <IBDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>Bénéficiaires</Typography>
                <Divider>iBondacha App</Divider>
                <br />

                <Box sx={{display: 'block', width: '850px', margin: '0 auto'}}>
                    <AddListBusiness />
                </Box>

            </Box>

            <IBDrawerTabsXS />
        </Box>
    );
}

export default IBBusiness;
import React, { useState, useEffect } from 'react';
import {
    Box, Button, TextField, Autocomplete, Grid, Typography, Snackbar, Alert, Backdrop, CircularProgress, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import api from '../../../../services/api';

const months = [
    { value: 1, label: 'Janvier' },
    { value: 2, label: 'Février' },
    { value: 3, label: 'Mars' },
    { value: 4, label: 'Avril' },
    { value: 5, label: 'Mai' },
    { value: 6, label: 'Juin' },
    { value: 7, label: 'Juillet' },
    { value: 8, label: 'Août' },
    { value: 9, label: 'Septembre' },
    { value: 10, label: 'Octobre' },
    { value: 11, label: 'Novembre' },
    { value: 12, label: 'Décembre' },
];

const VoucherExportCompta = () => {
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [store, setStore] = useState('');
    const [storeUser, setStoreUser] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/ibondacha/beneficiaries/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/ibondacha/vouchers/export-month/', {
                params: {
                    month,
                    year,
                    store: store || undefined,
                    store_user: storeUser || undefined,
                    company_reference: selectedCompany ? selectedCompany.reference : undefined,
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `iBondacha-${month}-${year}.xlsx`);
            document.body.appendChild(link);
            link.click();

            setSnackbarMessage('Fichier téléchargé avec succès');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Erreur lors du téléchargement du fichier');
            setSnackbarSeverity('error');
            console.error('Error downloading file:', error);
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography sx={{pb:2}} variant="h5" gutterBottom>Exporter les Transactions iBondacha</Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="month-label">Mois</InputLabel>
                        <Select
                            labelId="month-label"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            label="Mois"
                        >
                            {months.map((month) => (
                                <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Année"
                        type="number"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={companies}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => setSelectedCompany(newValue)}
                        renderInput={(params) => <TextField {...params} label="Entreprise" fullWidth />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Référence du magasin"
                        value={store}
                        onChange={(e) => setStore(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Utilisateur du magasin"
                        value={storeUser}
                        onChange={(e) => setStoreUser(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="ib2"
                        onClick={handleDownload}
                        fullWidth
                    >
                        Télécharger
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default VoucherExportCompta;
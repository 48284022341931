import React, { useState } from 'react';
import {
    Box, Button, TextField, Grid, Snackbar, Alert, Backdrop, CircularProgress, InputAdornment 
} from '@mui/material';
import api from '../../../../services/api';

const SendSMS = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSendSMS = async () => {
        setLoading(true);
        try {
            const response = await api.post('/api/ibondacha/sms/', {
                phone_number: phoneNumber
            });
            setSnackbarMessage(`${response.data.message}`);
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Erreur lors de l\'envoi du SMS');
            setSnackbarSeverity('error');
            console.error('Error sending SMS:', error);
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <Box sx={{ p: 3, maxWidth: 400, mx: 'auto' }}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <TextField
                        label="Numéro de téléphone"
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => {
                            if (e.target.value.length <= 9) {
                                setPhoneNumber(e.target.value);
                            }
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+261</InputAdornment>,
                        }}
                        inputProps={{
                            maxLength: 9,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="ib1"
                        onClick={handleSendSMS}
                        fullWidth
                        disabled={!phoneNumber}
                    >
                        Envoyer
                    </Button>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SendSMS;
import React, { useState } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function PasswordResetForm({ uidb64, token }) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState(' ');
    const [errorCode, setErrorCode] = useState('success');
    const [activeError, setActiveError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [display, setDispaly] = useState('initial');


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setActiveError(true)
            setMessage("Les mots de passe ne correspondent pas.");
            setErrorCode("error")
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset-confirm/${uidb64}/${token}/`, {
                new_password: newPassword
            });
            setActiveError(true)
            setErrorCode("success")
            setMessage("Votre mot de passe a été réinitialisé avec succès.");
            setDispaly("none");
        } catch (error) {
            setActiveError(true)
            setErrorCode("error")
            setMessage("Erreur lors de la réinitialisation du mot de passe.");
        }
    };

    return (
        <Box>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} display={display}>
                <Box>
                    <Typography variant="body2" color="primary">Choisissez un mot de passe fort avec un minimum de 8 caractères, au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre.</Typography>
                </Box>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Nouveau mot de passe"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="password"
                    autoFocus
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toogle la visibilité du mot de passe"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="confirmPassword"
                    label="Confirmer le nouveau mot de passe"
                    name="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="password"
                    autoFocus
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toogle la visibilité du mot de passe"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2, p: 2 }}
                >
                    Réinitialiser le mot de passe
                </Button>
            </Box>

            <Box>
                {activeError && (
                    <Box>
                        <Alert severity={errorCode} sx={{ mt: 2 }}>{message} </Alert>

                        <Button
                            component={RouterLink}
                            to="/login"
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 2, mb: 2, p: 1 }}
                        >
                            Connexion
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default PasswordResetForm;
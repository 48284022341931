import React from 'react';
import { Box, Toolbar} from '@mui/material';
import IBAppBarComponent from './components/AppBar';
import IBDrawerBarMD from './drawer/Drawer';
import IBDrawerTabsXS from './drawer/DrawerXS';
import VoucherGroupsManager from './components/VoucherGroupsManager';
import CreateVoucher from './components/CreateVoucher';
import { Helmet } from 'react-helmet-async';


function IBVoucher() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Gestion iBondacha - iBondacha</title>
            </Helmet>
            <IBAppBarComponent />
            <IBDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />

                <Box sx={{display: 'block', width: '850px', margin: '0 auto'}}>
                    <VoucherGroupsManager />

                    <CreateVoucher />
                </Box>
                

            </Box>

            <IBDrawerTabsXS />
        </Box>
    );
}

export default IBVoucher;
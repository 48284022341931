import React from 'react';
import { Box, Toolbar, Typography, Paper } from '@mui/material';
import AppBarComponent from '../components/AppBar';
import DrawerBarandTabsXS from '../components/drawer/DrawerBarXS';
import DrawerBarMD from '../components/drawer/DrawerBarMD';
import { Helmet } from 'react-helmet-async';

import Divider from '@mui/material/Divider';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

function Notification() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>Notification - BEFIANA Connect</title>
            </Helmet>
            <AppBarComponent />
            <DrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>Notification</Typography>
                <Divider>Befiana Connect</Divider>
                <br />

                <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 10, p: 2, maxWidth: 899 }}>
                    <NotificationsNoneIcon color="primary" sx={{ fontSize: 50, display:'block', margin:'0 auto' }} />
                    <Typography variant="body1" color="initial" align='center' pb={3}>Actuellement aucune notification pour votre compte.</Typography>
                </Paper>


                <DrawerBarandTabsXS />
            </Box>
        </Box>
    );
}

export default Notification;
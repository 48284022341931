import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Snackbar, Typography, Alert, Box, LinearProgress } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import api from '../../../../services/api';
import ContactCounter from './ContactCounter';

function ImportContacts() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [uploadData, setUploadData] = useState(null);

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setBuffer((prev) => {
          const diff = Math.random() * 2;
          return Math.min(prev + diff, 79);
        });
      }, 500);
      return () => clearInterval(timer);
    }
  }, [loading]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file.name.endsWith('.xlsx')) {
      setSnackbarMessage('Veuillez uniquement télécharger un fichier au format .xlsx pour cette action. Vous trouverez un modèle à utiliser via le lien ci-dessus.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 53) / progressEvent.total);
        setProgress(percentCompleted);
        setBuffer(percentCompleted + 10);
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    api.post('/api/smsko/import-contacts/', formData, config).then(response => {
      setUploadData(response.data);
      setSnackbarMessage('Votre contact a été importé avec succès. Vous pouvez maintenant gérer votre liste ou envoyer des SMS en masse.');
      setSnackbarSeverity('success');
      setProgress(100); 
      setBuffer(100);
      setSnackbarOpen(true);
    }).catch(error => {
      const errorMessage = error.response?.data?.error || 'Échec de l\'importation des contacts, veuillez réessayer.';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error:', error);
    }).finally(() => {
      setLoading(false);
      setTimeout(() => {
        setProgress(0);
        setBuffer(0);
      }, 3000);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()} style={{ border: '2px dashed gray', padding: 20, textAlign: 'center' }}>
      <input {...getInputProps()} />
      <Typography variant="body1" color="initial" component="div">Glissez-déposez un fichier Excel ici, ou cliquez pour sélectionner le fichier de vos contacts.</Typography>
      <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
        Charger le fichier
      </Button>
      
      {loading && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
          <ContactCounter />
          <Typography variant="subtitle2" color="primary">Traitement ou mise à jour en cours...</Typography>
        </Box>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {uploadData && (
        <Typography variant="body1" style={{ marginTop: 20 }} component="div">
          Nombre total de numéros traités ou importés : {uploadData.contacts.length}
        </Typography>
      )}
    </Box>
  );
}

export default ImportContacts;
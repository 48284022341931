import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false, 
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isAuthenticated = true; // L'utilisateur est authentifié
    },
    refreshAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      // state.refreshToken = action.payload.refreshToken;
    },
    logoutSuccess: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false; // L'utilisateur n'est plus authentifié
    },
    // Ajoutez d'autres réducteurs au besoin
  },
});

export const { loginSuccess, refreshAccessToken, logoutSuccess } = authSlice.actions;

export default authSlice.reducer;
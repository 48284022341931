import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import navDrawer from './navDrawerItems'
import { Box, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

const drawerWidths = {
  sm: 240, // Tablette
  md: 340, // Ordinateur
};

function SMSDrawerBarMD() {
  const location = useLocation();

  const drawerContent = (
    <List>
      {navDrawer.map((item, index) => (
        <ListItem
          button
          key={item.name}
          component={Link}
          to={item.to}
          selected={location.pathname === item.to}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(255, 231, 104, 0.3)', 
              '& .MuiListItemIcon-root': {
                color: 'primary.main', 
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 231, 104, 0.3)', 
              }
            },
            '& .MuiListItemIcon-root': { color: item.color } 
          }}
        >
          <ListItemIcon sx={{ color: item.color }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: { sm: drawerWidths.sm, md: drawerWidths.md },
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: { sm: drawerWidths.sm, md: drawerWidths.md }, boxSizing: 'border-box' },
        display: { xs: 'none', sm: 'block' }, // Caché sur `xs`, affiché sur `sm` et plus grands
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        {drawerContent}
      </Box>
      <Box sx={{ position: 'absolute', bottom: 0, left: 0, margin: '0 auto', width: '100%', paddingTop: '15px' }}>
        <Typography variant="subtitle2" color="#787878" align='center'>Befiana Platform Corps © 2024 </Typography>
      </Box>
    </Drawer>
  )
}

export default SMSDrawerBarMD 
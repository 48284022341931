import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Dialog, DialogTitle, Paper, DialogContent, Backdrop, Snackbar, Alert, Button, Grid, Box, CircularProgress, IconButton, Typography, TextField, Stack, Tabs, Tab } from '@mui/material';
import { Refresh, Visibility as VisibilityIcon } from '@mui/icons-material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Helmet } from 'react-helmet-async';
import api from '../../../../services/api';
import WidgetSMS from './WidgetSMS';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import CampaignIcon from '@mui/icons-material/Campaign';
import MessageIcon from '@mui/icons-material/Message';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

dayjs.extend(utc);
dayjs.extend(timezone);

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

function isFutureDate(date) {
  const now = new Date();
  const launchDate = new Date(date);
  return launchDate > now;
}

function DisplayLaunchDate({ sendAt }) {
  if (sendAt && isFutureDate(sendAt)) {
    const formattedDate = dayjs(sendAt).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss');
    return (
      <Typography color="primary" textAlign="center" m={1}>
        <IconButton size="small" sx={{ color: '#006837', marginRight: 1 }}>
          <ScheduleSendIcon />
        </IconButton>
        <b>SMS programmés | Lancement :</b> {formattedDate}
      </Typography>
    );
  }
}

const getMessageLength = (message) => {
  if (!message) return 0;

  const fnameCount = (message.match(/\*\|FNAME\|\*/g) || []).length;

  const adjustedMessage = message.replace(/\*\|FNAME\|\*/g, ' '.repeat(39));

  return fnameCount * 39 + adjustedMessage.split('').reduce((count, char) => count + (char === 'ê' || char === 'ô' ? 2 : 1), 0);
};

const getSmsCount = (messageLength) => {
  if (messageLength <= 160) {
    return 1;
  } else if (messageLength <= 320) {
    return 2;
  }
  return Math.ceil(messageLength / 160);
};

function CampaignsList() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsFuture, setCampaignsFuture] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [sentSMSData, setSentSMSData] = useState({});
  const [campaignStats, setCampaignStats] = useState({});
  const [campaignInfos, setCampaignInfos] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [openDetailsFuture, setOpenDetailsFuture] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const [canGoCampaign, setCanGoCampaign] = useState(false);
  const [goCampaign, setGoCampaign] = useState(false);
  const [msgCampaign, setMsgGoCampaign] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [sentSMSList, setSentSMSList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredSMSList, setFilteredSMSList] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const deliveryStatusOptions = [
    { label: 'Reçu', value: 'DeliveredToTerminal', color: '#4caf50', info: 'Vos messages ont été remis aux téléphones des destinataires. (Les destinataires ont reçu les messages.)' },
    { label: 'Reçu par le Réseau', value: 'DeliveredToNetwork', color: '#17a2b8', info: 'Vos messages ont été envoyés avec succès vers les réseaux et sont en cours de traitement. (Les messages sont en route vers les destinataires.)' },
    { label: 'Livraison Incertaine', value: 'DeliveryUncertain', color: '#ff8c00', info: 'Nous ne sommes pas en mesure de confirmer la livraison de vos messages pour le moment. (Les statuts sont incertains ; il peut être nécessaire de vérifier ultérieurement.)' },
    { label: 'Livraison Impossible', value: 'DeliveryImpossible', color: '#dc3545', info: 'Vos messages n\'ont pas pu être remis aux destinataires avant expiration. (Les messages n\'ont pas été reçus par les destinataires. Cela peut être dû à plusieurs raisons, comme les téléphones éteints ou hors couverture pendant une période prolongée.)' },
    { label: 'En Attente', value: 'MessageWaiting', color: '#000', info : 'Vos messages sont en attente d\'être envoyés. (Les messages seront envoyés prochainement.)' },
  ];

  useEffect(() => {
    if (selectedCampaign.id) {
      const status = deliveryStatusOptions[tabValue].value;
      fetchSentSMS(selectedCampaign.id, status);
    }
  }, [selectedCampaign.id, tabValue]);

  useEffect(() => {
    const currentStatus = deliveryStatusOptions[tabValue].value;
    const filtered = (sentSMSData[currentStatus] || []).filter((sms) =>
      sms.phone_number.includes(searchText) ||
      sms.message.toLowerCase().includes(searchText.toLowerCase()) ||
      sms.callback_data.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredSMSList(filtered);
  }, [searchText, tabValue, sentSMSData]);

  useEffect(() => {
    fetchCampaigns();
    fetchCampaignsFuture();
  }, []);

  useEffect(() => {
    let intervalId;
    if (selectedCampaign.id) {
      fetchSentSMS(selectedCampaign.id); 
      intervalId = setInterval(() => {
        fetchSentSMS(selectedCampaign.id); 
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId); 
    };
  }, [selectedCampaign.id]);

  useEffect(() => {
    const filtered = sentSMSList.filter((sms) =>
      sms.phone_number.includes(searchText) ||
      sms.message.toLowerCase().includes(searchText.toLowerCase()) ||
      sms.callback_data.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredSMSList(filtered);
  }, [searchText, sentSMSList]);

  const fetchSentSMS = async (campaignId, status) => {
    try {
      const response = await api.get(`/api/smsko/sms/campaign/${campaignId}/sent/?delivery_status=${status}`);
      const formattedData = response.data.sent_sms.map((sms) => ({
        ...sms,
        send_date: dayjs(sms.send_date).format('DD/MM/YYYY HH:mm:ss'),
      }));
      setSentSMSData((prev) => ({ ...prev, [status]: formattedData }));
    } catch (error) {
      console.error(`Erreur lors de la récupération des SMS pour ${status}:`, error);
    }
  };

  const columnsSMS = [
    { field: 'phone_number', headerName: 'Numéro', width: 100 },
    { field: 'message', headerName: 'Message', flex: 1 },
    { field: 'send_date', headerName: 'Date d\'envoi', width: 150 },
    { field: 'callback_data', headerName: 'Réf SMS by BEFIANA', width: 200 },
  ];

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setSearchText(''); 
  };

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/sms/campaigns/?active=true');
      setCampaigns(response.data);
    } catch (error) {
      console.error('Failed to fetch campaigns', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignsFuture = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/sms/campaigns/?active=false');
      setCampaignsFuture(response.data);
    } catch (error) {
      console.error('Failed to fetch campaigns', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignStats = async (campaignId) => {
    setIsLoadingStats(true); 
    try {
      const response = await api.get(`/api/smsko/sms/campaign/${campaignId}/statistics/`);
      setCampaignStats(response.data);
    } catch (error) {
      setCampaignStats({});
    } finally {
      setIsLoadingStats(false); 
    }
  };

  const fetchCampaignStatsNoLoad = async (campaignId) => {
    try {
      const response = await api.get(`/api/smsko/sms/campaign/${campaignId}/statistics/`);
      setCampaignStats(response.data);
    } catch (error) {
      setCampaignStats({});
    } finally {
      // 
    }
  };

  const handleOpenDetails = (campaign) => {
    setSelectedCampaign(campaign);
    fetchCampaignStats(campaign.id);
    setOpenDetails(true);
  };

  const handleOpenDetailsFuture = (campaign) => {
    setSelectedCampaign(campaign);
    fetchCampaignStats(campaign.id);
    setOpenDetailsFuture(true);
    setCanGoCampaign(false);
  };

  useEffect(() => {
    let intervalId;
    if (openDetails && selectedCampaign.id) {
      intervalId = setInterval(() => {
        fetchCampaignStatsNoLoad(selectedCampaign.id);
      }, 3000); 
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [openDetails, selectedCampaign.id]);


  const columnsfuture = [
    { field: 'name', headerName: 'Campagne', width: 200 },
    { field: 'message', headerName: 'Message', flex: 1 },
    {
      field: 'details',
      headerName: 'Action',
      width: 160,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDetailsFuture(params.row)}>
          <RocketLaunchIcon color='primary' />
          <span style={{ marginLeft: '8px', fontSize: '14px', fontWeight: 'bold', color: '#006837' }}>DÉMARRER</span>
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    }
  ];

  const columns = [
    { field: 'name', headerName: 'Campagne', width: 200 },
    { field: 'message', headerName: 'Message', flex: 1 },
    {
      field: 'details',
      headerName: 'Détails',
      width: 130,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenDetails(params.row)}>
          <VisibilityIcon style={{ color: '#006837' }} />
        </IconButton>
      ),
      sortable: false,
      filterable: false,
    }
  ];

  const preLauchCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
        const response = await api.post(`/api/smsko/sms/campaigns/launch/${campaignId}/`);
        setCampaignInfos(response.data);

        const smsCount = getSmsCount(getMessageLength(response.data.message)) * response.data.recipients_count;

        if (response.data.current_balance >= smsCount) {
            setCanGoCampaign(true);  
        } else {
            setCanGoCampaign(false); 
        }
    } catch (error) {
        console.error('Action Failed', error);
    } finally {
        setGoCampaign(false);
    }
  };

  const LauchCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
      const response = await api.post(`/api/smsko/sms/campaigns/launch/${campaignId}/`, {
        confirm: true
      });
      setMsgGoCampaign(response.data.message);
      setOpenDetailsFuture(false);
    } catch (error) {
      console.error('Action Failed', error);
    } finally {
      setGoCampaign(false);
      setSnackbarOpen(true);
      fetchCampaignsFuture();
      fetchCampaigns();
    }
  };

  const delCampaign = async (campaignId) => {
    setGoCampaign(true);
    try {
      await api.delete(`/api/smsko/sms/campaigns/${campaignId}/delete/`);
    } catch (error) {
      console.error('Action Failed', error);
    } finally {
      setGoCampaign(false);
      setSnackbarOpen(true);
      setMsgGoCampaign("La campagne a été supprimée");
      setOpenDetailsFuture(false);
      fetchCampaignsFuture();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Helmet>
        <title>Campagnes SMS - BEFIANA Connect</title>
        <meta name="description" content="Gérer et suivre vos campagnes SMS sur BEFIANA Connect." />
      </Helmet>
      
      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <Typography variant="h3" color="secondary" sx={{fontSize: '2em', mb: 2}}>Mes campagnes prêtes à démarrer</Typography>
        <Button variant="outlined" color="primary" sx={{ mb: 1 }} startIcon={<Refresh />} onClick={fetchCampaignsFuture}>
          Actualiser la liste des campagnes
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={campaignsFuture}
            columns={columnsfuture}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{ minHeight: '140px' }}
          />
        )}
      </Paper>

      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <Typography variant="h3" color="primary" sx={{fontSize: '2em', mb: 2}}>Suivre les performances de mes campagnes</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={campaigns}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            sx={{ minHeight: '140px' }}
          />
        )}
      </Paper>

      <Dialog open={openDetails} onClose={() => setOpenDetails(false)} fullWidth maxWidth="md">
        <Helmet>
          <title>{`Campagne : ${selectedCampaign.name} (#${selectedCampaign.id}) - BEFIANA Connect`}</title>
        </Helmet>
        <DialogTitle color="primary">
          <Button variant="text" color="primary" startIcon={<CampaignIcon />}>
            Campagne : {selectedCampaign.name} (#{selectedCampaign.id})
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="Total SMS" nb={campaignStats.total_sms} color="#17a2b8" colorT="#ffffff" icon="SendAndArchive" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Livrés" nb={campaignStats.sms_delivered} color="#ff8c00" colorT="#ffffff" icon="PhonelinkRing" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Échoués" nb={campaignStats.sms_failed} color="#dc3545" colorT="#ffffff" icon="GppBad" />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <WidgetSMS name="SMS Reçu" nb={campaignStats.sms_terminal === 0 ? '-' : ((campaignStats.sms_terminal/campaignStats.total_sms)*100).toFixed(2) + '%'} color="#4caf50" colorT="#ffffff" icon="CheckCircle" />
              </Grid>
            </Grid>
            <br />
            <Typography sx={{border: '1px solid #00331a', padding: '5px', borderRadius: '5px', marginBottom: '10px'}}>
              <IconButton size="small" sx={{ color: '#006837', marginRight: 1 }}>
                <MessageIcon />
              </IconButton>
              {selectedCampaign.message}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>
                  <IconButton size="small" sx={{ color: '#006837', marginRight: 1 }}>
                    <ChecklistIcon />
                  </IconButton>
                  <b>Groupe de Contacts ID :</b> {selectedCampaign.target_all_contacts ? 'Tous mes contacts' : `#${selectedCampaign.list}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>
                  <IconButton size="small" sx={{ color: '#006837', marginRight: 1 }}>
                    <EventAvailableIcon />
                  </IconButton>
                  <b>Création :</b> {new Date(selectedCampaign.created_at).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>

            
            <DisplayLaunchDate sendAt={selectedCampaign.send_at} />
                        

            <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto" sx={{backgroundColor: '#00331a', marginTop: '11px'}} >
              {deliveryStatusOptions.map((option, index) => (
                <Tab key={index} label={option.label} sx={{color: '#fff'}} />
              ))}
            </Tabs>

            <Paper elevation={1} sx={{ margin: 'auto', mt: 1, p: 2, mb: 3, maxWidth: 899 }}>
              
              <Typography variant="subtitle2" color={deliveryStatusOptions[tabValue].color} mb={1}>{deliveryStatusOptions[tabValue].info}</Typography>
              <TextField
                label="Rechercher"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={filteredSMSList}
                  columns={columnsSMS}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10]}
                  disableSelectionOnClick
                  localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
              </Box>
            </Paper>

            <Typography variant="subtitle2" color="secondary" mt={2} mb={4} sx={{textAlign: 'center'}}>Nous traitons l'envoi de messages en file d'attente en fonction du débit par seconde autorisé pour tous les utilisateurs.</Typography>
            
          </Box>
        </DialogContent>
        <Button onClick={() => setOpenDetails(false)} color="primary">Fermer</Button>
      </Dialog>

      <Dialog open={openDetailsFuture} onClose={() => setOpenDetailsFuture(false)} fullWidth maxWidth="sm">
        <Helmet>
          <title>{`Campagne : ${selectedCampaign.name} (#${selectedCampaign.id}) - BEFIANA Connect`}</title>
        </Helmet>
        <DialogTitle color="primary">Campagne : {selectedCampaign.name} (#{selectedCampaign.id})</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container direction='row' spacing={0} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm={6} md={6}>
                <WidgetSMS name="Contact" nb={campaignInfos.recipients_count} color="#17a2b8" colorT="#ffffff" icon="ContactPhone" />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <WidgetSMS name="SMS Solde" nb={campaignInfos.current_balance} color="#ff8c00" colorT="#ffffff" icon="ShoppingCart" />
              </Grid>
            </Grid>
            <br />
            <Typography variant="subtitle2" color="secondary" m={1}>{campaignInfos.message}</Typography>
            <Typography m={1}><b>Groupe de Contacts ID :</b> {selectedCampaign.target_all_contacts ? 'Tous mes contacts' : `${selectedCampaign.list}`} | <b>Message:</b> {selectedCampaign.message}</Typography>
            <Typography m={1}><b>Solde SMS maximum requis :</b> {selectedCampaign.message ? getSmsCount(getMessageLength(selectedCampaign.message)) * campaignInfos.recipients_count : 0}</Typography>

            <DisplayLaunchDate sendAt={selectedCampaign.send_at} />
            <Stack spacing={2}>
              <Button onClick={() => preLauchCampaign(selectedCampaign.id)} variant="outlined">Analyser</Button>
              <Button disabled={!canGoCampaign} onClick={() => LauchCampaign(selectedCampaign.id)} variant="contained">Lancer ma campagne</Button>
              <Button onClick={() => setOpenDetailsFuture(false)} variant="outlined">Annuler</Button>
            </Stack>

            <Typography variant="subtitle2" color="secondary" m={1}>Solde SMS requis : en campagne dynamique, le solde peut varier selon la longueur du prénom de chaque contact.</Typography>

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
              open={goCampaign}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </DialogContent>
        <Button onClick={() => delCampaign(selectedCampaign.id)} color="error">Supprimer</Button> <br />
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {msgCampaign}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={isLoadingStats} 
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default CampaignsList;

import React, { useEffect, useState } from 'react';
import { Typography, Paper, Card, CardContent, Grid, Divider, List, ListItemButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import api from '../../services/api';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr');

function AboutAdress() {
  const [userProfile, setUserProfile] = useState({
    username: ' ',
    firstName: ' ',
    lastName: ' ',
    date_of_birth: ' ',
    email: ' ',
    phone: ' ',
    email_verified: false,
    is_active: false,
    id: ' ',
  });

  useEffect(() => {
    api.get('api/user-info/')
      .then(response => {
        setUserProfile({
          username: response.data.username,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          date_of_birth: response.data.date_of_birth,
          email: response.data.email,
          phone: response.data.phone_number,
          email_verified: response.data.email_verified,
          is_active: response.data.is_active,
          id: response.data.id,
        });
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          console.error('Erreur lors de la récupération des informations utilisateur:', error);

        }
      });
  }, []);

  return (
    <Paper elevation={1} sx={{ margin: 'auto', mb: 5, p: 2, maxWidth: 899, borderRadius: '17px' }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="div" color={'primary'}>
                Coordonnées
              </Typography>
            </Grid>
          </Grid>

          <List component="nav" aria-label="user information">
            <Divider />

            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink}
                to="/myAccount/add-new-email"
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Email</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">{userProfile.email}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

            <List component="nav" aria-label="user information">
              <ListItemButton
                sx={{
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  cursor: 'pointer',
                }}
                component={RouterLink}
                to="/myAccount/phone"
              >
                <Grid container alignItems="center">
                  <Grid item xs={10} md={11}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Typography variant="body2">Téléphone</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">{userProfile.phone}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} container justifyContent="flex-end">
                    <KeyboardArrowRightIcon />
                  </Grid>
                </Grid>
              </ListItemButton>
            </List>
            <Divider />

          </List>
        </CardContent>
      </Card>

    </Paper>
  )
}

export default AboutAdress